import { CURRENT_ENVIRONMENT } from "./envConstants";

export const DRIVE_ID =
  "b!slrLZVLK-0K0odsjp7YbdxvQaF5hsoREstieWeZV4ii51TFnLDwjTrUWlaXEKKVo";

export const TRAINING_PROJECT = "Trainings";
export const BROWNBAGS_PROJECT = "Brownbags";

export const TRAINING_PROJECT_ID = "32323986";
export const BROWNBAGS_PROJECT_ID = "32323979";

export const README_FILE_NAME =
  CURRENT_ENVIRONMENT === "dev" ? "README_DEV.json" : "README.json";

export const prepareContentUrl = (filePath, fileName) =>
  `/drives/${DRIVE_ID}/root:/${filePath}/${encodeURIComponent(
    fileName
  )}:/content`;

export const prepareListUrl = (filePath) =>
  `/drives/${DRIVE_ID}/root:/${filePath}:/children`;
