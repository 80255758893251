export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const SET_CURRENT_USER_NAME = 'SET_CURRENT_USER_NAME';

export const SET_CURRENT_USER_DETAILS = 'SET_CURRENT_USER_DETAILS';

export const USER_LOGOUT='USER_LOGOUT'
export const SET_CURRENT_ACCESS_DETAILS='SET_CURRENT_ACCESS_DETAILS';


export const setCurrentUser = (user_id) =>({
    type:SET_CURRENT_USER,
    payload:user_id
});

export const setCurrentUserID=(email_id)=>({
    type:SET_CURRENT_USER_ID,
    payload:email_id
});

export const setCurrentUserName=(user_name)=>({
    type:SET_CURRENT_USER_NAME,
    payload:user_name
});



export const setCurrentUserDetails=(user_details)=>({
    type:SET_CURRENT_USER_DETAILS,
    payload:user_details
});

export const setLogOut =()=>({
    type:USER_LOGOUT
})

export  const setAccessListDetails = (access_list) => ({
    type:SET_CURRENT_ACCESS_DETAILS,
    payload:access_list
})
