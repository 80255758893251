import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

export const MyAdditionalGoals = forwardRef(({ label, minValue, weightAge, placeHolder, values, courseId, onChange }, ref) => {
    const [fields, setFields] = useState([]);

    useImperativeHandle(ref, () => ({}));

    useEffect(() => {
        setFields(values);
    }, [values]);

    const onValueChange = (item) => {
        let records = fields.map((input) => {
            if(input.label === item.label) {
                return {
                    id:0,
                    label:item.label,
                    name:item.name,
                    typeId:courseId,
                    type:label
                }
            } else {
                return input;
            }
        });
        let payload = { CourseTypeId: courseId, Name:label, Minimum: minValue, Weightage: weightAge, List: [...records] };
        onChange(payload);
    }

    return (
        <>
            <div>
                <Row className="mb-2">
                    <Col>
                        <p className="m-0">
                            <span className="font-semi-bold" style={{verticalAlign:'sub'}}>{label}</span>
                            <span className="font-semi-bold" style={{ fontSize: '12px', marginLeft: '3px', verticalAlign:'sub' }}>(Min {minValue})</span>
                            <span className="font-semi-bold" style={{ fontSize: '12px', marginLeft: '3px', verticalAlign:'sub' }}>(Weightage {weightAge}%)</span>
                        </p>
                    </Col>
                </Row>
                <Row>
                    {fields && fields.map((item) =>
                        <Form.Group className="mb-2">
                            <Row>
                                <Col>
                                    <input type="text"
                                        name={item.label}
                                        className="form-control"
                                        placeholder={placeHolder}
                                        value={item.name}
                                        onChange={(e) => onValueChange({ label:item.label, name:e.target.value })} />
                                </Col>
                            </Row>
                        </Form.Group>
                    )}
                </Row>
            </div>
        </>
    )
});