import React, { useEffect,  } from "react";
import ReactPlayer from "react-player";
import { GaEvent } from "../App";
import { gaCategory } from "../constants/gaConstants";

const MediaPlayer = ({ videoLink, isPlaying, setIsPlaying }) => {
    useEffect(() => {
        GaEvent(gaCategory.TRAINING_WIZARD, "Video Viewed", videoLink?.module_name);
    }, [videoLink?.module_name]);
    return (
        <div className="videoDiv align-center">

            {videoLink?.video_link &&
                <>{videoLink?.video_link.startsWith("https://web.microsoftstream.com/") || 
                    videoLink?.video_link.startsWith("https://wavicledata-my.sharepoint.com") ?
                    <iframe width="640" height="360"
                     allow="autoplay; fullscreen; encrypted-media"
                        src={videoLink.video_link}
                        allowfullscreen id='video-iframe' className="video-frame" title="Media Player"/> :
                    <ReactPlayer className=' vedio ' url={videoLink.video_link}
                        pip={true}
                        controls={true}
                        
                        playing={isPlaying}
                        onContextMenu={(e) => e.preventDefault()}
                        fullscreen={true}
                        muted={true}
                    />}</>
            }

        </div>
    )
}

export default MediaPlayer;