import axios from 'axios';
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { GaEvent } from "../App";
import { Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import {
    GOAL_LIST,
    SAVED_GOAL_LIST, SAVE_INDIVIDUAL_GOALS
} from '../constants/urlConstants';
import { GoalContext } from "./GoalContext";
import { GoalCourse } from "./GoalCourse";
import { MyAdditionalGoals } from "./MyAdditionalGoals";
import { SavedGoalPanel } from "./SavedGoalPanel";


const MyGoals = (props) => {
    const staticValues = useContext(GoalContext);
    const currentUserDetail = props.currentUserDetail;
    const employeeId = currentUserDetail.employee_id;
    const [isLoading, setLoading] = useState(false);
    const [isGoalSaved, setGoalSaved] = useState(false);
    const [allGoals, setAllGoals] = useState();
    const [myGoals, setMyGoals] = useState();
    
    useEffect(() => {
        if (!allGoals && !myGoals) {
            loadValues();
        }
    }, [isGoalSaved]);

    // This method is used and load the employee goals and competency band goals.
    const loadValues = async () => {
        setLoading(true);
        try {
            let result = await axios.post(SAVED_GOAL_LIST, {
                employeeId: employeeId
            });
            if (Object.keys(result?.data).length === 0) {
                getAllGoals();
            } else {
                setMyGoals(result?.data);
                setGoalSaved(true);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("err", error);
        }
    }

    //
    const getCompetencyId = () => {
        let compentency = 0;
        let records = staticValues.filter((x) => x.type === "competency");
        if (records?.length > 0) {
            let result = records.filter(x => x.description === currentUserDetail.competency);
            compentency = result[0].id;
        }
        return compentency;
    };


    // This method is used to get compentency goal list from server by passing competency id and band id.
    const getAllGoals = async () => {
        setLoading(true);
        try {
            let result = await axios.post(GOAL_LIST, {
                competencyId: getCompetencyId(),
                bandId: currentUserDetail.band,
                employeeId: employeeId
            });
            if (result) {
                setAllGoals(result?.data);
                setGoalSaved(false);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("err", error);
        }
    }

    return (
        <Container className="main-container">
            {isLoading && <>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '30rem' }}>
                            <Spinner animation="grow" variant="primary" />
                        </div>
                    </Col>
                </Row>
            </>}

            {!isLoading && <>
                <Row>
                    <Col>
                        {!isGoalSaved && allGoals && (<UnSavedGoalPanel userDetails={currentUserDetail} Loading={isLoading} values={allGoals} onSave={loadValues} />)}
                        {isGoalSaved && myGoals && (<SavedGoalPanel userDetails={currentUserDetail} Loading={isLoading} values={myGoals} onChange={loadValues} />)}
                    </Col>
                </Row>
            </>}
        </Container>
    );
}

const UnSavedGoalPanel = ({ userDetails, values, Loading, onSave }) => {
    const employeeId = userDetails?.employee_id;
    const staticValues = useContext(GoalContext);
    const [isLoading, setLoading] = useState(false);
    const [btnText, setBtnText] = useState('Save');
    const [isBtnDisabled, setBtnDisabled] = useState(false);
    const [isDataLoading, setDataLoading] = useState(false);
    const [assignById, setAssignedById] = useState(false);
    const [courseTypes, setCourseTypes] = useState([]);
    const [goalDetails, setGoalDetails] = useState();
    const [empGoals, setEmpGoals] = useState([]);
    const [empCertifications, setEmpCertifications] = useState([]);
    const [empCourses, setEmpCourses] = useState([]);
    const [empTrainings, setEmpTranings] = useState([]);
    const [technicalCourseDetails, setTechnicalCourseDetails] = useState([]);
    const [nonTechnicalCourseDetails, setNonTechnicalCourseDetails] = useState([]);
    const [certificationDetails, setCertificationDetails] = useState([]);
    const [addtionalCourseDetails, setAddtionalCourseDetails] = useState([]);

    useEffect(() => {
        setBtnDisabled(true);
        Promise.all([initValues(), loadStaticValues()])
    }, [values]);

    useEffect(() => {
        setLoading(Loading);
    }, [Loading]);

    //loading static course types data into states.
    const loadStaticValues = async () => {
        if (staticValues) {
            setCourseTypes(staticValues.filter(x => x.type === 'course_type').map(x => { return { value: x.id, label: x.description } }));
        }
    }

    //initializing method to load technical details, certification details and non technical course details.
    const initValues = () => {
        setLoading(true);
        setDataLoading(true);
        let bandGoals = values;
        if (bandGoals && bandGoals.GoalList && bandGoals.GoalList[0]) {
            let goalInfo = bandGoals.GoalList[0];
            let empCertifications = bandGoals.CertificationList?.map(x => x.certification_id);
            let empCoursesLists = bandGoals.CoursesList?.map(x => x.course_id);
            let empTrainings = bandGoals.TrainingList?.map(x => x.training_id);
            let empGoalsList = bandGoals.UserGoalList?.map(x => x.course_id);

            setGoalDetails(goalInfo);
            setAssignedById(goalInfo?.assigned_by_employee_id);

            if (empGoalsList) { setEmpGoals(empGoalsList); }
            if (empCertifications) { setEmpCertifications(empCertifications); }
            if (empCoursesLists) { setEmpCourses(empCoursesLists); }
            if (empTrainings) { setEmpCourses((courses) => [...courses, ...empTrainings]); }

            if (goalInfo?.goals) {
                setTechnicalCourseDetails(goalInfo?.goals["Technical_Courses"]);
                setNonTechnicalCourseDetails(goalInfo?.goals["Non_Technical_Courses"]);
                setCertificationDetails(goalInfo?.goals["Certifications"]);
                setAddtionalCourseDetails(goalInfo?.goals["Additional_Goals"]);
            }
            setBtnDisabled(false);
        }
        setLoading(false);
        setDataLoading(false);
    }

    // This method helps to get values from the list param and loading into combo boxs.
    const getGoalComboOptions = (key) => {
        let result = [];
        if (goalDetails?.goals) {
            switch (key) {
                case "Technical_Courses":
                case "Non_Technical_Courses":
                    result = goalDetails?.goals[key].List?.map(x => {
                        return ({
                            typeId: x.typeId,
                            value: x.id,
                            label: x.name,
                            type: x.type,
                            isDisabled: empGoals.includes(x.id)
                        });
                    });
                    break;
                case "Certifications":
                    result = goalDetails?.goals[key].List.map(x => {
                        return ({
                            typeId: x.typeId,
                            value: x.id,
                            label: x.name,
                            type: x.type,
                            isDisabled: empGoals.includes(x.id) && empCertifications.includes(x.id)
                        });
                    });
                    break;
                case "Additional_Goals":
                    result = goalDetails?.goals[key].List?.map(x => {
                        return ({
                            typeId: x.typeId,
                            value: x.id,
                            label: x.name,
                            type: x.type,
                            isDisabled: empCourses.includes(x.CourseTypeId)
                        });
                    });
                    break;
            }
        }
        return result;
    }

    // This method helps to get course type id from course types state values.
    const getCourseTypeId = (key) => {
        if (key === null || key === undefined) return 0;
        let course = staticValues.filter(x => x.type === 'course_type' && x.description.toLowerCase() === key.toLowerCase());
        let courseId = course && course[0] ? course[0].id : 0;
        return courseId;
    }

    // This method helps to get value of minimum number of courses from technical, non technical and certifications list.
    // const getMinimumValue = (key) => {
    //     switch (key) {
    //         case "Technical_Courses":
    //             return technicalCourseDetails.Minimum;
    //         case "Certifications":
    //             return certificationDetails.Minimum;
    //         case "Non_Technical_Courses":
    //             return nonTechnicalCourseDetails.Minimum;
    //     }
    // }

    // // This method helps to get value of course weightage from technical, non technical and certifications list.
    // const getWeightAgeValue = (key) => {
    //     if (key === null || key === undefined) return 0;
    //     switch (key) {
    //         case "Technical_Courses":
    //             let techWeight = parseFloat(technicalCourseDetails.Weightage) / parseInt(technicalCourseDetails.Minimum);
    //             return techWeight.toFixed(2) || 0;
    //         case "Certifications":
    //             let certWeight = parseFloat(certificationDetails.Weightage) / parseInt(certificationDetails.Minimum);
    //             return certWeight.toFixed(2) || 0;
    //         case "Non_Technical_Courses":
    //             let nonWeight = parseFloat(nonTechnicalCourseDetails.Weightage) / parseInt(nonTechnicalCourseDetails.Minimum);
    //             return nonWeight.toFixed(2) || 0;
    //         case "Additional_Courses":
    //             let addWeight = parseFloat(addtionalCourseDetails.Weightage) / parseInt(addtionalCourseDetails.Minimum);
    //             return addWeight.toFixed(2) || 0;
    //         default:
    //             return 0;
    //     }
    // }

    // // This method helps to get list of courses selected previously by employee from technical, non technical and certifications list.
    // const getSelectedValues = (key) => {
    //     switch (key) {
    //         case "Technical_Courses":
    //             return technicalCourseDetails.List?.map(x => { return { value: x.id, label: x.name, type: x.type } });
    //         case "Certifications":
    //             return certificationDetails.List?.map(x => { return { value: x.id, label: x.name, type: x.type } });
    //         case "Non_Technical_Courses":
    //             return nonTechnicalCourseDetails.List?.map(x => { return { value: x.id, label: x.name, type: x.type } });
    //     }
    // }

    //
    const getAdditionalGoalsValues = (item) => {
        let values = [];
        let itemName = item.Name ? item.Name.replace(/\s+/g, '_').toLowerCase() : 'input_box';

        if (item) {
            if (item.List) {
                values = item.List;
            } else {
                for (let index = 0; index < item.Minimum; index++) {
                    values.push({
                        id: item.CourseTypeId,
                        label: itemName + '_' + index,
                        name: '',
                        typeId: item.CourseTypeId,
                        type: item.Name
                    });
                }
            }
        }
        return values;
    }

    //
    const addAdditionalGoalsValues = (item) => {
        let additionals = addtionalCourseDetails.map((x) => {
            if (x.CourseTypeId === item.CourseTypeId) {
                return {
                    Name: x.Name,
                    CourseTypeId: x.CourseTypeId,
                    Minimum: x.Minimum,
                    Weightage: x.Weightage,
                    List: item.List
                }
            } else {
                return x;
            }
        })
        setAddtionalCourseDetails(additionals);
    }

    // This method helps to construct the payload which is going to save in server.
    const constructPayload = (item, weight, index) => {
        return {
            "employeeId": employeeId,
            "assignedByEmployeeId": assignById,
            "goalId": goalDetails?.goal_id,
            "typeId": item.typeId, // courseTypeId 
            "title": item.name,
            "type": item.type,
            "courseId": index !== "Additional_Courses" ? item.id : item.typeId,
            "weightage": weight,
            "active": 1
        }
    }

    // This method helps to save the courses choosen by the employee.
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            disableSave(true);
            let payload = [];
            let errors = [];

            let technicalOptions = getGoalComboOptions("Technical_Courses");
            let certificateOptions = getGoalComboOptions("Certifications");
            let nonTechnicalOptions = getGoalComboOptions("Non_Technical_Courses");

            if (technicalOptions) {
                if (technicalOptions.some(x => !x.isDisabled)) {
                    if (technicalCourseDetails && Object.keys(technicalCourseDetails).length > 0) {
                        let counts = Object.keys(technicalCourseDetails.List).length;
                        let techWeight = parseFloat(technicalCourseDetails.Weightage) / parseInt(technicalCourseDetails.Minimum);
                        if (counts !== parseInt(technicalCourseDetails.Minimum)) {
                            errors.push(`${technicalCourseDetails.Minimum} technical courses`)
                        }
                        technicalCourseDetails.List.forEach(element => {
                            payload.push(constructPayload(element, (techWeight.toFixed(2) || 0), "Technical_Courses"));
                        });
                    } else {
                        errors.push(`1 technical courses`)
                    }
                }
            }

            if (certificateOptions) {
                if (certificateOptions.some(x => !x.isDisabled)) {
                    if (certificationDetails && Object.keys(certificationDetails).length > 0) {
                        let counts = Object.keys(certificationDetails.List).length;
                        let certWeight = parseFloat(certificationDetails.Weightage) / parseInt(certificationDetails.Minimum);
                        if (counts !== parseInt(certificationDetails.Minimum)) {
                            errors.push(`${certificationDetails.Minimum} certifications`)
                        }
                        certificationDetails.List.forEach(element => {
                            payload.push(constructPayload(element, (certWeight.toFixed(2) || 0), "Certifications"));
                        });
                    } else {
                        errors.push(`1 certifications`)
                    }
                }
            }

            if (nonTechnicalOptions) {
                if (nonTechnicalOptions.some(x => !x.isDisabled)) {
                    if (nonTechnicalCourseDetails && Object.keys(nonTechnicalCourseDetails).length > 0) {
                        let counts = Object.keys(nonTechnicalCourseDetails.List).length;
                        let nonWeight = parseFloat(nonTechnicalCourseDetails.Weightage) / parseInt(nonTechnicalCourseDetails.Minimum);
                        if (counts !== parseInt(nonTechnicalCourseDetails.Minimum)) {
                            errors.push(`${nonTechnicalCourseDetails.Minimum} non technical courses`)
                        }
                        nonTechnicalCourseDetails.List.forEach(element => {
                            payload.push(constructPayload(element, (nonWeight.toFixed(2) || 0), "Non_Technical_Courses"));
                        });
                    } else {
                        errors.push(`1 non technical courses`)
                    }
                }
            }

            if (addtionalCourseDetails) {
                addtionalCourseDetails.forEach(item => {
                    let addWeight = parseFloat(item.Weightage) / parseInt(item.Minimum);
                    if (item.List) {
                        let itemList = item.List.filter(x => x.name !== "");
                        let counts = itemList.length;
                        if (counts === 0 || counts !== parseInt(item.Minimum)) {
                            errors.push(`${item.Minimum} additional ${item.Name.toLowerCase()} courses`)
                        }
                        itemList.forEach(element => {
                            if (element.name) {
                                payload.push(constructPayload(element, (addWeight.toFixed(2) || 0), "Additional_Courses"));
                            }
                        });
                    } else {
                        errors.push(`${item.Minimum} additional ${item.Name.toLowerCase()} courses`)
                    }
                });
            }

            let finalPayload = {
                Goal_List: [...payload],
                Course_List: [...payload.filter(x => [1, 2].includes(x.typeId) && !empCourses.includes(x.courseId))],
                Certification_List: [...payload.filter(x => x.typeId === 3 && !empCertifications.includes(x.courseId))],
                Training_List: [...payload.filter(x => x.typeId === 50 && !empTrainings.includes(x.courseId))],
            }

            //console.log(finalPayload);

            if (errors.length === 0) {
                //disableSave(false);
                axios.post(SAVE_INDIVIDUAL_GOALS, finalPayload)
                    .then((res) => {
                        disableSave(false);
                        if (res.data.errorType === 'Error' || res.data.errorMessage) {
                            utilities.showToast(res.data.errorMessage);
                        } else if (res.data.affectedRows > 0) {
                            GaEvent("Saved", "New Goal Added", "Goal");
                            utilities.showToast('Added ' + res.data.affectedRows + ' New Goal!!');
                        }
                        onSave();
                    })
                    .catch((err) => {
                        disableSave(false);
                        console.log('err', err);
                    });
            } else {
                console.log(errors);
                disableSave(false);
                utilities.showToast(`You have to choose ${errors.join(', ')}`);
            }
        }
    };

    const disableSave = (state) => {
        setBtnText(state ? 'Saving' : 'Save');
        setBtnDisabled(state);
    }

    return (
        <Container className="main-container tab-container p-4">
            {isLoading && <>
                <Loader />
            </>}

            {!isLoading && <>
                <Form onSubmit={handleSubmit} noValidate validated={false}>
                    <Row>
                        <Col className="competency-row font-bold">Self Goals</Col>
                        <Col className="competency-row" >
                            <Button style={{ float: "right" }} disabled={isBtnDisabled} type="submit">{btnText}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="competency-row" >
                            <GoalCourse label={"Technical Course"}
                                comboOptions={getGoalComboOptions("Technical_Courses")}
                                isLoading={isDataLoading}
                                onChange={setTechnicalCourseDetails}
                                value={technicalCourseDetails}
                                disableMaxField={true}
                                courseId={getCourseTypeId("Technical")} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="competency-row" >
                            <GoalCourse label={"Certifications"}
                                comboOptions={getGoalComboOptions("Certifications")}
                                isLoading={isDataLoading}
                                onChange={setCertificationDetails}
                                value={certificationDetails}
                                disableMaxField={true}
                                courseId={getCourseTypeId("Certifications")} />
                        </Col>

                    </Row>
                    <Row>
                        <Col className="competency-row" >
                            <GoalCourse label={"Non Technical Courses"}
                                comboOptions={getGoalComboOptions("Non_Technical_Courses")}
                                isLoading={isDataLoading}
                                onChange={setNonTechnicalCourseDetails}
                                value={nonTechnicalCourseDetails}
                                disableMaxField={true}
                                courseId={getCourseTypeId("Non Technical")} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="competency-row" >
                            {addtionalCourseDetails && addtionalCourseDetails.map((item) => (
                                <MyAdditionalGoals label={item.Name}
                                    minValue={item.Minimum}
                                    weightAge={item.Weightage}
                                    placeHolder={item.Name}
                                    values={getAdditionalGoalsValues(item)}
                                    courseId={item.CourseTypeId}
                                    onChange={(value) => addAdditionalGoalsValues(value)}
                                />
                            ))}
                        </Col>
                    </Row>
                </Form>
            </>}
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        currentUserDetail: state.CurrentUserReducer.currentUserDetail,
    };
};
export default connect(mapStateToProps)(MyGoals);;
