import {
  EventType, PublicClientApplication
} from '@azure/msal-browser';
import axios from "axios";
import { createBrowserHistory } from "history";
import React, { Component } from "react";
import ReactGA from 'react-ga';
import { connect } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { updateEmployeeDetails,fetchStaticData } from "./Auth/action";
import Login from "./Auth/login";
import { CURRENT_ENVIRONMENT, measurementIds } from "./constants/envConstants";
import { EMPLOYEES_API,GET_GOAL_STATIC_DATA } from "./constants/urlConstants";
import ErrorBoundary from "./ErrorBoundary";
import NavigationBar from "./NavBar";
import { setAccessListDetails } from "./NavBar/action";
import "./snackbar.css";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { checkAccessList } from "./common/utilities";
import { RouteData } from "./constants";
import config from './Office365/Office365Config';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
});

// Check if there are already accounts in the browser session
// If so, set the first account as the active account
const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    // Set the active account - this simplifies token acquisition
    const authResult = event.payload;
    msalInstance.setActiveAccount(authResult.account);
  }
});

export const history = createBrowserHistory();
ReactGA.initialize(measurementIds[CURRENT_ENVIRONMENT]);
history.listen(location => {
  ReactGA.pageview(location.pathname);
});

//For GA
export const GaEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

class App extends Component {
  fetchEmployeeDetails = () => {
    axios.get(`${EMPLOYEES_API}/getemployee`).then((res) => {
      if (res && !res.data.errorMessage) {
        this.props.updateEmployeeDetails(res.data);
      }
    });
  };

  fetchConfigurationData = () => {
    axios.get(GET_GOAL_STATIC_DATA).then((res) => {
      if (res && !res.data.errorMessage) {
        this.props.fetchStaticData(res.data);
      }
    });
  };

  componentDidMount() {
    const { currentUserDetail } = this.props;
    if (this.props.isAuthenticated && currentUserDetail?.role_id) {
      this.props.setAccessListDetails(currentUserDetail.access_list)
      this.fetchEmployeeDetails();
      this.fetchConfigurationData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.fetchEmployeeDetails();
      this.fetchConfigurationData();
    }
    if (this.props.currentUserDetail !== prevProps.currentUserDetail && (this.props.accessListDetails === undefined || this.props.accessListDetails.length <= 0)) {
      const { currentUserDetail } = this.props;
      if (currentUserDetail?.access_list)
        this.props.setAccessListDetails(currentUserDetail?.access_list);
    }
  }

  render() {
    const currentUserID = localStorage.getItem("email_id");
    const { isAuthenticated } = this.props;


    return (
      <>
        <HashRouter history={history}>
          <div className="App">
            {currentUserID &&
              isAuthenticated &&
              !(
                window.location.href.endsWith("/helpDoc") ||
                window.location.href.endsWith("/#/errorPage")
              ) && <NavigationBar />}
          </div>
          <ErrorBoundary>
            {currentUserID && isAuthenticated ? (
              <Switch>
                {
                  RouteData.map(routeList => {
                    if (checkAccessList(routeList.accessKey)) {
                      return (
                        <Route exact={routeList.exact} path={routeList.path} component={routeList.component} />
                      )
                    }
                  })
                }
              </Switch>
            ) :
              window.location.href.endsWith("/errorPage") ?
                <Switch>
                  <Route exact path="/errorPage">
                    <ErrorPage />
                  </Route>
                </Switch>
                : (
                  <ErrorBoundary>
                    <Switch>
                      <Route exact path="*">
                        <Login />
                      </Route>
                    </Switch>
                  </ErrorBoundary>
                )
            }
          </ErrorBoundary>
        </HashRouter>
        <div id="snackbar"></div>
      </>
    );
  }
}

export class ErrorPage extends Component {
  render() {
    const msg = this.props?.location?.state?.msg;
    return (
      <div className="display-center align-center screen-height">
        <span className="error-msg">{msg ? msg : "Invalid User! Please contact administrator."}</span>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.CurrentUserReducer.isAuthenticated,
    currentUser: state.CurrentUserReducer.currentUser,
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
    accessListDetails: state.CurrentUserReducer.accessList
  };
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ updateEmployeeDetails, setAccessListDetails,fetchStaticData }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
