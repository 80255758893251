export const UPDATE_EMPLOYEE_LIST = 'UPDATE_EMPLOYEE_LIST';
export const FETCH_STATIC_DATA = 'FETCH_STATIC_DATA';

export const updateEmployeeDetails = (employeeDetails) =>({
    type: UPDATE_EMPLOYEE_LIST,
    payload:employeeDetails
});

export const fetchStaticData = (data) =>({
    type: FETCH_STATIC_DATA,
    payload:data
});

