import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import addLogo from "../assets/plus.png";
import Select from "react-select";
import { utilities } from "../components/utilities";
import { daysToYearsAndMonths } from "../common/utilities";
import { useAppContext } from '../Office365/Office365Context';
import {prepareListUrl, TRAINING_PROJECT} from '../constants/oneDriveConstants';
import {  getListFromOneDrive } from '../Office365/Office365Graph';

import "./styles.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { MANDATORY_TRAINING } from "../constants/urlConstants";
import { Loader } from "../components/customComponents";
import { useSelector } from "react-redux";
const Mandatory = () => {
  return (
    <Container className="main-container">
      <Row>
        <Col>
          <MandatoryConfigPanel />
        </Col>
      </Row>
    </Container>
  );
};

const MandatoryConfigPanel = () => {
  const [competency, setCompetency] = useState([]);
  const [competencyOptions, setCompetencyOptions] = useState([]);
  const [validityOptions,setValidityOptions]=useState([]);
  const [isLoading, setLoading] = useState(false);
  const [trainingList, setTrainingList] = useState([]);
  const [showMandatory, setShowMandatory] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [show, setShow] = useState(false);
  const [mandatoryTable, setMandatoryTable] = useState([]);
  const [customDate, setCustomDate] = useState("");
  const [training, setTraining] = useState([]);
  const [validity, setValidity] = useState({});

  const app = useAppContext();

  const validity_intervals = [
    { label: "3 months", value: 3 * 30 },
    { label: "6 months", value: 6 * 30 },
    { label: "9 months", value: 9 * 30 },
    { label: "1 year", value: 365 },
    { label: "2 years", value: 2 * 365 },
    { label: "Custom", value: "custom" },
  ];

  const { employeeList, staticData, staticDataLoading } = useSelector(
    (state) => state.EmployeeListReducer
  );

  useEffect(() => {
    const options = staticData
    .filter((x) => x.type === "competency")
    .map((x) => ({ value: x.id, label: x.description }));
  setCompetencyOptions(options);
  setCompetency(options);
  }, [])

  /////competency////
  useEffect(() => {
    // loadStaticData();
    loadTableData();
    loadTraining();
  }, [refreshKey]);

  useEffect(() => {
    if (tableData.length > 0) {
      loadTable(); // Load table when data is available
    }
  }, [tableData, showMandatory]);


  const getUniqueNamesWithLabelValue = (firstApiData, secondApiData) => {
  const ApiTrainingNames = secondApiData.map(item => item.training_name);

  const uniqueNamesData = firstApiData.filter(item => !ApiTrainingNames.includes(item.name));

  const transformedData = uniqueNamesData.map(item => ({ label: item.name, value: item.name }));

  return transformedData;
};

  const loadTraining = async () => {
    const url = prepareListUrl(TRAINING_PROJECT);
    getListFromOneDrive(app?.authProvider, url)
      .then((response) => {
        let filteredData = response.value.filter((item) => {
          return (
            !String(item.name).includes(".yml") &&
            !String(item.name).includes(".json")
            )
          });
         setTrainingList(filteredData)
      })
      .catch(function (error) {
        utilities.showToast(error.message);
      });
  };

  const loadTable = () => {
    if (showMandatory===1) {
      const data = tableData.filter(
        (course) => course.mandatory === showMandatory
      );
      setMandatoryTable(data);
    } else if(tableData && tableData?.length > 0){
      setMandatoryTable(tableData)
    }
   setLoading(false)
  };

  const loadTableData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        MANDATORY_TRAINING
      );
      if (response.data.errorType === "Error") {
        console.error(response.data.errorMessage);
      } else {
        setTableData(response.data);

      }
    } catch (error) {
      utilities.showToast(error);
    }
  };

  const handleCompetencyChange = (selectedOptions) => {
    const allOption = competencyOptions.find(
      (option) => option.value === "all"
    );

    if (selectedOptions.includes(allOption)) {
      setCompetency([
        ...competencyOptions.filter((option) => option.value !== "all"),
      ]);
    } else setCompetency(selectedOptions);
  };

  const toggleModal = () => {
    setShow((prevShow) => !prevShow);
  };

  const handleValidityChange = (e) => {
    if (e.value === "custom") {
      setValidity(e);
    } else {
      setValidity(e);
      setCustomDate(null); // Reset custom date when selecting predefined interval
    }
  };
const reset = ()=>{
  setTraining([]);
  setCompetency(competencyOptions)
  setCustomDate("");
  setValidity({})
}
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      training_name: training.map((item) => item.label),
      competency: competency.map((item) => item.label),
      valid_thru:365
        // (validity?.value === "custom" ? customDate : null) ||
        // (validity ? validity.value : null),
    };
    try {
      await axios
        .post(
          MANDATORY_TRAINING,
          payload
        )
        .then((res) => {
          if (res.data.errorType === "Error") {
            utilities.showToast(res.data.errorMessage);
          } else {
            utilities.showToast("Added  New Training");
          }
          // console.log("Response from API:", res.data);
          // You can perform further actions based on the response if needed
        });
      setRefreshKey((oldKey) => oldKey + 1);
      reset();
      setShow(false);
    } catch (error) {
      utilities.showToast("Error posting data");
      // Handle error cases
    }
  };

  return (
    <Container>
      <div>
        <i
          className="fa fa-plus-circle fa-2x add-logo"
          onClick={toggleModal}
          src={addLogo}
        />
        <ReusableModal
          disable={true}
          reset={reset}
          show={show}
          toggleModal={toggleModal}
          handleSubmit={handleSubmit}
          title="Add Mandatory Training"
          trainingList={getUniqueNamesWithLabelValue(trainingList,tableData)}
          training={training}
          isLoading={isLoading}
          setTraining={setTraining}
          competencyOptions={competencyOptions}
          competency={competency}
          handleCompetencyChange={handleCompetencyChange}
          validity_intervals={validity_intervals}
          validity={validity}
          handleValidityChange={handleValidityChange}
          setCustomDate={setCustomDate}
        customDate={customDate}
        />
      </div>
      <p className="mt-5 mb-4 mx-0">
        Mandatory Settings - Manage Mandatory trainings.
      </p>
      {isLoading ? (
        <Loader/>
      ) : (
        <table
          id="mandatory-list"
          className="table table-bordered table-striped"
        >
          <thead className="thead-light">
            <tr>
              <th>Training</th>
              <th>Competency</th>
              <th>
                Validity
              </th>
            </tr>
          </thead>
          <tbody>
            {mandatoryTable &&
              mandatoryTable.map((item) => {
                return (
                  <tr>
                    <td>
                      <span className="vertical-sub">{item.training_name}</span>
                    </td>
                    <td>
                      <span className="vertical-sub">{item.competency}</span>
                    </td>
                    <td>
                      <span className="vertical-sub">{item.valid_thru ? daysToYearsAndMonths(item.valid_thru) : "N/A"}</span>
                      <span className="float-right">
                        <EditAction
                          item={item}
                          onSave={() => setRefreshKey((oldKey) => oldKey + 1)}
                          toggleModal={toggleModal}
                          handleSubmit={handleSubmit}
                          title="Edit Mandatory Training"
                          trainingList={trainingList}
                          training={training}
                          isLoading={isLoading}
                          setTraining={setTraining}
                          refreshKey={refreshKey}
                          setRefreshKey={setRefreshKey}
                          competencyOptions={competencyOptions}
                          competency={competency}
                          handleCompetencyChange={handleCompetencyChange}
                          validity_intervals={validity_intervals}
                          validity={validity}
                          handleValidityChange={handleValidityChange}
                          setCustomDate={setCustomDate}
                        />
                        <DeleteAction item={item} setRefreshKey={setRefreshKey}/>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </Container>
  );
};

const EditAction = (props) => {
  const {
    item,
    onSave,
    setRefreshKey,
    isLoading,
    competencyOptions,
    validity_intervals,
  } = props;
  const [name, setName] = useState(item?.training_name);
  const [showEdit, setShowEdit] = useState(false);
  const [competencyEdit, setCompetencyEdit] = useState([]);
  const [validityEdit, setValidityEdit] = useState(null);
  const [editCustomDate, setEditCustomDate] = useState(null); // Initialize with null
  const [editMandatory, setEditMandatory] = useState(item?.mandatory === 1);

  useEffect(() => {
    const initialCompetencyOptions = competencyOptions.filter(
      (option) => item?.competency.includes(option.label)
    );
    const matchingValidityInterval = validity_intervals.find(
      (interval) => interval.value === item?.valid_thru
    );

    if (matchingValidityInterval) {
      setValidityEdit(matchingValidityInterval);
    } else {
      setValidityEdit(validity_intervals.find((interval) => interval.value === "custom"));
      setEditCustomDate(item?.valid_thru);
    }

    setCompetencyEdit(initialCompetencyOptions);
    setName(item ? item.training_name : "");
  }, [item, competencyOptions, validity_intervals]);

  

  const toggleEditModal = () => {
    setShowEdit((prevShow) => !prevShow);
  };
  const toggleEditMandatory = (e)=>{
    setEditMandatory(e.target.checked)
  }
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      training_name: name,
      competency: competencyEdit.map((item) => item.label),
      valid_thru:
        (validityEdit?.value === "custom" ? editCustomDate : null) ||
        (validityEdit ? validityEdit.value : null),
      
    };
    try {
      // console.log(payload, "payloadEdit");
      await axios
        .put(
          MANDATORY_TRAINING,
          payload
        )
        .then((res) => {
          if (res.data.errorType === "Error") {
            utilities.showToast(res.data.errorMessage);
          } else  {
            utilities.showToast(
              "Edited Training " + name
            );
            onSave();
          }
          // console.log("Response from API:", res.data);
          // You can perform further actions based on the response if needed
        });
      setShowEdit(false);
      setRefreshKey((oldKey) => oldKey + 1);
    } catch (error) {
      utilities.showToast("Error posting data");
      // Handle error cases
    }
  };

  const handleEditCompetencyChange = (selectedOptions) => {
    const allOption = competencyOptions.find(
      (option) => option.value === "all"
    );

    if (selectedOptions.includes(allOption)) {
      setCompetencyEdit([
        ...competencyOptions.filter((option) => option.value !== "all"),
      ]);
    } else setCompetencyEdit(selectedOptions);
  };
  const handleEditValidityChange = (e) => {
    if (e.value === "custom") {
      setValidityEdit(e);
    } else {
      setValidityEdit(e);
      setEditCustomDate(null); // Reset custom date when selecting predefined interval
    }
  };
  return (
    <>
      <i
        className="fa fa-edit fa-lg cursor-pointer mx-2"
        onClick={toggleEditModal}
      />
      <ReusableModal
        type={"edit"}
        disable={false}
        show={showEdit}
        toggleModal={toggleEditModal}
        toggleMandatory={toggleEditMandatory}
        mandatorySwitch={editMandatory}
        handleSubmit={handleEditSubmit}
        title={"Edit Training " + " " + name}
        training={name}
        isLoading={isLoading}
        competencyOptions={competencyOptions}
        competency={competencyEdit}
        handleCompetencyChange={handleEditCompetencyChange}
        validity_intervals={validity_intervals}
        validity={validityEdit}
        handleValidityChange={handleEditValidityChange}
        setCustomDate={setEditCustomDate}
        customDate={editCustomDate}
      />
    </>
  );
};

const DeleteAction = ({ item,setRefreshKey}) => {
  const [show, setShow] = useState(false);
  const [btnText, setBtnText] = useState('Delete');
  const [isSaving, setSaving] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = 
    async () => {
      const payload ={"training_name": item.training_name}
      // console.log(item);
      try {
        await axios.delete(MANDATORY_TRAINING, {data: payload}).then((res) => {
          if (res.data.errorType === "Error") {
            utilities.showToast(res.data.errorMessage);
          } else {
            utilities.showToast(
              `Removed ${item.training_name} Training from Mandatory`
            );
           
          }
          // console.log("Response from API:", res.data);
          // You can perform further actions based on the response if needed
        });
        // Perform any additional actions after successful deletion
        setShow(false);
      setRefreshKey((oldKey) => oldKey + 1);
      } catch (error) {
        console.error("Error deleting item:", error);
        // Handle error cases
      }
    
  };

  return (
    <>
      <i className="fa fa-trash fa-lg cursor-pointer" onClick={handleShow} />
      <Modal show={show} onHide={handleClose} contentClassName="w-100">
        <Modal.Header closeButton>
          <Modal.Title>Delete Course Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to delete the training {item.training_name}?</p>
          <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="danger" disabled={isSaving} onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export const ReusableModal = (props) => {
  const {
    type,
    disable,
    reset,
  show,
  toggleModal,
  handleSubmit,
  title,
  trainingList,
  training,
  isLoading,
  setTraining,
  competencyOptions,
  competency,
  handleCompetencyChange,
  validity_intervals,
  validity,
  handleValidityChange,
  setCustomDate,
  customDate

  } = props;

  const onHideHandler = () => {
    toggleModal();
    if (reset) {
      reset();
    }
  };
  return (
    <Modal show={show} onHide={onHideHandler} size="lg" centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {disable && (
              <>
                {" "}
                <Row className="mt-3 mb-2">
                  <Col>Training :</Col>
                  <Col md={6} lg={9}>
                    <>
                    <Select
                  label="Training"
                  isMulti
                  classNamePrefix="react-select"
                  options={trainingList}
                  value={training || []} 
                  isLoading={isLoading}
                  required={true}
                  onChange={(e)=>{setTraining(e)}}
                  // isDisabled={isDisabled}
                ></Select>
                    </>
                    {/* <span style={showMessage}>{errorMessage}</span> */}
                  </Col>
                </Row>
              </>
            )}

            <Row className="mt-3">
              <Col>Competency :</Col>
              <Col md={6} lg={9}>
                <Select
                  label="Competency"
                  isMulti
                  classNamePrefix="react-select"
                  options={competencyOptions}
                  value={competency || []} 
                  isLoading={isLoading}
                  required={true}
                  onChange={handleCompetencyChange}
                  // isDisabled={isDisabled}
                ></Select>
              </Col>
            </Row>
            {/* <Row className="mt-3">
              <Col>Validity :</Col>
              <Col md={6} lg={9}>
                <Select
                  label="Validity"
                  classNamePrefix="react-select"
                  options={validity_intervals}
                  value={validity || null}
                  isLoading={isLoading}
                  required={true}
                  onChange={handleValidityChange}
                  // isDisabled={isDisabled}
                ></Select>
              </Col>
            </Row>
            <Row className="mt-3">
              {validity?.value === "custom" && (
                <>
                  <Col>{type === "edit" ? "Edit" : "Enter" } number of days</Col>
                  <Col md={6} lg={9}>
                    <Form.Control
                      required
                      value={customDate || ''}
                      onChange={(e) => {
                        setCustomDate(e.target.value);
                      }}
                      type="text"
                      placeholder="Enter number of days"
                    />
                    <Form.Control.Feedback type="invalid">
                      "error"
                    </Form.Control.Feedback>
                  </Col>
                </>
              )}
            </Row> */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHideHandler}>
            Cancel
          </Button>
          <Button variant="success" type="submit">
        {type==="edit" ? "Update": "Set Mandatory"}  
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Mandatory;