import { store } from "..";

export const employeeTableHeaders =(staticData, isAdmin, isAdd=false)=> {
  
  let roles = [{value:"", label:'Select'}, ...staticData.filter(x => x.type === 'role').map(x => { return { value: x.id, label: x.description } })];
  let band = [{value:"", label:'Select'}, ...staticData.filter(x => x.type === 'band').map(x => { return { value: x.id, label: x.description } })];
  let competency  = [{value:"", label:'Select'}, ...staticData.filter(x => x.type === 'competency').map(x => { return { value: x.description, label: x.description } })];
  let geography = [{value:"", label:'Select'},{ value: 'India', label: 'India' }, { value: 'Canada', label: 'Canada' }, { value: 'US', label: 'US' }]
  return [
  {
    title: "Emp ID",
    accessor: "employee_id",
    isRequired:true
  },
  {
    title: "Employee Name",
    accessor: "employee_name",
    isRequired:true
  },
  {
    title: "Email ID",
    accessor: "email_id",
    isRequired:true
  },
  {
    title: "Competency",
    accessor: "competency",
    type: 'combo',
    valueList: competency,
  },
  {
    title: "Band",
    accessor: "band_type",
    type: 'combo',
    valueList: band,
  },
  {
    title: "Geography",
    accessor: "geography",
    type: 'combo',
    valueList: geography,
  },
  {
    title: "Role",
    accessor: "role_name",
    isRequired:true,
    type: 'combo',
    valueList: roles,
  },
  {
    title: "Joining Date",
    accessor: "joining_date",
    isRequired:true,
    type: 'date',
  },
  {
    title: "Skill Set",
    accessor: "skill_set",
    isRequired:true
  },
  {
    title: "Secondary Skill Set",
    accessor: "sec_skill_set",
  },
  ...isAdmin?[
  {
    title: "",
    accessor:"delete"
  },
  // ...isAdd?[
  // {
  //   title: "",
  //   accessor: "delete",
  // }]
  //:[]
]:[]
]};
