import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import {
    Col, Form, Row
} from "react-bootstrap";
import Select from "react-select";

export const GoalCourse = forwardRef(({ label, value, selectedValues, onChange, comboOptions, isLoading, isDisabled, courseId, disableMaxField, errorMessage }, ref) => {
    const [showMessage, setShowMessage] = useState(style.hideError);
    const [minValue, setMinValue] = useState(0);
    const [weightage, setWeightage] = useState(0);
    const [selectedComboValues, setSelectedComboValues] = useState([]);

    useEffect(() => {
        if (value !== undefined) {
            let minimum = value?.Minimum !== undefined ? parseInt(value?.Minimum) : 0;
            let weight = value?.Weightage !== undefined ? parseFloat(value?.Weightage) : 0;
            setMinValue((!isNaN(minimum) && minimum > 0) ? minimum : '');
            setWeightage((!isNaN(weight) && weight > 0) ? ((weight > 100) ? 100 : weight) : '');
            setSelectedComboValues(selectedValues);
        }
    }, [value]);

    useImperativeHandle(ref, () => ({
        validate() {
            if (!selectedValues) {
                setShowMessage(style.showError);
                return false;
            } else {
                setShowMessage(style.hideError);
                return true;
            }
        },
    }));

    // This mehtod will be invoked when there is any change happens in minimum, weightage and courses field.
    const onOptionSelect = (minimumValue, weightageValue, selectedValues) => {
        let finalValues = selectedValues ? selectedValues.map(x => { return { id: x.value, typeId: x.typeId, name: x.label, type: x.type, isDisabled: x.isDisabled } }) : [];
        let payload = { CourseTypeId: courseId, Minimum: minimumValue, Weightage: weightageValue, List: [...finalValues] };
        onChange(payload);
    }

    return (
        <>
            <Form>
                <Row className="mb-2">
                    <Col xs={12} md={4} lg={6}>
                        <span className="m-0 font-semi-bold" style={{ verticalAlign: 'sub' }}>{label}</span>
                        {disableMaxField && (
                            <>
                                <span className="font-semi-bold" style={{ fontSize: '12px', marginLeft: '3px', verticalAlign: 'sub' }}>(Min {minValue})</span>
                                <span className="font-semi-bold" style={{ fontSize: '12px', marginLeft: '3px', verticalAlign: 'sub' }}>(Weightage {weightage}%)</span>
                            </>
                        )}
                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        {!disableMaxField && (
                            <Form.Group as={Col} controlId="formBasicEmail">
                                <Row>
                                    <Col style={{textAlign:'right'}}>
                                        <Form.Label className="font-semi-bold m-0" style={{ verticalAlign: 'sub'}}  disabled={isDisabled}>Min#:</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            value={minValue}
                                            required
                                            onChange={(e) => { onOptionSelect(e.target.value, weightage, selectedComboValues) }}
                                            disabled={isDisabled}
                                            type="number" />
                                    </Col>
                                </Row>
                            </Form.Group>
                        )}
                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        {!disableMaxField && (
                            <Form.Group as={Col} controlId="formBasicEmail">
                                <Row>
                                    <Col style={{textAlign:'right'}}>
                                        <Form.Label className="font-semi-bold m-0" style={{ verticalAlign: 'sub'}}>Weightage(%):</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            value={weightage}
                                            required
                                            onChange={(e) => { onOptionSelect(minValue, e.target.value, selectedComboValues) }}
                                            type="number"
                                            disabled={isDisabled}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Select isMulti
                            classNamePrefix="react-select"
                            options={comboOptions}
                            value={selectedComboValues}
                            isLoading={isLoading}
                            required={true}
                            onChange={(options) => onOptionSelect(minValue, weightage, options)}
                            isDisabled={isDisabled}
                        ></Select>
                        <span style={showMessage}>{errorMessage}</span>
                    </Col>
                </Row>
            </Form>
        </>
    )
});

const style = {
    showError: {
        color: "#dc3545",
        display: "inline-block",
        fontSize: "0.875em",
    },
    hideError: {
        color: "#dc3545",
        display: "none",
        fontSize: "0.875em"
    }
}