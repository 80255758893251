import Axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import w_logo from "../assets/wavicle.png";
import { checkAccessList, exportToExcel, getUTCTimeStamp } from "../common/utilities";
import { CustomCombo, Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import { SEND_MAIL_NOTIFICATION, TO_EMAIL, UPDATE_MY_COURSE } from "../constants/urlConstants";

const currentDate = new Date();

const CoursesMasterList = ({ masterList, fetchMasterList, isLoading }) => {
  const [showRangePicker, setShowPicker] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [status, setStatus] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchBy, setSearchBy] = useState("");

  useEffect(() => {
    fetchDetails();
  }, [status, startDate, endDate, showRange]);

  const fetchDetails = () => {
    const payload = {
      ...(showRange
        ? { fromDate: startDate, toDate: endDate }
        : { fromDate: "", toDate: "" }),
      status: status,
    };
    fetchMasterList(payload);
  }

  const handleShowDateRange = (e) => {
    setShowRange(e.target.checked);
  };

  const handleShowPicker = (e) => {
    setShowPicker((prevValue) => !prevValue);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchBy(e.target.value);
  };

  const handleDateRangeChange = (ranges) => {
    setStartDate(getUTCTimeStamp(ranges.selection.startDate));
    setEndDate(getUTCTimeStamp(ranges.selection.endDate));
  };

  const handlePdf = () => {
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = w_logo;
    const columns = ["Employee Name", "Course Name", "Status"];
    const certMasterTable = []
    masterList.forEach((course) => {
      certMasterTable.push([course.employee_name, course.Course_Title, course.learning_status]);
    });
    doc.addImage(img, "png", 15, 5, 100, 30);

    const totalPagesExp = "{total_pages_count_string}";

    doc.setFont("HelveticaNeue", "normal");
    doc.setFontSize(13);
    doc.text(`Courses Master List`, 15, 50);
    doc.text(`From Date:       ${startDate}`, 15, 75);
    doc.text(`To Date:       ${endDate}`, 15, 100);
    let columnStyles = { text: { cellWidth: "wrap" } };
    doc.autoTable(columns, certMasterTable, {
      margin: { left: 15 },
      startY: 150,
      styles: {
        overflow: "linebreak",
        cellWidth: "wrap",
        font: "HelveticaBold",
        fontSize: 11,
        textColor: "black",
      },
      columnStyles,
      headStyles: {
        fillColor: "#d6d8db",
        textColor: "black",
      },
      didDrawPage: (data) => {
        doc.setFontSize(11);
      },
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.setFontSize(11);
    doc.save("CoursesMasterList.pdf");
  };

  const handleExcel = () => {
    if (!masterList) return;
    const dataList = masterList.map((course) => {
      return {
        "Employee Name": course.employee_name,
        "Course Name": course.Course_Title,
        Status: course.learning_status,
      };
    });
    exportToExcel(dataList, "CoursesMasterList.xlsx");
  };

  const StatusDropDown = () => {
    const options = [
      { value: "", text: "All" },
      { value: "pending", text: "Pending" },
      { value: "completed", text: "Completed" },
    ];
    const selectProps = {
      value: status,
      id: "status-combo",
      onChange: handleStatusChange,
    };
    return <CustomCombo selectProps={selectProps} options={options} />;
  };

  const SearchDropDown = () => {
    const options = [
      { value: "", text: "All" },
      { value: "Name", text: "Name" },
      { value: "Course Name", text: "Course Name" },
    ];
    const selectProps = {
      value: searchBy,
      id: "search-combo",
      onChange: handleSearchChange,
    };
    return <CustomCombo selectProps={selectProps} options={options} />;
  };

  const searchHander = (e) => {
    setSearchVal(e.target.value)
  }

  const ActionButtons = ({ course }) => {
    const [isSaving, setSaving] = useState(false);
    const status = course.learning_status;

    const handleAction = (state) => {
      setSaving(true);
      const newStatus = state ? "Approved" : "Rejected";
      const payload = {
        course_id: course.Course_Id,
        employee_id: course.employee_id,
        learning_status: newStatus,
      };
      //console.log(payload);
      const subject = `Course Request ${newStatus}`;
      const mailText = `This is to notify that course request by ${course.employee_name} for ${course.Course_Title} has been ${newStatus}.`;
      Axios.post(UPDATE_MY_COURSE, payload).then(() => {
        utilities.showToast(course.Course_Title + " " + newStatus);
        const emailPayload = {
          to: TO_EMAIL,
          cc: course.email_id,
          subject,
          text: mailText,
        };
        Axios.post(SEND_MAIL_NOTIFICATION, emailPayload);
        fetchDetails();
        setSaving(false);
      }).catch((err) => {
        setSaving(false);
        console.log('err', err);
      });
    };

    return (
      <>
        {isSaving && <>
          <span>Saving...</span>
        </>}

        {!isSaving &&
          <>
            {status === "Completed" ? (
              <>
                {checkAccessList("approve_cert") &&
                  <div className="flex-space-between align-center">
                    <div>
                      <Button className="custom-green-btn" onClick={() => handleAction(true)}>Approve</Button>
                    </div>
                    <div>&nbsp;&nbsp;-or-&nbsp;&nbsp;</div>
                    <div>
                      <Button className="custom-red-btn" onClick={() => handleAction(false)}>Reject</Button>
                    </div>
                  </div>
                }
              </>
            ) : status === "Pending" ? (
              <span className="text-warning">
                <i class="fa fa-clock-o" aria-hidden="true" />
                {status}
              </span>
            ) : status === "Approved" ? (
              <span className="text-success">
                <i class="fa fa-check" aria-hidden="true" />
                {status}
              </span>
            ) : status === "Rejected" ? (
              <span className="text-danger">
                <i class="fa fa-times" aria-hidden="true" />
                {status}
              </span>
            ) : (
              <span>{status}</span>
            )}
          </>
        }
      </>
    );
  };

  if (searchBy === "Name") {
    masterList = masterList.filter((item) =>
      item.employee_name.toLowerCase().includes(searchVal.toLowerCase()))
  }
  else if (searchBy === "Course Name") {
    masterList = masterList.filter((item) =>
      item.Course_Title.toLowerCase().includes(searchVal.toLowerCase()))
  }
  else {
    masterList = masterList.filter((item) =>
      item.employee_name.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.Course_Title.toLowerCase().includes(searchVal.toLowerCase()))

  }

  const defaultSelectionRange = {
    startDate,
    endDate,
    key: "selection",
  };


  return (
    <div>
      <div className="col-flex">
        <div className="flex-space-between my-3">
          <div>
            {"Status : "}
            <StatusDropDown />
          </div>
          <div>
            {"SearchBy: "}
            <SearchDropDown />
          </div>
          <div class="form-group has-search" >
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              class="form-control"
              id="header-search"
              placeholder="Search"
              name="search"
              onChange={searchHander} />
          </div>
          <div>
            <i
              className="fa fa-file-pdf-o fa-2x top-icons"
              onClick={handlePdf}
            />
            <i
              className="fa fa-file-excel-o fa-2x top-icons"
              onClick={handleExcel}
            />
          </div>
        </div>
        <div className="d-flex">
          <div>
            <Form.Check type="switch"
              id="custom-switch"
              label="All Time"
              onChange={handleShowDateRange}
            />
          </div>
          <div className="col-flex">
            {showRange && (
              <span className="mx-2">
                <span>Date:</span>
                <span onClick={handleShowPicker} className="cursor-pointer link-decor mx-2">
                  {`${startDate.toDateString()} - ${endDate.toDateString()}   `}
                  &nbsp;<i className={`fa fa-angle-${showRangePicker ? "down" : "right"}`} />
                </span>
              </span>
            )}
            {showRange && showRangePicker && (
              <DateRangePicker
                ranges={[defaultSelectionRange]}
                onChange={handleDateRangeChange}
                maxDate={currentDate}
              />
            )}
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {masterList && !utilities.isEmptyOrNull(masterList) ? (
              <table id="courses-master-table" className="table table-bordered table-striped">
                <thead className="thead-light">
                  <tr>
                    <th>Employee Name</th>
                    <th>Course Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {masterList.map((course) => (
                      <tr>
                        <td>{course.employee_name}</td>
                        <td style={{ width: '60%' }}>{course.Course_Title}</td>
                        <td><ActionButtons course={course} /></td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            ) : (
              <div>No Certifications !</div>
            )}
          </>
        )}
      </div>
    </div >
  );
};

export default CoursesMasterList;
