import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { updateEmployeeDetails } from "../Auth/action";
import { checkAccessList, exportToExcel } from "../common/utilities";
import { CustomCombo, Loader } from "../components/customComponents";
import { CustomTable } from "../components/CustomTable";
import CustomTabs from "../components/CustomTabs";
import { EditableTable } from "../components/EditableTable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import w_logo from "../assets/wavicle.png";
import { utilities } from "../components/utilities";
import {
  EMPLOYEES_API,
  NOTIFICATION_CONFIG,
  SEND_MAIL_NOTIFICATION,
  MANDATORY_TRAINING,
} from "../constants/urlConstants";
import { mandatoryTrainingsEmail } from "../constants/emailTemplates";
import { CURRENT_ENVIRONMENT } from "../constants/envConstants";
import { employeeTableHeaders } from "./empMgmtConstants";
import "./styles.css";

const EmployeeManagement = (props) => {
  const tabs = [
    { title: "Employee Search", key: "search", content: <EmployeeSearch /> },
    ...(checkAccessList("crud_employee")
      ? [{ title: "Add Employee", key: "add", content: <AddEmployee /> }]
      : []),
  ];

  return (
    <div className="page-wrap">
      <CustomTabs tabs={tabs} />
    </div>
  );
};

const EmployeeSearch = () => {
  const [region, setRegion] = useState({ label: "All", value: "All" });
  const [competency, setCompetency] = useState({ label: "All", value: 0 });
  const [band, setBand] = useState({ label: "All", value: 0 });
  const [searchBy, setSearchBy] = useState("employee_id");
  const [searchVal, setSearchVal] = useState("");
  const [searchResults, setSearchResults] = useState();
  const [selectedEmp, setSelectedEmp] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [regionOptions, setRegionOptions] = useState();
  const [competencyOptions, setCompetencyOptions] = useState();
  const [bandOptions, setBandOptions] = useState();
  const [isLoading, setLoading] = useState(null);

  const { employeeList, staticData, staticDataLoading } = useSelector(
    (state) => state.EmployeeListReducer
  );

  let employeeTableHeadersList = employeeTableHeaders(
    staticData,
    checkAccessList("crud_employee")
  );

  useEffect(() => {
    setRegionOptions([
      { value: "All", label: "All" },
      { value: "India", label: "India" },
      { value: "Canada", label: "Canada" },
      { value: "US", label: "US" },
    ]);
    if (staticData) {
      let competencies = [{ value: 0, label: "All" }];
      let bands = [{ value: 0, label: "All" }];
      setCompetencyOptions([
        ...competencies,
        ...staticData
          .filter((x) => x.type === "competency")
          .map((x) => {
            return { value: x.id, label: x.description };
          }),
      ]);
      setBandOptions([
        ...bands,
        ...staticData
          .filter((x) => x.type === "band")
          .map((x) => {
            return { value: x.id, label: x.description };
          }),
      ]);
    }
  }, [staticData]);

  useEffect(() => {
    let newResults = [];
    let employees = [];

    employees = applyFilter(region, competency, band);

    if (_.isEmpty(searchVal)) {
      setResult(employees);
      return;
    }

    if (searchBy === "name") {
      newResults = employees.filter((item) =>
        item?.employee_name?.toLowerCase().includes(searchVal.toLowerCase())
      );
    } else if (searchBy === "employee_id") {
      newResults = employees.filter((item) =>
        item?.employee_id?.toLowerCase().includes(searchVal.toLowerCase())
      );
    } else if (searchBy === "email_id") {
      newResults = employees.filter((item) =>
        item?.email_id?.toLowerCase().includes(searchVal.toLowerCase())
      );
    } else if (searchBy === "skill_set") {
      const search_strings = searchVal?.toLowerCase().split(",");
      if (search_strings) {
        newResults = employees.filter((item) => {
          let pr_skills = item?.skill_set?.toLowerCase().split(",");
          let se_skills = item?.sec_skill_set?.toLowerCase().split(",");
          let records = [];
          if (pr_skills) {
            records = [...pr_skills];
          }
          if (se_skills) {
            records = [...records, ...se_skills];
          }
          return search_strings.every((x) => records.includes(x));
        });
      }
    }
    setResult(newResults);
  }, [region, competency, band, searchBy, searchVal, employeeList]);

  const handleSearchByChange = (e) => setSearchBy(e.target.value);

  const onHide = () => {
    setShowDelete(false);
    setShowEdit(false);
  };

  const handleEdit = (e, emp) => {
    setShowEdit(true);
    setSelectedEmp(emp);
  };

  const handleDelete = (e, emp) => {
    setShowDelete(true);
    setSelectedEmp(emp);
  };

  const searchRef = useRef(null);

  const debounce_search = _.debounce(() => {
    setSearchVal(searchRef.current.value);
  }, 1000);

  const searchHandler = (e) => {
    debounce_search();
  };

  const options = [
    { value: "employee_id", text: "Employee ID" },
    { value: "name", text: "Name" },
    { value: "email_id", text: "Email ID" },
    { value: "skill_set", text: "Skill Set" },
  ];

  const selectProps = {
    value: searchBy,
    id: "status-combo",
    onChange: handleSearchByChange,
  };

  const setResult = (newResults) => {
    setSearchResults(
      newResults.map((emp) => {
        return {
          ...emp,
          edit: (
            <>
              {checkAccessList("crud_employee") && (
                <i
                  className="fa fa-edit cursor-pointer"
                  onClick={(e) => handleEdit(e, emp)}
                />
              )}
            </>
          ),
          delete: (
            <>
              {checkAccessList("crud_employee") && (
                <i
                  className="fa fa-trash cursor-pointer"
                  onClick={(e) => handleDelete(e, emp)}
                />
              )}
            </>
          ),
        };
      })
    );
  };

  const applyFilter = (selectedRegion, selectedCompetency, selectedBand) => {
    setRegion(selectedRegion);
    setCompetency(selectedCompetency);
    setBand(selectedBand);
    let newList = employeeList;
    let ftRegion = selectedRegion?.label;
    let ftCompetency = selectedCompetency?.label;
    let ftBand = selectedBand?.label;

    if (ftRegion !== "All" && ftCompetency === "All" && ftBand === "All") {
      newList = employeeList.filter((x) => x.geography === ftRegion);
    } else if (
      ftRegion === "All" &&
      ftCompetency !== "All" &&
      ftBand === "All"
    ) {
      newList = employeeList.filter((x) => x.competency === ftCompetency);
    } else if (
      ftRegion === "All" &&
      ftCompetency === "All" &&
      ftBand !== "All"
    ) {
      newList = employeeList.filter((x) => x.band_type === ftBand);
    } else if (
      ftRegion !== "All" &&
      ftCompetency !== "All" &&
      ftBand === "All"
    ) {
      newList = employeeList.filter(
        (x) => x.geography === ftRegion && x.competency === ftCompetency
      );
    } else if (
      ftRegion !== "All" &&
      ftCompetency === "All" &&
      ftBand !== "All"
    ) {
      newList = employeeList.filter(
        (x) => x.geography === ftRegion && x.band_type === ftBand
      );
    } else if (
      ftRegion === "All" &&
      ftCompetency !== "All" &&
      ftBand !== "All"
    ) {
      newList = employeeList.filter(
        (x) => x.competency === ftCompetency && x.band_type === ftBand
      );
    } else if (
      ftRegion !== "All" &&
      ftCompetency !== "All" &&
      ftBand !== "All"
    ) {
      newList = employeeList.filter(
        (x) =>
          x.geography === ftRegion &&
          x.competency === ftCompetency &&
          x.band_type === ftBand
      );
    }

    return newList;
  };

  const handlePdf = () => {
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = w_logo;
    const resourcesTable = document.getElementById("employee-skill-matrix");

    doc.addImage(img, "png", 15, 5, 100, 30);

    const totalPagesExp = "{total_pages_count_string}";

    doc.setFont("HelveticaNeue", "normal");
    doc.setFontSize(13);
    doc.text(`Resource Matrix List`, 15, 50);
    var res = doc.autoTableHtmlToJson(resourcesTable);

    // Remove the last column from the data
    const columns = res.columns.slice(0, -1);
    const data = res.data.map((row) => row.slice(0, -1));

    let columnStyles = { text: { cellWidth: "wrap" } };
    doc.autoTable(columns, data, {
      margin: { left: 15 },
      startY: 70,
      theme: "grid",
      styles: {
        overflow: "linebreak",
        font: "HelveticaBold",
        fontSize: 11,
        textColor: "black",
      },
      columnStyles,
      headStyles: {
        fillColor: "#d6d8db",
        textColor: "black",
      },
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.setFontSize(11);
    doc.save("ResourceList.pdf");
  };

  const handleExcel = () => {
    if (!searchResults) {
      utilities.showToast("No Records to export!");
    }
    const dataList = searchResults.map((emp) => {
      let record = {};
      employeeTableHeadersList.forEach((field) => {
        if (field.title === "Delete") return;
        record = {
          ...record,
          [field.title]: emp[field.accessor],
        };
      });
      return record;
    });
    exportToExcel(dataList, "ResourceList");
  };

  return (
    <div className="mt-4">
      {isLoading || staticDataLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <ConfirmDelete
            show={showDelete}
            employee={selectedEmp}
            onHide={onHide}
          />
          <EditEmployee
            show={showEdit}
            employee={selectedEmp}
            onHide={onHide}
          />
          <div className="options-pane flex-space-between margin-bottom">
            <div className="row-flex align-center mt-4">
              <CustomCombo selectProps={selectProps} options={options} />
              <div className="has-search mx-2">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  id="header-search"
                  className="form-control"
                  placeholder="Search"
                  name="search"
                  ref={searchRef}
                  onChange={searchHandler}
                />
              </div>
            </div>
            <div>
              <p className="font-bold m-0">{"Geography: "}&nbsp;&nbsp;</p>
              <Select
                className="width-medium"
                options={regionOptions}
                defaultValue={{ label: "All", value: "All" }}
                onChange={(option) => applyFilter(option, competency, band)}
              ></Select>
            </div>
            <div>
              <p className="font-bold m-0">{"Competency: "}&nbsp;&nbsp;</p>
              <Select
                className="width-medium"
                options={competencyOptions}
                defaultValue={{ label: "All", value: 0 }}
                onChange={(option) => applyFilter(region, option, band)}
              ></Select>
            </div>
            <div>
              <p className="font-bold m-0">{"Band: "}&nbsp;&nbsp;</p>
              <Select
                className="width-medium"
                options={bandOptions}
                defaultValue={{ label: "All", value: 0 }}
                onChange={(option) => applyFilter(region, competency, option)}
              ></Select>
            </div>
            <div className="row-flex align-center  mt-4">
              <i
                className="fa fa-file-pdf-o fa-2x top-icons"
                onClick={handlePdf}
              />
              <i
                className="fa fa-file-excel-o fa-2x top-icons"
                onClick={handleExcel}
              />
            </div>
          </div>
          <div>
            <CustomTable
              id="employee-skill-matrix"
              columns={employeeTableHeadersList}
              rows={searchResults}
              highlightText={searchVal}
              className={"custom-emp-table"}
            />
          </div>
        </>
      )}
    </div>
  );
};

const EditEmployee = ({ employee, show, onHide, handleSubmit }) => {
  const [updatedEmployee, setUpdatedEmp] = useState(employee);
  const [employee_id, setEmployeeId] = useState(employee.employee_id);
  const [employee_name, setEmployeeName] = useState(employee.employee_name);
  const [email_id, setEmployeeMail] = useState(employee.email_id);
  const [skill_set, setEmployeeSkill] = useState(employee.skill_set);
  const [sec_skill_set, setEmployeeSecSkill] = useState(employee.sec_skill_set);
  const [joiningDate, setJoiningDate] = useState(employee.joining_date);
  const dispatch = useDispatch();
  const employeeList = useSelector(
    (state) => state.EmployeeListReducer.employeeList
  );
  const updateDetails = () => {
    let index = employeeList.findIndex(
      (employeeDetail) =>
        employee.employee_id.toUpperCase() ===
        employeeDetail.employee_id.toUpperCase()
    );
    axios
      .put(`${EMPLOYEES_API}/updateuser`, {
        ...employee,
        defaultEmployeeId: employee.employee_id,
        employee_id: employee_id || employee.employee_id,
        employee_name: employee_name || employee.employee_name,
        email_id: email_id || employee.email_id,
        skill_set: skill_set || employee.skill_set,
        sec_skill_set: sec_skill_set || employee.sec_skill_set,
        joining_date: joiningDate || employee.joining_date,
      })
      .then((res) => {
        if (res) {
          let newEmployeeList = employeeList;
          newEmployeeList[index] = {
            ...employee,
            defaultEmployeeId: employee.employee_id,
            employee_id: employee_id || employee.employee_id,
            employee_name: employee_name || employee.employee_name,
            email_id: email_id || employee.email_id,
            skill_set: skill_set || employee.skill_set,
            sec_skill_set: sec_skill_set || employee.sec_skill_set,
            joining_date: joiningDate || employee.joining_date,
          };
          dispatch(updateEmployeeDetails(newEmployeeList));
          utilities.showToast("User Updated Succesfully");
          onHide();
        }
      })
      .catch(() => {
        utilities.showToast("Couldn't Update User!", true);
      });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Employee</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Group controlId="formBasicCourseTitle">
            <Form.Label>Employee ID</Form.Label>
            <Form.Control
              type="id"
              value={employee_id || employee.employee_id}
              onChange={(e) => {
                setUpdatedEmp({
                  ...updatedEmployee,
                  employee_id: e.target.value,
                });
                setEmployeeId(e.target.value);
              }}
              autoComplete="off"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a Employee ID.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicEmpName">
            <Form.Label>Employee Name</Form.Label>
            <Form.Control
              type="EmpName"
              value={employee_name || employee.employee_name}
              onChange={(e) => {
                setUpdatedEmp({
                  ...updatedEmployee,
                  employee_name: e.target.value,
                });
                setEmployeeName(e.target.value);
              }}
              autoComplete="off"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide Employee Name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicEmailId">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              type="email_id"
              value={email_id || employee.email_id}
              onChange={(e) => {
                setUpdatedEmp({ ...updatedEmployee, email_id: e.target.value });
                setEmployeeMail(e.target.value);
              }}
              autoComplete="off"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide Email ID.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicSkillSet">
            <Form.Label>Skill Set</Form.Label>
            <Form.Control
              type="SkillSet"
              value={skill_set || employee.skill_set}
              onChange={(e) => {
                setUpdatedEmp({
                  ...updatedEmployee,
                  skill_set: e.target.value,
                });
                setEmployeeSkill(e.target.value);
              }}
              autoComplete="off"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide Skill Set.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicSecSkillSet">
            <Form.Label>Sec. Skill Set</Form.Label>
            <Form.Control
              type="sec_skill_set"
              value={sec_skill_set || employee.sec_skill_set}
              onChange={(e) => {
                setUpdatedEmp({
                  ...updatedEmployee,
                  sec_skill_set: e.target.value,
                });
                setEmployeeSecSkill(e.target.value);
              }}
              autoComplete="off"
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={updateDetails}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmDelete = ({ employee, show, onHide, refresh }) => {
  const payload = {
    employee_id: employee.employee_id,
  };

  const dispatch = useDispatch();
  const employeeList = useSelector(
    (state) => state.EmployeeListReducer.employeeList
  );
  const deleteUser = () => {
    axios
      .delete(`${EMPLOYEES_API}/deleteuser`, {
        data: payload,
      })
      .then((res) => {
        if (res) {
          let newEmployeeList = [...employeeList];
          let index = employeeList.findIndex(
            (employeeDetail) =>
              employee.employee_id.toUpperCase() ===
              employeeDetail.employee_id.toUpperCase()
          );
          newEmployeeList.splice(index, 1);
          dispatch(updateEmployeeDetails(newEmployeeList));
          utilities.showToast("User Deleted Succesfully");
          onHide();
        }
      })
      .catch((err) => {
        console.log(err, "Error during Delete user call");
        utilities.showToast("Couldn't Delete User!", true);
      });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Employee</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Are you sure you want to delete user {employee?.employee_name} (Emp
          ID: {employee?.employee_id})
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={deleteUser}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddEmployee = () => {
  const { employeeList, staticData, staticDataLoading } = useSelector(
    (state) => state.EmployeeListReducer
  );
  const columns = React.useMemo(
    () => employeeTableHeaders(staticData, true, true),
    []
  );
  const [isLoading, setLoading] = useState(null);
  const newRow = {
    employee_id: "",
    employee_name: "",
    email_id: "",
    competency: "",
    band_type: "",
    geography: "",
    role_name: "",
    skill_set: "",
    sec_skill_set: "",
    joining_date: "",
  };
  const [data, setData] = React.useState([newRow]);
  const [originalData] = React.useState(data);
  const ccEmails = useRef("");
  const mandatoryTrainings = useRef([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const dispatch = useDispatch();
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value, isDelete) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    if (isDelete) {
      let currData = [...data];
      currData.splice(rowIndex, 1);
      setData([...currData]);
    } else {
      setData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        })
      );
    }
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  useEffect(() => getDetailsToNotifyEmployee(), []);
  const checkMandatoryFields = (dataToValidate) => {
    if (
      dataToValidate &&
      dataToValidate.employee_id &&
      dataToValidate.employee_name &&
      dataToValidate.email_id &&
      dataToValidate.role_name &&
      dataToValidate.band_type &&
      dataToValidate.geography &&
      dataToValidate.competency &&
      dataToValidate.joining_date
    ) {
      return true;
    } else {
      return false;
    }
  };

  const prepareEmail = (employeeDetail, trainingList) => {
    const tdHtml = '<td style="border: 1px solid #ddd; padding: 4px;">';
    const urlEnv = CURRENT_ENVIRONMENT === "dev" ? "dev-" : "";
    const URL = `https://${urlEnv}learning.wavicledata.com/#/trainings/completed/32323986`;
    let table_content = "";

    for (let i = 0; i < trainingList.length; i++) {
      table_content += "<tr>";
      let training = trainingList[i];
      if (
        training.competency.includes(employeeDetail.competency) &&
        training.geography === employeeDetail.geography
      ) {
        const hyperLink = `<a href="${URL}/${training.training_name}">${training.training_name}</a>`;
        table_content += `<td style="border: 1px solid #ddd; padding: 4px;" rowspan="${
          training.subcategory ? training.subcategory.split(", ").length : 1
        }">${hyperLink}</td>`;

        if (training.subcategory) {
          const subModules = training.subcategory.split(", ");
          // eslint-disable-next-line no-loop-func
          subModules.forEach((subModule, index) => {
            if (index !== 0) {
              table_content += "</tr><tr>";
            }
            table_content += `${tdHtml}${subModule}</td>`;
          });
        } else {
          table_content += `${tdHtml}</td>`;
        }

        table_content += "</tr>";
      }
    }

    if (table_content === "") {
      return table_content;
    }

    return mandatoryTrainingsEmail(employeeDetail.employee_name, table_content);
  };
  const getDetailsToNotifyEmployee = () => {
    axios.get(NOTIFICATION_CONFIG).then((config) => {
      if (config.data.errorType === "Error") {
        utilities.showToast(config.data.errorMessage);
      } else {
        ccEmails.current = config.data
          .filter((item) => {
            return item.competency === "All";
          })[0]
          .email_id.join(",");
      }
    });
    axios
      .get(MANDATORY_TRAINING)
      .then((trainings) => (mandatoryTrainings.current = trainings.data));
  };
  const notifyMandatoryTrainings = async (empList, ccEmails) => {
    for (let emp of empList) {
      if (emp.geography.toLowerCase() !== "india") continue;
      const emailContent = prepareEmail(emp, mandatoryTrainings.current);
      if (emailContent) {
        const emailPayload = {
          to: emp.email_id,
          cc: ccEmails,
          subject: "Action Required: Mandatory Trainings for New Employees",
          html: emailContent,
        };
        await axios.post(SEND_MAIL_NOTIFICATION, emailPayload);
      }
    }
  };

  const uploadEmployees = () => {
    setLoading(true);
    let flagValidation = true;
    if (data.length > 0) {
      data.some((employeeDetails) => {
        if (!checkMandatoryFields(employeeDetails)) {
          flagValidation = false;
          return true;
        }
      });
    }
    if (flagValidation) {
      let empList = [];
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 as month is zero-indexed
      const day = String(currentDate.getDate()).padStart(2, "0");

      let newEmployees = data.map((emp) => {
        empList.push({
          employee_name: emp.employee_name,
          email_id: emp.email_id,
          competency: emp.competency,
          geography: emp.geography,
        });
        return {
          employee_id: emp.employee_id,
          employee_name: emp.employee_name,
          email_id: emp.email_id,
          competency: emp.competency,
          band: emp.band_type,
          geography: emp.geography,
          band_type: staticData
            ?.filter((x) => x.type === "band")
            ?.find((o) => o.id === emp.band_type)?.description,
          role: emp.role_name,
          role_name: staticData
            ?.filter((x) => x.type === "role")
            ?.find((o) => o.id === emp.role_name)?.description,
          skill_set: emp.skill_set,
          sec_skill_set: emp.sec_skill_set,
          joining_date: emp.joining_date,
          insert_date: `${year}-${month}-${day}`,
        };
      });

      let payload = {
        Employee_List: newEmployees,
      };

      axios
        .post(`${EMPLOYEES_API}/addemployee`, payload)
        .then((res) => {
          if (res && res.data && res.data.errorMessage) {
            utilities.showToast(res.data.errorMessage);
          } else {
            let newEmployeeList = [...employeeList, ...newEmployees];
            dispatch(updateEmployeeDetails(newEmployeeList));
            notifyMandatoryTrainings(empList, ccEmails.current).finally(() => {
              setLoading(false);
              utilities.showToast("Users Saved Succesfully");
            });
            resetData();
          }
        })
        .catch(() => {
          utilities.showToast("Couldn't Add User!", true);
          setLoading(false);
        });
    } else {
      utilities.showToast("Mandatory fields need to be updated", true);
      setLoading(false);
    }
  };
  const addRow = () => {
    setData([...data, newRow]);
  };
  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => setData(originalData);
  return (
    <div className="emp-mgmt-wrap">
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div style={{ display: "inline-block" }}>
            <i className="fa fa-plus-circle fa-2x add-logo" onClick={addRow} />
          </div>
          <EditableTable
            columns={columns}
            data={data}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
          />
          <div className="display-flex">
            <span className="orange-btn" onClick={resetData}>
              Reset Data
            </span>
            &nbsp;&nbsp;
            <span className="blue-btn" onClick={uploadEmployees}>
              Save
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeManagement;
