import React, { Component } from "react";
// import { learningData } from "./learningMockData";
import axios from "axios";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkAccessList } from "../common/utilities";
import { Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import {
  DELETE_TRAINING,
  GET_COURSE_STATIC_DATA,
  UPCOMING_TRAININGS,
} from "../constants/urlConstants";
import AddTraining from "./AddTraining";
import TrainingHistory from "./TrainingHistory";
import TrainingSummary from "./TrainingSummary";
import UploadParticipants from "./UploadParticipants";
import UploadQuestionnaires from "./UploadQuestionnaires";
import {
  prepareContentUrl,
  prepareListUrl,
  README_FILE_NAME,
  TRAINING_PROJECT,
} from "../constants/oneDriveConstants";
import {
  getListFromOneDrive,
  getFileFromOneDrive,
} from "../Office365/Office365Graph";
import { appContext } from "../Office365/Office365Context";

class Trainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      staticData: [],
      dropDownData: null,
      courseData: null,
      isLoading: false,
      enRollments: [],
      suggestions: props.employeeList,
    };
  }

  fetchCourses = () => {
    this.setState({ isLoading: true });
    axios.get(UPCOMING_TRAININGS).then((res) => {
      this.setState({
        courses: res.data,
        isLoading: false,
      });
    });
  };

  deleteTraining = (dataToDelete) => {
    let payload = {
      training_id: dataToDelete.training_id,
    };
    axios
      .delete(DELETE_TRAINING, {
        data: payload,
      })
      .then((res) => {
        if (res.data && res.data.isSuccess) {
          this.fetchCourses();
        }
      });
  };

  componentDidMount() {
    this.fetchCourses();
    this.fetchStaticData();
    this.fetchDropDown();
    this.fetchTraining();
  }

  changeCourseStatus = (courseDetail) => {
    const indexToUpdate = this.state.courses.findIndex(
      (courseList) => courseList.training_id === courseDetail.training_id
    );
    const tempCourse = [...this.state.courses];
    let courseToUpdate = tempCourse[indexToUpdate];
    courseToUpdate["is_enrollment_open"] =
      courseToUpdate["is_enrollment_open"] === 0 ? 1 : 0;
    this.setState({
      courses: tempCourse,
    });
  };

  getUpcomingTrainings = () => {
    const { suggestions, courses, isLoading, enRollments, staticData } =
      this.state;
    const { isAdmin } = this.props;
    return (
      <div className="column-flex">
        {checkAccessList("crud_train") && (
          <div className="addIcon">
            <AddTraining
              suggestions={suggestions}
              fetchCourses={this.fetchCourses}
              staticValues={staticData}
            />
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {courses.length > 0 ? (
              <div className="row">
                {courses.length &&
                  courses.map((course) => {
                    return (
                      <TrainingSummary
                        isAdmin={isAdmin}
                        topic={course}
                        key={course.id}
                        enRollments={enRollments}
                        deleteTraining={this.deleteTraining}
                        fetchCourses={this.fetchCourses}
                        changeCourseStatus={this.changeCourseStatus}
                        staticValues={staticData}
                      />
                    );
                  })}
              </div>
            ) : (
              <center>
                <p className="no_data">No Upcoming Trainings to Show !!</p>
              </center>
            )}
          </>
        )}
      </div>
    );
  };

  getDefaultTabKey = () => {
    let { match } = this.props;
    if (match?.params) {
      if (match.params[0] && match.params[0].includes("/")) {
        return match?.params[0]?.split("/").includes("completed")
          ? "completed/32323986"
          : "upcoming";
      } else {
        return match?.params[0]?.toLowerCase() === "completed"
          ? "completed/32323986"
          : "upcoming";
      }
    } else {
      return "upcoming";
    }
  };

  fetchStaticData = () => {
    try {
      return axios.get(GET_COURSE_STATIC_DATA).then((response) => {
        this.setState({
          staticData: response.data,
        });
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  fetchTraining = () => {
    const readmePath = prepareContentUrl(TRAINING_PROJECT, README_FILE_NAME);
    this.context?.authProvider &&
      getFileFromOneDrive(this.context?.authProvider, readmePath)
        .then((response) => {
          this.setState({
            courseData: response?.training_history_data,
            isLoading: false,
          });
        })
        .catch(function (error) {
          utilities.showToast(error.message);
        });
  };
  fetchDropDown = () => {
    const url = prepareListUrl(TRAINING_PROJECT);
    this.context?.authProvider &&
      getListFromOneDrive(this.context?.authProvider, url)
        .then((response) => {
          let filteredData = response.value.filter((item) => {
            return (
              !String(item.name).includes(".yml") &&
              !String(item.name).includes(".json")
            );
          });
          this.setState({
            dropDownData: filteredData,
          });
        })
        .catch(function (error) {
          utilities.showToast(error.message);
        });
  };

  render() {
    if (!this.state.courseData || !this.state.dropDownData) return <Loader />;

    return (
      <div className="page-wrap">
        <Tabs
          defaultActiveKey={this.getDefaultTabKey()}
          id="uncontrolled-tab-example"
          onSelect={(e) => {
            this.props.history.push("/trainings/" + e);
          }}
        >
          <Tab eventKey="upcoming" title="UPCOMING TRAININGS">
            {this.getUpcomingTrainings()}
          </Tab>
          <Tab eventKey={"completed/32323986"} title="TRAINING HISTORY">
            <TrainingHistory
              readmeData={this.state.courseData}
              oneDriveData={this.state.dropDownData}
            />
          </Tab>
          {checkAccessList("upload_quest") && (
            <Tab eventKey="upload" title="UPLOAD QUESTIONNAIRES">
              <UploadQuestionnaires
                dropDownData={this.state.dropDownData}
                readmeData={this.state.courseData}
              />
            </Tab>
          )}
          {checkAccessList("upload_attendance") && (
            <Tab eventKey="participants" title="TRAINING ATTENDANCE">
              <UploadParticipants
                dropDownData={this.state.courseData}
                readmeData={this.state.courseData}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.CurrentUserReducer.currentUserDetail.isAdmin,
    employeeList: state.EmployeeListReducer.employeeList,
  };
};

Trainings.contextType = appContext;

export default withRouter(connect(mapStateToProps)(Trainings));
