import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

export const GoalsCombo = forwardRef(({ onChange, value, label, options, errorMessage, isLoading, isDisabled }, ref) => {
  useImperativeHandle(ref, () => ({
    validate() {
      if (!value) {
        setShowMessage(style.showError);
        return false;
      } else {
        setShowMessage(style.hideError);
        return true;
      }
    },
  }));

  const [showMessage, setShowMessage] = useState(style.hideError);

  return (
    <Row>
      <Col className="font-semi-bold" md={6} lg={3}>{label}</Col>
      <Col md={6} lg={9}>
        <Select required
          value={value}
          onChange={onChange}
          options={options}
          borderColor="#dc3848"
          isLoading={isLoading}
          isDisabled={isDisabled}></Select>
        <span style={showMessage}>{errorMessage}</span>
      </Col>
    </Row>
  );
}
);

export const GoalsDateField = ({ onChange, value, label, width, startDate, CurDate, isDisabled, errorMessage }) => {
  //sets the conditions for data
  const handleDate = () => {
    if (label === "Start Date") {
      return CurDate;
    } else if (label === "End Date") {
      return startDate ? startDate : CurDate;
    }
  };
  return (
    <Row>
      <Col className="font-semi-bold align-center" md={12} lg={3}>{label}</Col>
      <Col md={12} lg={9}>
        <Form.Control
          value={value}
          required
          min={handleDate()}
          onChange={(e) => { onChange(e.target.value) }}
          type="date"
          disabled={isDisabled}/>
        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
      </Col>
    </Row>
  );
};

const style = {
  showError: {
    color: "#dc3545",
    display: "inline-block",
    fontSize: "0.875em",
  },
  errorBorder: {
    borderColor: '#dc3848',
  },
  hideError: {
    color: "#dc3545",
    display: "none",
    fontSize: "0.875em",
  },
};
