import React, { useState } from "react";
import { Loader } from "../components/customComponents";
import { useAppContext } from './Office365Context';
import './styles.css';

export default function Welcome(props) {
  const app = useAppContext();
  const [loading, setLoading] = useState(false);
  return (
    <>
      {
        loading && (
          <Loader />
        )
      }
      {props.logout &&
        <span className="logout_button" onClick={app?.signOut}> <span>
        </span>LogOut</span>}
      {!props.logout && !loading &&
        <button className="login_button" onClick={() => {
          app?.signIn(props)
          setLoading(true)
        }}>
          <span>
            <img src={require("../assets/microsoftlogo.png")} alt="" className="logo" />
          </span>
          Continue using microsoft
        </button>
      }
    </>
  );
}