import { Tab, Tabs } from "react-bootstrap";
import React from "react";

const CustomTabs = ({tabs}) => {
  return (
    <div>
      <Tabs defaultActiveKey={tabs?.[0]?.key}>
          {
              tabs.map(tab=>{
                  return (
                      <Tab title={tab.title} eventKey={tab.key}>
                          {tab.content}
                      </Tab>
                  )
              })
          }
        </Tabs>
    </div>
  );
};

export default CustomTabs;
