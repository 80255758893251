import { UPDATE_EMPLOYEE_LIST } from "./action";
import { FETCH_STATIC_DATA } from "./action";

const initState = { employeeList: [], staticData:[], staticDataLoading:true };

export const EmployeeListReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_EMPLOYEE_LIST:
      if (payload.constructor === Array) {
        state = { ...state, employeeList: [...payload] };
      } return state; 
    case FETCH_STATIC_DATA:
      state = { ...state, staticData: payload, staticDataLoading:false }; 
      return state; 
    default:
      return state;
  }
};
