import Axios from "axios";
import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import {
  APPLY_CERTIFICATION, CC_MAIL,
  FROM_EMAIL, SEND_MAIL_NOTIFICATION
} from "../constants/urlConstants";
import './styles.css';

export const CertificationList = ({ certificationData, isLoading, myCerts, isMyCertsLoading, fetchCertMasterList }) => {
  const [tableData , setTableData] = useState(certificationData);
  const [searchVal, setSearchVal] = useState("");
  const searchRef = useRef(null);
  
  const debounce_search = _.debounce(() => {
    setSearchVal(searchRef.current.value);
    }, 1000);

  const searchHander = () =>{
    debounce_search();
  }

  useEffect(()=>{
    let tableData=certificationData;
    if(searchVal){
      tableData = certificationData.filter((item) =>{
        return item.certification_title?.toLowerCase().includes(searchVal.toLowerCase()) || item.issuing_authority?.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.guide_name?.toLowerCase().includes(searchVal.toLowerCase())
    });
    }
    setTableData(tableData);
  },[searchVal]);
  
  useEffect(()=>{
    setTableData(certificationData)
  },[certificationData]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
        <div className="filterContainer">
          <input
                type="text"
                id="header-search"
                className="filter-input"
                placeholder="Search"
                name="search"
                ref = {searchRef}
                onChange={searchHander}
              />
        </div>
        <div className="row">
          {tableData &&
            tableData.map((certificationDetail) => {
              return (
                <CertificationSummary
                  certificate={certificationDetail}
                  key={certificationDetail.id}
                  myCerts={myCerts}
                  isMyCertsLoading={isMyCertsLoading}
                  fetchMasterList= {fetchCertMasterList}
                />
              );
            })}
        </div>
        </>
      )}
    </>
  );
};


const CertificationSummary = ({
  certificate,
  CurrentUserReducer,
  myCerts,
  isMyCertsLoading,
  fetchMasterList
}) => {
  const user = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  const guide_name = certificate.guide_name;
  const { email_id, employee_id, employee_name } = user;
  const handleClick = (title, email_id, guide_email, guide_name) => {
    const mailText = `You have successfully applied for the below certification: \n${title}.\nKindly contact ${guide_name}(${guide_email}) for certification details`;
    const subject = "Wavicle:Certification Applied";
    const emailPayload = {
      from: FROM_EMAIL,
      to: email_id,
      subject,
      text: mailText
    }
    Axios.post(
      SEND_MAIL_NOTIFICATION, emailPayload);

    //  --------------Admin-----------------------

    const adminText = `${employee_name} has been applied '${title}' certification`;
    const adminPayload = {
      from: FROM_EMAIL,
      to: guide_email,
      cc: CC_MAIL,
      subject,
      text: adminText
    }
    Axios.post(
      SEND_MAIL_NOTIFICATION, adminPayload);
    const payload = {
      certification_id: certificate.certification_id,
      employee_id,
      enrollment_status: "Pending",
      progress: Number(0.0).toFixed(2),
    };
    Axios.post(APPLY_CERTIFICATION, payload).then((res) => {
      utilities.showToast("Request Mail has been sent!");
      fetchMasterList();
    });
  };
  const isApply = (
    certification_id,
    certification_title,
    email_id,
    guide_email,
    guide_name
  ) => {
    if (!isMyCertsLoading) {
      const isCertification = myCerts.find((myCerts) => {
        return myCerts.certification_id === certification_id;
      });
      if (isCertification) {
        if (isCertification.enrollment_status === "Pending") {
          return (
            <div
              class="text-primary text-capitalize"
              style={{ borderRadius: "0.3rem" }}
            >
              Applied
            </div>
          );
        } else if (isCertification.enrollment_status === "Completed") {
          return (
            <div
              class=" text-success text-capitalize"
              style={{ borderRadius: "0.3rem" }}
            >
              Done <i className="fa fa-check" aria-hidden="true"></i>
            </div>
          );
        }
      } else {
        return (
          <Button
            variant="primary"
            onClick={() =>
              handleClick(
                certification_title,
                email_id,
                guide_email,
                guide_name
              )
            }
          >
            Apply
          </Button>
        );
      }
    }
  };

  return (
    <div className="col-md-6">
      <Card className="certifications-card" bg="light">
        <Card.Body className="col-flex">
          <Card.Title>{certificate.certification_title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {/* {certificate.certidescription} */}
            Issuing Authority : {certificate.issuing_authority}
          </Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">
            Guide Name : {guide_name ? guide_name : "NULL"}
          </Card.Subtitle>
          <div className="flex-space-between">
            <div>{certificate.level &&
            <span className="badge badge-secondary padding-all certification_level">
              {" "}
              {certificate.level}
            </span>}
            {(!certificate.exam_fee || certificate.exam_fee === 0) &&
              <span className="badge badge-success padding-all certification_type">
                {" "}
                Free
              </span>
            }
            </div>
            <div className="applyButton">
              {isApply(
                certificate.certification_id,
                certificate.certification_title,
                email_id,
                certificate.guide_email,
                certificate.guide_name
              )}
            </div>
            {/* <Button
              className="applyButton"
              variant="primary"
              onClick={() =>
                handleClick(
                  certificate.certification_title,
                  email_id,
                  certificate.guide_email,
                  certificate.guide_name
                )
              }
            >
              Apply
            </Button> */}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
