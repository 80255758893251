import axios from "axios";
import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { checkAccessList } from "../common/utilities";
import { Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import {
  COURSE_LISTING,
  COURSE_MASTER_LIST,
  DELETE_COURSE,
  GET_COURSE_STATIC_DATA
} from "../constants/urlConstants";
import AddCourse from "./addCourse";
import CoursesMasterList from "./CoursesMasterList";
import CourseSummary from "./CourseSummary";
import MyCoursesList from "./MyCourses";
import "./styles.css";

const defaultPayload = { fromDate: "", toDate: "", status: "" };
class Courses extends Component {
  state = {
    courses: [],
    myCourses: [],
    isMasterListLoading: false,
    masterList: [],
    isUpdateStatus: true,
    isLoading: true,
    isAdmin: this.props.isAdmin,
    staticData: [],
  };

  componentDidMount() {
    this.fetchAllCourses();
    this.fetchStaticData();
    if (!this.props.isAdmin) {
      this.fetchCoursesMasterList();
    }
  }



  fetchCoursesMasterList = (payload = defaultPayload) => {
    this.setState({ isMasterListLoading: true });
    const { myCourses } = this.state;
    axios.post(COURSE_MASTER_LIST, payload)
      .then((response) => {
        this.setState({
          isMasterListLoading: false,
          masterList: response.data,
          myCourses: payload.fromDate === '' ? response.data.filter(({ employee_id }) => employee_id === this.props.userDetails.employee_id) : myCourses
        });
      })
      .catch((err) => {
        this.setState({ isMasterListLoading: false });
        console.log(err);
      });
  };

  fetchAllCourses = () => {
    this.setState({ isLoading: true });
    axios
      .get(COURSE_LISTING)
      .then((res) => {
        const { data } = res;
        if (data.errorMessage) {
          this.setState({ isLoading: false });
        } else
          this.setState({
            courses: data,
            isLoading: false,
          });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  deleteCourse = (dataToDelete) => {
    let payload = { Course_Id: dataToDelete.Course_Id };
    axios.delete(DELETE_COURSE, { data: payload })
      .then((res) => {
        if (res?.data?.affectedRows > 0) {
          this.fetchAllCourses()
        }
        if (res?.data[0]?.course_id) {
          utilities.showToast(`${dataToDelete.Course_Title} cannot be deleted. Users have enrolled for the course.`);
        }
      });
  }

  fetchStaticData = () => {
    axios
      .get(
        GET_COURSE_STATIC_DATA
      )
      .then((res) => {
        const data = res.data;
        if (res.data.errorType === 'Error') {
          this.setState({ isLoading: false });
        } else {
          this.setState({ staticData: data })
        }
      });
  }


  render() {
    const { courses, myCourses, isLoading, isMasterListLoading, masterList, staticData } = this.state;
    return (
      <div className="page-wrap">
        <div className="column-flex">
          {isLoading ? (
            <Loader />
          ) : (
            <Tabs defaultActiveKey="all_courses" id="uncontrolled-tab-example">
              <Tab eventKey="all_courses" title="ALL COURSES">
                {checkAccessList("crud_course") && (
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="add-logo">
                        <AddCourse fetchCourses={this.fetchAllCourses} courseList={courses} staticValues={staticData} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  {courses &&
                    courses.map((course) => {
                      return (
                        <CourseSummary
                          course={course}
                          myCourses={this.state.myCourses}
                          key={course.id}
                          isMyCourseLoading={this.state.isMasterListLoading}
                          getMyCourses={this.fetchCoursesMasterList}
                          deleteCourse={this.deleteCourse}
                          courseList={courses}
                          fetchCourses={this.fetchAllCourses}
                          staticValues={staticData}
                        />
                      );
                    })}
                </div>
              </Tab>
              <Tab eventKey="my_learnings" title="MY LEARNINGS">
                <MyCoursesList myCourses={myCourses} getMyCourses={this.fetchCoursesMasterList} isMyCourseLoading={isMasterListLoading} />
              </Tab>
              {checkAccessList(["export_course", "all_learnings"]) && (
                <Tab eventKey="all_learnings" title="ALL LEARNINGS">
                  <CoursesMasterList masterList={masterList} fetchMasterList={this.fetchCoursesMasterList} isLoading={isMasterListLoading} />
                </Tab>
              )}
            </Tabs>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userDetails: state.CurrentUserReducer.currentUserDetail,
    isAdmin: state.CurrentUserReducer.currentUserDetail.isAdmin,
    accessListDetails: state.CurrentUserReducer.accessList
  };
};
export default connect(mapStateToProps)(Courses);
