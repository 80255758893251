import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { utilities } from '../components/utilities';

import { connect } from 'react-redux';
import { GaEvent } from '../App';
import { gaCategory } from '../constants/gaConstants';
import {
    ADD_COURSE
} from '../constants/urlConstants';

const EditCourse = (props) => {
    const course = props.course;
    const courseList = props.courseList;
    const staticValues = props.staticValues;
    const filterCourses = courseList?.filter(courseData => courseData.Course_Title.toLowerCase() != course.Course_Title.toLowerCase());

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [Course_Title, setCourse_Title] = useState(course.Course_Title);
    const [Course_Description, setCourse_Description] = useState(course.Course_Description);
    const [Course_Duration, setCourse_Duration] = useState(course.Course_Duration);
    const [Course_Language, setCourse_Language] = useState(course.Course_Language);
    const [Course_Prerequisite_Skills, setCourse_Prerequisite_Skills] = useState(course.Course_Prerequisite_Skills);
    const [Course_Skill_Level, setCourse_Skill_Level] = useState(course.Skill_Id);
    const [Course_Category_type, setCourse_Category_type] = useState(course.Category_Id);
    const [Course_URL, setCourse_URL] = useState(course.URL);
    const [validated, setValidated] = useState(false);
    const [Course_Technical_Radio, setCourse_Technical_Radio] = useState(course.Course_Type);
    const [Course_Category_Options, setCourse_Category_Options] = useState(staticValues.filter(x => x.type === 'Category'));
    const [Course_SkillSet_Options, setCourse_SkillSet_Options] = useState(staticValues.filter(x => x.type === 'SkillLevel'));
    const [isRedundant, setRedundantCourse] = useState(false);

    useEffect(() => {
        fetchComboValues();
    }, [staticValues]);

    const fetchComboValues = () => {
        setCourse_Category_Options(staticValues.filter(x => x.type === 'Category'));
        setCourse_SkillSet_Options(staticValues.filter(x => x.type === 'SkillLevel'));
    }

    const handleSubmit = (e) => {
        let dataToCheck = filterCourses?.filter(courseData => courseData.Course_Title.toLowerCase() === Course_Title.toLowerCase());
        if (dataToCheck.length > 0) {
            setRedundantCourse(true)
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            setRedundantCourse(false)
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            } else {
                e.preventDefault();
                const payload = {
                    Course_Id: course.Course_Id,
                    Course_Title: Course_Title,
                    Course_Description: Course_Description,
                    Course_Duration: Course_Duration,
                    Course_Language: Course_Language,
                    Course_Prerequisite_Skills: Course_Prerequisite_Skills,
                    Course_URL: Course_URL,
                    Skill_Id: Course_Skill_Level,
                    Category_Id: Course_Category_type,
                    Course_Type: Course_Technical_Radio
                };
                axios
                    .put(ADD_COURSE, payload)
                    .then((res) => {
                        if (res.data.errorType === 'Error') {
                            utilities.showToast(res.data.errorMessage);
                        } else if (res.data.affectedRows > 0) {
                            GaEvent(gaCategory.COURSES, "Updated Course Details", Course_Title);
                            utilities.showToast(
                                'Updated ' + res.data.affectedRows + ' Course Details!!'
                            );
                            props.fetchCourses();

                        } else if (res.data.errorMessage) {
                            utilities.showToast(res.data.errorMessage);
                        }
                    })
                    .catch((err) => {
                        console.log('err', err);
                    });
                setShow(false);
            }
        }

        setValidated(true);


    };

    return (
        <>
            <i className="fa fa-edit cursor-pointer edit-training" onClick={handleShow} />
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit} noValidate validated={validated}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Course</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group controlId="formBasicCourseTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="id"
                                value={Course_Title}
                                onChange={(e) => {
                                    setCourse_Title(e.target.value);
                                    setRedundantCourse(false)
                                }}
                                autoComplete="off"
                                required
                            />
                            {isRedundant && (
                                <Form.Text type="invalid">
                                    <div class="text-danger">The Course already Exists!</div>
                                </Form.Text>
                            )}
                            <Form.Control.Feedback type="invalid">
                                Please provide a Course Title.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="Description"
                                as="textarea"
                                rows="3"
                                value={Course_Description}
                                onChange={(e) => {
                                    setCourse_Description(e.target.value);
                                }}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide Course Description.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicDuration">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control
                                type="id"
                                value={Course_Duration}
                                onChange={(e) => {
                                    setCourse_Duration(e.target.value);
                                }}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide Course Duration.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicLanguage">
                            <Form.Label>Language</Form.Label>
                            <Form.Control
                                type="id"
                                value={Course_Language}
                                onChange={(e) => {
                                    setCourse_Language(e.target.value);
                                }}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide Course Language.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicPrerequisiteSkills">
                            <Form.Label>Prerequisite Skills</Form.Label>
                            <Form.Control
                                type="Description"
                                as="textarea"
                                rows="3"
                                value={Course_Prerequisite_Skills}
                                onChange={(e) => {
                                    setCourse_Prerequisite_Skills(e.target.value);
                                }}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide Prerequisite Skills to attend the course.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicCourseType">
                            <Form.Label className='paddding_right'>Course Type:</Form.Label>

                            <Form.Check
                                inline
                                required
                                label="Technical"
                                name="group1"
                                type={'radio'}
                                checked={Course_Technical_Radio === "Technical"}
                                onChange={(e) => {
                                    setCourse_Technical_Radio("Technical");
                                }}
                                id={'Techical_Radio'}
                            />
                            <Form.Check
                                inline
                                required
                                label="Non Technical"
                                name="group1"
                                type={'radio'}
                                id={'NTechical_Radio'}
                                checked={Course_Technical_Radio === "Non Technical"}
                                onChange={(e) => {
                                    setCourse_Technical_Radio("Non Technical");
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide Category Type.
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group controlId="formBasicCategoryType">

                            <Form.Label>Category Type</Form.Label>
                            <Form.Control as="select"
                                required
                                value={Course_Category_type}
                                onChange={(e) => setCourse_Category_type(e.target.value)}
                            >
                                <option></option>
                                {
                                    Course_Category_Options?.map(x => { return <option value={x.id} >{x.description}</option> })
                                }

                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                                Please provide Category Type.
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group controlId="formBasicSkillLevel">
                            <Form.Label>Skill Level</Form.Label>
                            <Form.Control as="select"
                                required
                                value={Course_Skill_Level}
                                onChange={(e) => { setCourse_Skill_Level(e.target.value) }}

                            >
                                <option></option>
                                {
                                    Course_SkillSet_Options?.map(x => { return <option value={x.id} >{x.description}</option> })
                                }

                            </Form.Control>


                            <Form.Control.Feedback type="invalid">
                                Please provide Skill Level.
                            </Form.Control.Feedback>
                        </Form.Group>




                        <Form.Group controlId="formBasicURL">
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                                required={true}
                                type="id"
                                value={Course_URL}
                                onChange={(e) => {
                                    setCourse_URL(e.target.value);
                                }}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        currentUserDetail: state.CurrentUserReducer.currentUserDetail,
    };
};
export default connect(mapStateToProps)(EditCourse);
