import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import { utilities } from "../components/utilities";
import { SAVE_INDIVIDUAL_GOALS } from '../constants/urlConstants';


export const GoalProgress = ({ label, lists, weight, onUpdate }) => {
    const [minValue, setMinValue] = useState(0);
    const [weightage, setWeightage] = useState(0);
    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
        let count = Object.keys(lists).length;
        if (lists && count > 0) {
            setWeightage(weight);
            setMinValue(count);
            setCourseList(lists);
        }
    }, [lists]);

    return (
        <Row className="mb-3">
            <Col>
                <Row>
                    <Col>
                        <p className='font-semi-bold mb-2'>
                            <span style={{ fontSize: '16px' }}>{label}:</span>
                            <span style={{ fontSize: '12px', marginLeft: '5px' }}>(Min {minValue})</span>
                        </p>
                    </Col>
                    <Col>
                        <p className='font-semi-bold mb-2' style={{ textAlign: 'right' }}>
                            <span style={{ fontSize: '16px' }}>Weightage:</span>
                            <span style={{ fontSize: '16px', marginLeft: '3px' }}>{weightage ? parseFloat(weightage).toFixed(1) : 0}%</span>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {courseList && courseList.map((item) => (
                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            <span className='m-0' style={{verticalAlign:'sub'}}>{item.title}</span>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <CourseAction value={item} onChange={() => onUpdate()} />
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </Col >
        </Row >
    )
};

const CourseAction = ({ value, onChange }) => {
    const item = value;
    const [show, setShow] = useState(false);
    const [text, setText] = useState('');
    const [color, setColor] = useState('');
    const [type, setType] = useState('course');
    const [isClickable, setClickable] = useState(false);
    const [btnCompleteText, setCompleteText] = useState('Complete');
    const [isSaving, setSaving] = useState(false);

    useEffect(() => {
        loadValues();
    }, []);

    const loadValues = () => {
        switch (item.course_type) {
            case "Technical":
                setType("course");
                processState(item.course_status);
                break;
            case "Non Technical":
                setType("course");
                processState(item.course_status);
                break;
            case "Certifications":
                setType("certification");
                processState(item.certfication_status);
                break;
            case "Training":
                setType("training");
                if(item.is_complete) {
                    setText('Completed');
                    setColor('success');
                } else {
                    setText('Pending');
                    setColor('warning');
                }
                break;
            default:
                setType(item.course_type.toLowerCase());
                setClickable(true);
                processState(item.additional_course_status);
        }
    }

    const processState = (state) => {
        if (state) {
            switch (state) {
                case 'Pending':
                    setText('Pending');
                    setColor('warning');
                    break;
                case 'Completed':
                    setText('Completed');
                    setColor('success');
                    break;
                case 'Approved':
                    setText('Approved');
                    setColor('success');
                    break;
                case 'Rejected':
                    setText('Rejected');
                    setColor('danger');
                    break;
                default:
                    setText('Pending');
                    setColor('warning');
            }
        } else {
            setText('Pending');
            setColor('warning');
        }
    }

    const hideModal = () => setShow(false);

    const showModal = () => setShow(true);

    const handleComplete = () => {
        disableSave(true);
        let payload = { "usergoalsId": item.user_goals_id, "courseStatus": "Completed", }
        axios.put(SAVE_INDIVIDUAL_GOALS, payload)
            .then((res) => {
                disableSave(false);
                if (res.data.errorType === 'Error' || res.data.errorMessage) {
                    utilities.showToast(res.data.errorMessage);
                } else if (res.data.affectedRows > 0) {
                    setText('Completed');
                    setColor('success');
                    setClickable(false);
                    onChange();
                    utilities.showToast(item.title + ' is completed');
                }
                hideModal();
            })
            .catch((err) => {
                disableSave(false);
                hideModal();
                console.log('err', err);
            });
    }

    const disableSave = (state) => {
        setCompleteText(state ? 'Saving' : 'Complete');
        setSaving(state);
    }

    return (
        <>
            {isClickable && text === 'Pending' && <Button variant="outline-success" text='light' size="sm" onClick={showModal}>Mark as complete</Button>}
            {isClickable && text === 'Completed' && <Button variant={color} text='light' size="sm" disabled>{text}</Button>}

            {!isClickable && type === 'training' && text === 'Pending' && <Button variant='warning' text='light' size="sm" disabled>Pending</Button>}
            {!isClickable && type === 'training' && text === 'Completed' && <Button variant='success' text='light' size="sm" disabled>Completed</Button>}

            {!isClickable && type === 'course' && text === 'Pending' && <Button variant='warning' text='light' size="sm" disabled>Pending</Button>}
            {!isClickable && type === 'course' && text === 'Completed' && <Button variant='warning' text='light' size="sm" disabled>Pending</Button>}
            {!isClickable && type === 'course' && text === 'Approved' && <Button variant='success' text='light' size="sm" disabled>Completed</Button>}
            {!isClickable && type === 'course' && text === 'Rejected' && <Button variant='danger' text='light' size="sm" disabled>Rejected</Button>}

            {!isClickable && type === 'certification' && text === 'Pending' && <Button variant='warning' text='light' size="sm" disabled>Pending</Button>}
            {!isClickable && type === 'certification' && text === 'Approved' && <Button variant='warning' text='light' size="sm" disabled>Pending</Button>}
            {!isClickable && type === 'certification' && text === 'Completed' && <Button variant='success' text='light' size="sm" disabled>Completed</Button>}
            {!isClickable && type === 'certification' && text === 'Rejected' && <Button variant='danger' text='light' size="sm" disabled>Rejected</Button>}
            
            <Modal show={show} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{item.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to mark this {type} as complete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleComplete} disabled={isSaving}>{btnCompleteText}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}