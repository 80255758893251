import React, { Component } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import WavicleLogo from "../assets/waviclelogo.png";
import { checkAccessList } from "../common/utilities";
import LoginUsingMicrosoft from "../Office365/Office365Welcome";
import { setCurrentUser, setLogOut } from "./action";
import { mainMenu } from "./menuConstants";
import "./style.css";

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: "Admin",
      employee_name: "",
      activeMenu: "Home",
      geography: ""
    };
  }
  componentDidMount() {
    if (this.props.currentUserDetail) {
      const { employee_name, geography } = this.props.CurrentUserReducer.currentUserDetail;
      this.setState({
        employee_name: employee_name,
        geography
      });
    }
    this.refreshActiveMenu(this?.props?.location?.pathname);
  }
  refreshActiveMenu = (pathname) => {
    mainMenu.forEach((menu) => {
      if (pathname === menu?.to) {
        this.setActiveMenu(menu?.title);
      }
    });
  };
  componentWillReceiveProps(nextProps) {
    const newPathName = nextProps?.location?.pathname;
    if (this.props?.location?.pathname !== newPathName) {
      this.refreshActiveMenu(newPathName);
    }
  }

  handleSelect = (e) => {
    this.setState({
      currentUser: e,
    });
    this.props.setCurrentUser(e);
  };
  setActiveMenu = (activeMenu) => {
    this.setState({ activeMenu });
  };

  checkMenuList = (menu) => {
    const { geography } = this.state;
    const exceptionMenuListForIndia = ["GOALS"];
    if (exceptionMenuListForIndia.includes(menu?.title)){
      return geography == "India"
    }else{
      return true
    }
  }

  render() {
    const { employee_name, activeMenu } = this.state;
    return (
      <>
        <Navbar
          bg="light"
          expand="lg"
          className="fixed-top shadowed-box nav-bar-styles"
        >
          <Navbar.Brand>
            <Link
              to="/"
              onClick={() => {
                this.setActiveMenu("HOME");
              }}
              className="link-style"
            >
              <img
                alt=""
                src={WavicleLogo}
                className="d-inline-block align-top"
              />
              <span className="orange-text "> ACADEMY</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="flex-end">
            <Nav className="ml-auto left-menu">
              {mainMenu.map((menu) => {
                return checkAccessList(menu.accessKey) && (
                  <>
                    {menu.hasMenu ? (
                      <NavDropdown
                        title={
                          menu.title === "MY PROFILE"
                            ? `Welcome ${employee_name}`
                            : "Welcome"
                        }
                        className="wa-menu-item"
                        key={menu.id}
                      >
                        {menu.hasMenu && (
                          <MenuDropdown
                            menuList={menu.menuItems}
                            props={this.props}
                          />
                        )}
                      </NavDropdown>
                    ) : (
                      <>
                        {this.checkMenuList(menu) && (
                          <Link
                            to={menu.to}
                            className={`wa-menu-item  link-style ${activeMenu === menu.title ? "menu-active" : ""
                              }`}
                          >
                            <div
                              className={`padding-all`}
                              onClick={() => {
                                this.setActiveMenu(menu.title);
                              }}
                            >
                              {menu.title}
                            </div>
                          </Link>)}
                      </>
                    )}
                  </>
                )
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

const MenuDropdown = ({ menuList, props }) => {
  const logout = (label) => {
    if (label === "SignOut") {
      localStorage.clear();

      setTimeout(function () {
        window.location.reload();
        this.props.setLogOut();
      }, 1);
    }
  };
  const configuration = () => {
    //   if (label == "Configuration") {
    //   props.history.push("/configuration")
  };

  return (
    <>
      {menuList.map((menuItem, index, array) => {
        return checkAccessList(menuItem.accessKey) && (
          <>
            {menuItem.label === "SignOut" ? (
              <LoginUsingMicrosoft logout={true} />
            ) : (
              <Link
                {...menuItem}
                className="dropdown-item link-style justify-content-center"
                role="button"
              >
                {menuItem.label}
              </Link>
            )}
            {index < array?.length - 1 && <NavDropdown.Divider />}
          </>
        );
      })}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ setCurrentUser, setLogOut }, dispatch)
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
    currentUser: state.CurrentUserReducer.currentUser,
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
    accessListDetails: state.CurrentUserReducer.accessList
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
);
