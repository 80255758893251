import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { superadmins, admins } from "../constants/aclConstants";
import React from "react";
import {store} from "../index";

export const openInNewTab = (url) => {
  var win = window.open(url, "_blank");
  if (win) win.focus();
};

export const exportToExcel = (data, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blobData, fileName);
};

export const dateFormatter = (date) => {
  if (!date || !_.isDate(date)) return "";
  let dayOfMonth = date.getDate();
  if (dayOfMonth.toString().length === 1) {
    dayOfMonth = "0" + dayOfMonth;
  }
  let month = date.getMonth() + 1;
  if (month.toString().length === 1) {
    month = "0" + month;
  }
  return date.getFullYear() + "-" + month + "-" + dayOfMonth;
};

export const getUTCTimeStamp = (date) => {
  const dateNumber = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return new Date(Date.UTC(year, month, dateNumber));
};

export const dbDateFormatter = (dbDate) => {
  if (!dbDate) return { timestamp: null, date: "", time: "" };
  const dFields = dbDate?.split(/[- T : . +]/);
  const timestamp = new Date(
    Date.UTC(
      dFields[0],
      dFields[1] - 1,
      dFields[2],
      dFields[3],
      dFields[4],
      dFields[5]
    )
  );
  let hours =
    timestamp.getHours() < 10
      ? "0" + timestamp.getHours()
      : timestamp.getHours();
  let minutes =
    timestamp.getMinutes() < 10
      ? "0" + timestamp.getMinutes()
      : timestamp.getMinutes();

  return {
    timestamp,
    date:
      timestamp.getMonth() +
      1 +
      "/" +
      timestamp.getDate() +
      "/" +
      timestamp.getFullYear(),
    time: hours + ":" + minutes,
  };
};

export const showEllipsis = (str, maxLength) => {
  if (!str) return "";
  return str?.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};

export const isSuperAdmin = (email) => {
  return superadmins.includes(email);
};

export const isAdmin = (email) => {
  return admins.includes(email);
};

export const replaceAll = (string, find, replaceStr) => {
  if (!string || string === null) {
    return "";
  }
  return string.toString().replace(new RegExp(find, "ig"), replaceStr);
};


export const MCQ = (qns, type, correct) => {
  return (
    <div className="col-flex">
      {qns?.map((q) => {
        return (
          <>
            <span className='display-flex '><Form.Check type={type} />
            &nbsp;&nbsp;&nbsp;{q}</span>
          </>
        );
      })}
    </div>
  );
};

export const AccessDataConfig = {
  "cour_cert_train_view":"a01",
  "cour_cert_train_enroll":"a02",
  "export_course":"a03",
  "export_cert":"a04",
  "review_enroll":"a05",
  "assign_course":"a06",
  "crud_course":"a07",
  "crud_train":"a08",
  "crud_employee":"a09",
  "approve_cert":"a10",
  "upload_cert":"a11",
  "search_employee":"a12",
  "upload_quest":"a13",
  "upload_attendance":"a14",
  "all_learnings":"a15",
  "all_courses":"a16",
  "create_goals":"a17",
  "my_employee_goals":"a18",
  "system_configuration":"a19",
  "competency_selection":"a20",
  "goal_access":"a21",
  "goal_read_access":"a22",
  "my_goal_access":"a23",
  "training_admin": "a24",
}

export const checkAccessList = (accessKey) => {
  const accessListData = store.getState()?.CurrentUserReducer?.accessList;
  if(accessListData && accessKey && Array.isArray(accessKey)){
    return accessKey.some(data=>{
        if(accessListData.includes(AccessDataConfig[data]))
          return true
        return false;
    })
  }else{
    return accessListData?.includes(AccessDataConfig[accessKey]) || accessKey === "default_access"
  }
}

export function daysToYearsAndMonths(days) {
  const daysInYear = 365;
  const averageDaysInMonth = 30;
  
  const years = Math.floor(days / daysInYear);
  const remainingDaysOfYear = days % daysInYear;
  const months = Math.floor(remainingDaysOfYear / averageDaysInMonth);
  const remainingDaysOfMonth = remainingDaysOfYear % averageDaysInMonth;
  
  let result = '';
  
  if (years > 0) {
    result += `${years} ${years === 1 ? 'year' : 'years'}`;
  }
  
  if (months > 0) {
    if (years > 0) {
      result += ' and ';
    }
    result += `${months} ${months === 1 ? 'month' : 'months'}`;
  }
  
  if (remainingDaysOfMonth > 0) {
    if (years > 0 || months > 0) {
      result += ' ';
    }
    result += `${remainingDaysOfMonth} ${remainingDaysOfMonth === 1 ? 'day' : 'days'}`;
  }
  
  return result;
}