import React, { Component } from "react";
import { openInNewTab } from "../common/utilities";
import "./styles.css";

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      currentSlide: 0
    };
  }
  componentDidMount() {
    let { children } = this.props;
    this.setState({ total: children.length });
    this.slideInterval = setInterval(this.showNext, 3000);
  }
  componentWillUnmount() {
    clearInterval(this.slideInterval);
  }
  showNext = () => {
    this.setState(prevState => {
      return {
        currentSlide:
          prevState.currentSlide + 1 === prevState.total
            ? 0
            : prevState.currentSlide + 1
      };
    });
  };
  showPrev = () => {
    this.setState(prevState => {
      return {
        currentSlide:
          prevState.currentSlide === 0
            ? prevState.total - 1
            : prevState.currentSlide - 1
      };
    });
  };
  render() {
    const { children } = this.props;
    const currIndex = this.state.currentSlide;
    let imageUrl = "" + children[currIndex].image;
    const url = children[currIndex].url;
    //let description = "Content description";
    const bullets = Array(this.state.total).fill("○");
    bullets[currIndex] = "●";

    return (
      <>
        <div className="slides-div" style={{ height: "30rem" }}>
          <img
            className='carousel-img cursor-pointer'
            src={imageUrl}
            alt={imageUrl}
            onClick = {()=>openInNewTab(url)}
            style={{ maxWidth: "100%" }}
          />
          <div
            className="slideshow-bullets-div"
          >
            {bullets}
          </div>
        </div>
        {/* {description} */}
      </>
    );
  }
}

export default ImageCarousel;
