export const utilities = {
  getDateFromTimeStamp: (date) => {
    let fullDate = new Date(date);
    return fullDate.toLocaleDateString();
  },
  showToast: (toastText, isError) => {
    var x = document.getElementById("snackbar");
    if (typeof toastText === "string" && toastText.includes("no_account_error"))
      toastText = "Session expired, please relogin to continue."
    if (x) {
      const className = isError ? "red show" : "show";
      x.innerHTML = utilities.replaceAll(toastText, "\n", "<br/>");
      x.className = className;
      setTimeout(function () {
        x.className = x.className.replace(className, "");
        console.log(x.className);
      }, 3000);
    }
  },
  isEmptyOrNull: (string) => {
    if (!string || string === null || string === undefined) {
      return true;
    }
    return false;
  },
  replaceAll: (string, find, replaceStr) => {
    if (!string || string === null || typeof string !== "string") {
      return "";
    }
    
    return string.replace(new RegExp(find, "g"), replaceStr);
  }
};
