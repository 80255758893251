import React from "react";
import { Step, Steps, Wizard } from "react-albus";
import { BottomNavigation, TopNavigation } from "./TopNavigation";

export const CustomWizard = (props) => {
  const { steps, setShowWizard, className, onSubmit } = props;

  const topNavClick = (currentActiveStep, targetStepItem, steps, push) => {
    push(targetStepItem.id);
  };

  return (
    <div className={`${className} col-flex`}>
      <Wizard className="flex-space-between">
        <TopNavigation
          className="justify-content-center"
          disableNav={true}
          setShowWizard={setShowWizard}
          topNavClick={(currentActiveStep, targetStepItem, steps, push) => {
            topNavClick(currentActiveStep, targetStepItem, steps, push);
          }}
        />
        <Steps>
          {steps?.map((step, i) => {
            return (
              <Step key={i} id={step.name} name={step.name}>
                {step.content}
              </Step>
            );
          })}
        </Steps>
        <BottomNavigation
          setShowWizard={setShowWizard}
          onSubmit={onSubmit}
          setRefresh={props.setRefresh}
          bodyId="assesment-wizard"
        />
      </Wizard>
    </div>
  );
};
