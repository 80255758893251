import { Client, PageIterator } from '@microsoft/microsoft-graph-client';
import { endOfWeek, startOfWeek } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

let graphClient = undefined;

function ensureClient(authProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });
  }
  return graphClient;
}

export async function getUser(authProvider){
  ensureClient(authProvider);

  // Return the /me API endpoint result as a User object
  const user = await graphClient?.api('/me')
    // Only retrieve the specific fields needed
    .select('displayName,userPrincipalName')
    .get();
  return user;
}

export async function getFileFromOneDrive(authProvider, path){
  ensureClient(authProvider);
  // Return's the file data as a response
  const data = await graphClient?.api(path)
    .get();
  return data;
}
export async function getListFromOneDrive(authProvider, path){
  // Return's the List of files in a directory
  ensureClient(authProvider);
  const data = await graphClient?.api(path)
    .get();
  return data;
}



export async function getUserWeekCalendar(authProvider, timeZone) {
ensureClient(authProvider);

// Generate startDateTime and endDateTime query params
// to display a 7-day window
const now = new Date();
const startDateTime = zonedTimeToUtc(startOfWeek(now), timeZone).toISOString();
const endDateTime = zonedTimeToUtc(endOfWeek(now), timeZone).toISOString();

// GET /me/calendarview?startDateTime=''&endDateTime=''
// &$select=subject,organizer,start,end
// &$orderby=start/dateTime
// &$top=50
var response = await graphClient
.api('/me/calendarview')
.header('Prefer', `outlook.timezone="${timeZone}"`)
.query({ startDateTime: startDateTime, endDateTime: endDateTime })
.select('subject,organizer,start,end')
.orderby('start/dateTime')
.top(25)
.get();

if (response["@odata.nextLink"]) {
// Presence of the nextLink property indicates more results are available
// Use a page iterator to get all results
var events = [];

// Must include the time zone header in page
// requests too
var options = {
headers: { 'Prefer': `outlook.timezone="${timeZone}"` }
};

var pageIterator = new PageIterator(graphClient, response, (event) => {
events.push(event);
return true;
}, options);

await pageIterator.iterate();

return events;
} else {

return response.value;
}
}