import Axios from "axios";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import { makeHttpRequest } from "../common/http_request_util";
import { utilities } from "../components/utilities";
import { SUBMIT_TRAININGS } from "../constants/urlConstants";

const UploadParticipants = (props) => {
  const validAttenFileHeaders = [
    "employee_id",
    "training_id",
    "module_id",
    "score",
    "score_percentage",
    "is_pass",
    "training_name",
    "is_complete",
    "completion_date",
  ];
  const [file, setfile] = useState([]);

  const parsePostData = (data) => {
    let dataToParse = Object.keys(data);
    if (dataToParse.length > 0) {
      let parsedData = [];
      dataToParse.forEach((items) => {
        let obj = {
          ...data[items],
          employee_id: items,
        };
        parsedData.push(obj);
      });
      return parsedData;
    }
  };

  const fileValidation = () => {
    var fileInput = document.getElementById("atten_upload");

    var filePath = fileInput.value;
    var allowedExtensions = /(\.xlsx|\.xlsm|\.xml|\.xls)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  const headerCheck = (header) => {
    let flag = 0;
    if (header?.length > 0) {
      header.forEach((data) => {
        if (!validAttenFileHeaders.includes(data)) {
          flag++;
        }
      });
    } else {
      flag = 1;
    }
    return flag === 0;
  };
  const idValid = (t_id, t_list) => {
    let index = t_list.findIndex((a) => {
      return a.training_id === t_id;
    });

    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleFile = (e) => {
    const file = e.target.files[0];
    setfile(file);
  };

  const handleUpload = () => {
    if (fileValidation()) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = ({ target: { result } }) => {
        /* Parse data */
        const wb = XLSX.read(result, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws);
        let header = "";
        if (data.length > 0) header = Object.keys(data[0]);

        if (!headerCheck(header)) {
          utilities.showToast("Invalid data in sheet " + wsname);
          document.getElementById("atten_upload").value = null;
          return true;
        }
        let obj = {};
        var date = new Date();
        let last_updated = date.toISOString().slice(0, 19).replace("T", " ");
        data.forEach((trainingDetails) => {
          let modules = [];
          if (obj[trainingDetails.employee_id]) {
            modules = [...obj[trainingDetails.employee_id].modules];
          }
          const completionDate = XLSX.SSF.format(
            "dd-mm-yyyy",
            trainingDetails.completion_date
          );
          let moduleObj = {
            module_id: trainingDetails.module_id,
            score: trainingDetails.score,
            score_percentage: trainingDetails.score_percentage,
            employee_id: trainingDetails.employee_id,
            training_id: trainingDetails.training_id,
            is_pass: trainingDetails.is_pass,
            completion_date: completionDate,
          };

          modules.push(moduleObj);
          const moduleCount = findModuleCount(trainingDetails.training_id);
          const attendedModules = modules.filter((m) => m?.is_pass).length;
          const progress_percentage = (attendedModules / moduleCount) * 100;
          obj[trainingDetails.employee_id] = {
            training_id: trainingDetails.training_id,
            is_complete: trainingDetails.is_complete,
            progress_percentage,
            last_updated: last_updated,
            training_name: trainingDetails.training_name,
            modules: [...modules],
          };
        });

        let postData = {
          trainingDetails: parsePostData(obj),
        };
        const t_id = postData?.trainingDetails[0].training_id;
        const t_list = props.dropDownData;

        if (idValid(t_id, t_list)) {
          Axios.post(SUBMIT_TRAININGS, postData)
            .then((res) => {
              if (res?.data?.errorMessage) {
                utilities.showToast(res?.data?.errorMessage);
              } else {
                utilities.showToast("Uploaded Successfully");
                document.getElementById("atten_upload").value = null;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          utilities.showToast("training Id does not exist");
          document.getElementById("atten_upload").value = null;
        }
      };
      reader.readAsBinaryString(file);
    } else {
      utilities.showToast(
        "Unsupported file extention. Supported formats are .xlsx, .xlsm, .xml, .xls"
      );
      document.getElementById("atten_upload").value = null;
    }
  };
  const findModuleCount = (trainingId) => {
    const training = props.readmeData.find(
      ({ training_id }) => training_id === trainingId
    );
    return training ? training?.modules?.length : 0;
  };
  return (
    <div>
      <div style={{ marginTop: "2%" }}>
        <h3>Training Attendance</h3>
        <p>
          <i>
            Please upload Excel with these headers :{" "}
            {validAttenFileHeaders.join(" ,")}
          </i>
          <i> (format:dd-mm-yyyy)</i>
        </p>
        <p>
          <i>
            Steps: <br />
            i. Update README.json <br />
            ii. Upload files and ppts in Academy OneDrive
            <br />
            iii. check all the data in the file
            <br />
            <br />
            Note: <br />
            Uploading attendance for a training would overwrite any assesments
            already taken through Wavicle Academy
            <br /> Attendance for only one training should be uploaded
          </i>
        </p>
      </div>
      <div>
        <input
          type="file"
          id="atten_upload"
          onChange={(e) => {
            handleFile(e);
          }}
        />
      </div>
      <div>
        <button
          type="button"
          className="btn btn-primary my-2"
          onClick={() => {
            handleUpload();
          }}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default UploadParticipants;
