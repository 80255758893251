import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { replaceAll } from "../common/utilities";
import { Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import BrownbagsWizard from "./BrownbagsWizard";
import "./style.css";
import { useAppContext } from '../Office365/Office365Context';
import {prepareContentUrl, prepareListUrl, README_FILE_NAME, BROWNBAGS_PROJECT} from '../constants/oneDriveConstants';
import { getFileFromOneDrive, getListFromOneDrive } from '../Office365/Office365Graph';


const Brownbags = (props) => {
  const [itemList, setItemList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChildDirectory, setIsChildDirectory] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [videoLinksJson, setVideoLinksJson] = useState([]);
  const [isVideoLinksAvailable, setVideoLinksAvailable] = useState(false);
  const [videoLinksTableData, setVideoLinksTableData] = useState({});
  const [filterValue, setFilterValue] = useState("");
  const [showWizard, setShowWizard] = useState(false);
  const [selectedVideoLink, setSelectedVideoLink] = useState(null);
  const [selectedPath, setSelectedPath] = useState("");
  // const [moduleData, setModuleData] = useState([]);
  const searchRef = useRef(null);

  const app = useAppContext();

  let history = useHistory();

  const openRootItem = () => {
    setIsLoading(true);
    setIsChildDirectory(false);
    setVideoLinksAvailable(false);
    // get subgroups projects
    const url = prepareListUrl(BROWNBAGS_PROJECT);

    getListFromOneDrive(app?.authProvider, url)
      .then(function (response) {
        setItemList(response.value);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        utilities.showToast(error.message);
      });
  };


  const downloadBlobFile = (child_url) => {
    setIsLoading(true);
        if (child_url.startsWith('https')){
          const link = document.createElement('a');
          link.href = child_url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          getListFromOneDrive(app?.authProvider, child_url).then((response) =>{
            setItemList(response.value);
            setIsChildDirectory(true);
        }).catch(function (error) {
          setIsLoading(false);
          utilities.showToast(error.message);
        });
      }
      setIsLoading(false);
  };

  const setVideoLinksJsonData = (projectId) => {
    const readmePath = prepareContentUrl(projectId, README_FILE_NAME)
    getFileFromOneDrive(app?.authProvider, readmePath).then((response) => {
      setVideoLinksJson(response.BrownBagsData);
    })
      .catch(function (error) {
        utilities.showToast(error.message);
      });
  };

  const setVideoLinksForFolder = (folderPath) => {
    const filteredIndex = videoLinksJson?.findIndex(data =>
      data.training_name === folderPath
    )
    let testData = [];
    if (filteredIndex >= 0)
      testData = videoLinksJson[filteredIndex];

    var filteredVideoLinks = testData
      ? testData
      : "";
    if (filteredVideoLinks !== "") {
      setVideoLinksAvailable(true);
      setVideoLinksTableData(filteredVideoLinks);
    }
  };

  const openChildItem = (childItem) => {
    let params = props.match.params[0];
    if (params.split("/").length < 2) {
      props.history.push(props.match.url + "/" + childItem.id);
      setSelectedPath("")
    } else if (params.split("/").length >= 2) {
      props.history.push(props.match.url + "/" + childItem.name);
      setSelectedPath(childItem.name);
    }
    setIsLoading(true);
    var child_url = "";
    var responseType = "";

    if ('@microsoft.graph.downloadUrl' in childItem) {
      if (currentProjectId) {
        child_url = childItem['@microsoft.graph.downloadUrl']
      } else {
        utilities.showToast("No parent projectId set! State Error!");
      }
    } else {
      setVideoLinksForFolder(childItem.name);
      if (currentProjectId) {
        child_url = prepareListUrl(`${BROWNBAGS_PROJECT}/${childItem.name}`)
      } else {
        utilities.showToast("No parent projectId set! State Error!");
      }
    }
    downloadBlobFile(child_url, responseType);
  };


  useEffect(() => {
    const { path, projectId } = deriveParamsFromUrl();
    if (projectId && path) {
      setVideoLinksAvailable(true);
      setVideoLinksForFolder(path);
    }
  }, [videoLinksJson]);

  useEffect(() => {
    checkRoute();
  }, [props.match.params]);

  const checkRoute = () => {
    const { match } = props;
    if (props) {
      let url = "";
      const { params, projectId, path } = deriveParamsFromUrl();
      if (params) {
        if (match.params) {
          if (projectId && path) {
            url = prepareListUrl(`${BROWNBAGS_PROJECT}/${path}`)
            setVideoLinksJsonData(BROWNBAGS_PROJECT);
            setSelectedPath(path);
          } else if (projectId) {
            url = prepareListUrl(BROWNBAGS_PROJECT)
            setSelectedPath("")
          } else {
            openRootItem();
          }
        }
      }
      setCurrentProjectId(projectId);

      if (url) {
        downloadBlobFile(url);
      }
    }
  };

  const deriveParamsFromUrl = () => {
    const { match } = props;
    const params = match?.params[0];
    const projectId = params?.split("/")[1];
    const path = params?.split("/")[2];
    return { params, projectId, path };
  };

  const { projectId, path } = deriveParamsFromUrl();
  const downloadFile = (fileName) => {
    fileName = replaceAll(fileName, " ", "_");
    const item = itemList.find(({ name }) => name === fileName);
    if (item) openChildItem(item);
  };
  const checkFilter = (item) => {
    if (projectId && !path) {
      if (filterValue && filterValue.length >= 2) {
        if (
          item &&
          item.name.toLowerCase().includes(filterValue.toLowerCase())
        ) {
          return item;
        }
      } else {
        return item;
      }
    } else {
      return item;
    }
  };

  const handleWizard = (videoLink) => {
    if (!videoLink.video_link) return;
    let obj = { ...videoLinksTableData };
    delete obj['modules'];
    setSelectedVideoLink({ ...obj, ...videoLink });
    setShowWizard(true);
  }
  const closeWizard = () => {
    setSelectedVideoLink(null);
    setShowWizard(false);
  };
  
  // const getModuleProgress = (data) => {
  //   let filterData = moduleData?.filter(x => x.module_id == data.module_id && x.is_pass && videoLinksTableData.training_id == x.training_id);
  //   return filterData.length > 0;
  // }

  const getPresentationLinks = (data) => {
    let documentLinks = data.document_links.map(videoLink => {
      return (
        <>
          {videoLink &&
            videoLink.startsWith("http") ? (
            <a href={videoLink} target="_blank" rel="noreferrer">
              {videoLink}
            </a>
          ) : (
            <span
              className="link-decor"
              onClick={() => downloadFile(videoLink)}
            >
              {videoLink}
            </span>
          )}
        </>
      )
    })
    return documentLinks
  }

  const debounce_search = _.debounce(() => {
    setFilterValue(searchRef.current.value);
  }, 1000);

  return (
    <div className="page-wrap">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="brownbagsContainer">
              {!path && projectId && (
                <div class="form-group has-search">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    id="header-search"
                    class="form-control"
                    placeholder="Search..."
                    name="search"
                    ref={searchRef}
                    onChange={(e) => {
                      debounce_search();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {isChildDirectory && isVideoLinksAvailable ? (
              <div className="col-md-1">
                <div
                  style={{ padding: "5%" }}
                  onClick={() => {
                    history.goBack();
                    setVideoLinksAvailable(false);
                    setVideoLinksTableData({});
                  }}
                >
                  <i className="fa fa-arrow-circle-left fa-2x" style={{ color: "#5776E7" }} />
                </div>{" "}
              </div>
            ) : (
              ""
            )}
            {
              //listing folder/file
              itemList.map((item, index) =>
                  !String(item.name).includes(".yml") &&
                  !String(item.name).includes(".json") &&
                  checkFilter(item, index) ? (
                  <div className={isVideoLinksAvailable ? "col-md-3 d-none" : "col-md-3"}>
                    <Card
                      className="history-card"
                      onClick={() => openChildItem(item)}
                    >
                      <span>
                        {item.name}
                        {'@microsoft.graph.downloadUrl' in item ? (
                          <text className="pull-right">&darr;</text>
                        ) : (
                          ""
                        )}
                      </span>
                    </Card>
                  </div>
                ) : (
                  ""
                )
              )
            }
          </div>


          {isVideoLinksAvailable ? (
            <div style={{ width: "100%" }}>
              <BrownbagsWizard
                show={showWizard}
                closeWizard={closeWizard}
                videoLink={selectedVideoLink}
                selectedPath={selectedPath}
                setShowWizard={setShowWizard}
                videoLinksTableData={videoLinksTableData?.modules}
              />

              <h4>{selectedPath}</h4>
              <span>
                {videoLinksTableData.training_description}
              </span>

              <div style={{ paddingTop: "2%" }}>
                <h5>Presenter : {videoLinksTableData.trainer_name}</h5>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  height: "100%",
                }}
              >
                <table className="table table-striped table-bordered table-hover training-history-table ">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Presentation</th>
                      {/* <th>Youtube</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {videoLinksTableData?.modules?.map((videoLink) => (
                      <tr>
                        {/* <td> <a href={videoLink.video_link} target="_blank">{videoLink.session_name}</a></td> */}
                        <td>
                          <span
                            className={`${videoLink.video_link ? 'link-decor' : ''}`}
                            onClick={() => {
                              handleWizard(videoLink);
                            }}
                          >
                            {videoLink.module_name}
                          </span>
                        </td>
                        <td>
                          {getPresentationLinks(videoLink)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <h5>Glossaries :  </h5>
              <div>
                {
                  //listing folder/file
                  itemList.map((item, index) =>
                    !String(item.name).includes(".yml") &&
                    !String(item.name).includes(".json") &&
                    checkFilter(item, index) && (
                      <a className="link-decor" onClick={() => openChildItem(item)}>{item.name}{index > 1 ? " , " : ""} </a>
                    ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(Brownbags);
