import axios from "axios";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { SEND_MAIL_NOTIFICATION, TO_EMAIL, UPDATE_MY_COURSE } from "../constants/urlConstants";
import { useSelector } from "react-redux";
import { utilities } from "../components/utilities";

const MyCoursesList = ({ myCourses, getMyCourses, isMyCourseLoading }) => {
  const currentUserDetail = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  const handleClick = (course) => {
    axios
      .post(UPDATE_MY_COURSE, {
        course_id: course.Course_Id,
        employee_id: currentUserDetail.employee_id,
        learning_status: "Completed",
      })
      .then((res) => {
        getMyCourses();
        const mailText = `Hi ${currentUserDetail.employee_name},\n\n      Congratulations! You have successfully completed the '${course.Course_Title}' course.`
        const emailPayload = {
          to: currentUserDetail.email_id,
          subject: "Course Completed:" + course.Course_Title,
          text: mailText
        }
        axios.post(SEND_MAIL_NOTIFICATION, emailPayload).then(
          utilities.showToast("Updated Course Status.")
        )
      });
  };

  return (
    <>
      {isMyCourseLoading ? (
        <Spinner
          animation="grow"
          variant="primary"
          className="spinner_center "
        />
      ) : (
        <table
          id="my-courses-list"
          className="table table-bordered table-striped"
        >
          <thead className="thead-light">
            <tr>
              <th>Course Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {myCourses && myCourses.length > 0 ? (
              myCourses.map((courseDetail) => {
                return (
                  <tr>
                    <td>
                      <span style={{ verticalAlign: 'sub' }}>{courseDetail.Course_Title}</span>
                    </td>
                    <td>
                      <span style={{ verticalAlign: 'sub' }}>{courseDetail.learning_status}</span>
                    </td>
                    <td>
                      {courseDetail.learning_status !== "Approved" &&
                        courseDetail.learning_status !== "Completed" && (
                          <Button className='custom-blue-btn'
                            onClick={() => handleClick(courseDetail)}>
                            Mark Complete
                          </Button>
                        )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <h3>No Courses Assigned.</h3>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default MyCoursesList;
