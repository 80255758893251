import Axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { dateFormatter } from "../common/utilities";
import { utilities } from "../components/utilities";
import {
  SEND_MAIL_NOTIFICATION, TO_EMAIL, UPDATE_CERTIFICATE_STATUS
} from "../constants/urlConstants";

const currentDate = dateFormatter(new Date());
const CertCompletionForm = (props) => {
  const [comments, setComments] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [fees, setFees] = useState('');
  const [dateOfConf, setDateOfConf] = useState(currentDate);
  const [dateOfCert, setDateOfCert] = useState(currentDate);
  const user = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  const handleSubmit = (event) => {
    const { certification } = props;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValidated(true);
    if (
      utilities.isEmptyOrNull(fees) ||
      utilities.isEmptyOrNull(dateOfConf) ||
      utilities.isEmptyOrNull(dateOfCert)
    ) {
      return;
    }
    event.preventDefault();

    setSubmitting(true);
    Axios.post(UPDATE_CERTIFICATE_STATUS, {
      certification_id: certification.certification_id,
      employee_id: user.employee_id,
      status: "Completed",
    }).then((res) => {
      props.refresh();
      const subject = "Certificate Completed";
      const content = `${user.employee_name} has completed the certification: ${certification.certification_title}. Kindly review details`;
      const mailText = `${content}.
    Comments :  ${comments}
    Date Of Probation : ${dateOfConf}
    Date Of Certification : ${dateOfCert}
    ApproximateFee (Rs) : ${fees}`;
      const payload = {
        to: TO_EMAIL,
        cc: user.email_id,
        subject,
        text: mailText,
      };
      Axios.post(SEND_MAIL_NOTIFICATION, payload)
        .then((response) => {
          setSubmitting(false);
          utilities.showToast("Updation mail sent");
          props.onHide();
        })
        .catch((err) => {
          setSubmitting(false);
          props.onHide();
        });
    });
  };
  const handleFeeChange = (e) => {
    const pattern = /^[0-9]*?(\.)?([0-9]{1,2})?$/;
    if(!pattern.test(e.target.value)){
      e.stopPropagation();
      return;
    }
    let val = parseFloat(e.target.value, 10);
    if (isNaN(val)) {
      setFees("");
    } else {
      setFees(e?.target?.value);
    }
  };
  return (
    <Modal {...props}>
      <Form onSubmit={handleSubmit} validated={validated}>
        <Modal.Header closeButton>
          <Modal.Title>Certification Completion Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicComment">
            <Form.Label>Comments:</Form.Label>
            <Form.Control
              id="comments"
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
            <Form.Label>Probation Confirmation Date </Form.Label>
            <Form.Control
              id="date-of-conf"
              type="date"
              value={dateOfConf}
              max={currentDate}
              required
              onChange={(e) => {
                setDateOfConf(e.target.value);
              }}
            />
            <Form.Label>Date of Certification </Form.Label>
            <Form.Control
              id="date-of-cert"
              type="date"
              value={dateOfCert}
              required
              max={currentDate}
              onChange={(e) => {
                setDateOfCert(e.target.value);
              }}
            />{" "}
            <Form.Label>Approximate Fees (Rs) </Form.Label>
            <Form.Control
              value={fees}
              required
              onChange={handleFeeChange}
            />
            <Form.Control.Feedback type="invalid">
              *Required
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          {isSubmitting ? (
            <Spinner animation="border" role="status" />
          ) : (
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Send Mail
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
  };
};
export default connect(mapStateToProps)(CertCompletionForm);
