import React from "react";
import Certifications from "./Certifications";
import Configuration from "./Configuration/Configuration";
import Courses from "./Courses";
import EmployeeManagement from "./EmployeeMgmt";
import Reports from "./Reports";
import { Goals } from "./Goals";
import { HelpDoc } from "./HelpDoc/WavicleAcademy_HelpDoc";
import Home from "./Home";
import Trainings from "./Trainings";
import Brownbags from "./Trainings/Brownbags";
import MyTrainings from "./Trainings/MyTrainings";

const ErrorPage = (props) => {
    const msg = props?.location?.state?.msg;
    return (
        <div className="display-center align-center screen-height">
            <span className="error-msg">{msg ? msg : "Invalid User! Please contact administrator."}</span>
        </div>
    )
}

export const PageNotFound = (props) => {
    let flag = false;
    RouteData.forEach(data => {
        if (data.path === props.location.pathname)
            flag = true;
    })
    return (
        <div className="error-container">
            <div>
            <h1 className="error-code">{flag? "403":"404"}</h1>
            </div>
            <div>
            <div className="error-detail">{flag ? "UnAuthorized! Contact admin for access" : "Page Not Found"}</div>
            </div>
            <span className="home-link-btn home-link" onClick={()=>{props.history.push("/")}}>Return to HomePage</span>
        </div>
    )
}




export const RouteData = [
    {
        path: "/",
        component: Home,
        accessKey: "default_access",
        exact: true
    },
    {
        path: "/courses",
        component: Courses,
        accessKey: "cour_cert_train_view",
        exact: false
    },
    {
        path: "/trainings",
        component: Trainings,
        accessKey: "cour_cert_train_view",
        exact: true
    },
    {
        path: "/trainings/*",
        component: Trainings,
        accessKey: "cour_cert_train_view",
        exact: false
    },
    {
        path: "/certifications",
        component: Certifications,
        accessKey: "cour_cert_train_view",
        exact: false
    },
    {
        path: "/employees",
        component: EmployeeManagement,
        accessKey: ["search_employee", "crud_employee"],
        exact: false
    },
    {
        path: "/reports",
        component: Reports,
        accessKey: "training_admin",
        exact: false
    },
    {
        path: "/goals",
        component: Goals,
        accessKey: "default_access",
        exact: false
    },
    {
        path: "/configuration",
        component: Configuration,
        accessKey: ["system_configuration","training_admin"],
        exact: true
    },
    {
        path: "/brownbags/*",
        component: Brownbags,
        accessKey: "default_access",
        exact: false
    },
    {
        path: "/errorPage",
        component: ErrorPage,
        accessKey: "default_access",
        exact: true
    },
    {
        path: "/helpdoc",
        component: HelpDoc,
        accessKey: "default_access",
        exact: true
    },
    {
        path: "/mytrainings",
        component: MyTrainings,
        accessKey: "cour_cert_train_view",
        exact: false
    },
    {
        path: "*",
        component: PageNotFound,
        accessKey: "default_access"
    },
    
]

