import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { Loader } from "../components/customComponents";
import axios from "axios";
import _ from "lodash";
import { utilities } from "../components/utilities";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { useAppContext } from "../Office365/Office365Context";
import { getListFromOneDrive } from "../Office365/Office365Graph";
import { TrainingCertificate } from "./TrainingCertificate";
import NewChart from "./ProgressBar/NewChart";
import Signature from "../assets/signature.png";
import Signature1 from "../assets/signature-nk.png";
import TrainingWizard from "./TrainingWizard";
import "./style.css";

import {
  GET_MODULE_PROGRESS,
  MANDATORY_TRAINING,
  DEFAULTERS_LIST,
} from "../constants/urlConstants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import {
  prepareListUrl,
  TRAINING_PROJECT,
  TRAINING_PROJECT_ID,
} from "../constants/oneDriveConstants";

const TrainingHistory = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mandatory, setMandatory] = useState(null);
  const [trainingList, setTrainingList] = useState(null);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [trainingData, setTrainingData] = useState(null);
  const [trainingStatus, setTrainingStatus] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const searchRef = useRef(null);
  const [refresh, setRefresh] = useState(false);

  const user = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  let history = useHistory();
  const app = useAppContext();

  const getMandatoryTrainings = async () => {
    try {
      const response = await axios.get(MANDATORY_TRAINING);
      if (response.data.errorType === "Error") {
        console.error(response.data.errorMessage);
      } else {
        setMandatory(response.data);
      }
    } catch (error) {
      utilities.showToast(error);
      setMandatory([]);
    }
  };

  const getCompletionStatus = async () => {
    try {
      const dbQuery = `&employee_id=${user.employee_id}`;
      const response = await axios.get(
        `${DEFAULTERS_LIST}?pageSize=99&pageNumber=1${dbQuery}`
      );
      if (response.status === 200 && response?.data?.results) {
        const status = {};
        for (const item of response.data.results) {
          const { training_name, completion_date, expiry_date } = item;
          status[training_name] = {
            completion_date,
            expiry_date,
          };
        }
        setTrainingStatus(status);
      } else {
        setTrainingStatus({});
        console.error(`HTTP error! Status: ${response.data.error}`);
      }
    } catch (error) {
      console.error("err", error);
    } finally {
    }
  };

  const hasTrainingName = (itemName) => {
    return mandatory.some(
      (training) =>
        training.training_name === itemName &&
        training.competency.includes(user.competency)
    );
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await getMandatoryTrainings();
      await getCompletionStatus();
      setIsLoading(false);
    };
    loadData();
  }, [refresh]);

  useEffect(() => {
    const { path } = deriveParamsFromUrl();
    if (path) {
      openChildItem(path);
    } else {
      setTrainingData(null);
    }
  }, [props.match.params]);

  useEffect(() => {
    if (mandatory) parseOneDriveRootList();
  }, [mandatory]);

  const parseOneDriveRootList = async () => {
    let mandatoryList = [];
    let othersList = [];
    const { path } = deriveParamsFromUrl();
    for (const item of props.oneDriveData) {
      if (hasTrainingName(item.name)) {
        mandatoryList.push(item);
      } else {
        othersList.push(item);
      }
      if (path && path === item.name) {
        await openChildItem(item.name);
      }
    }
    setTrainingList({
      mandatory: [...mandatoryList],
      others: [...othersList],
    });
  };

  const checkFilter = (item) => {
    if (filterValue && filterValue.length >= 2) {
      if (item && item.name.toLowerCase().includes(filterValue.toLowerCase())) {
        return item;
      }
    } else {
      return item;
    }
  };

  const findInReadme = (training) => {
    const trainingInfo = props.readmeData.filter(
      (t) => t.training_name === training
    );
    return trainingInfo.length > 0
      ? trainingInfo[0]
      : { training_name: training };
  };

  const openChildItem = async (childItemName) => {
    setTrainingData(findInReadme(childItemName));
    let child_url = prepareListUrl(`${TRAINING_PROJECT}/${childItemName}`);
    const response = await getListFromOneDrive(app?.authProvider, child_url);
    setItemList(response.value);
  };

  const downloadFile = (child_url) => {
    if (child_url.startsWith("https")) {
      const link = document.createElement("a");
      link.href = child_url;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      console.log("invalid Link", child_url);
    }
  };

  const debounce_search = _.debounce(() => {
    setFilterValue(searchRef.current.value);
  }, 1000);

  const deriveParamsFromUrl = () => {
    const { match } = props;
    const params = match?.params[0];
    const projectId = params?.split("/")[1];
    const path = params?.split("/")[2];
    return { params, projectId, path };
  };

  if (isLoading) return <Loader />;

  return (
    <div className="container">
      {trainingData && (
        <>
          <div className="col-md-1">
            <div
              style={{ padding: "15% 0" }}
              onClick={() => {
                history.push("/trainings/completed/" + TRAINING_PROJECT_ID);
                setTrainingData(null);
                setFilterValue(null);
              }}
            >
              <i
                className="fa fa-arrow-circle-left fa-2x"
                style={{ color: "#5776E7", cursor: "pointer" }}
              />
            </div>
          </div>
          <TrainingContent
            trainingData={trainingData}
            trainingStatus={trainingStatus[trainingData.training_name]}
            fileLinks={itemList}
            downloadFile={downloadFile}
            user={user}
            setRefresh={setRefresh}
          />
        </>
      )}
      {!trainingData && (
        <>
          <div className="filterContainer">
            <div className="form-group has-search">
              <span className="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                id="header-search"
                className="form-control"
                placeholder="Search..."
                name="search"
                ref={searchRef}
                onChange={(e) => {
                  debounce_search();
                }}
              />
            </div>
          </div>
          <Accordion
            expanded={expanded1}
            onChange={() => setExpanded1(!expanded1)}
            style={{ boxShadow: "none", border: "none" }}
          >
            <AccordionSummary
              expandIcon={
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ borderBottom: "1px solid #ddd" }}
            >
              <div className="accordion-title-container">
                <Typography variant="h6">Mandatory</Typography>
                <div style={{ paddingLeft: ".5rem" }}> </div>
                <div className="circle-badge">
                  <span className="badge-content">
                    {trainingList?.mandatory.length}
                  </span>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row" style={{ width: "100%" }}>
                {trainingList?.mandatory.map(
                  (item, index) =>
                    checkFilter(item, index) && (
                      <div key={index} className={"col-md-3"}>
                        <Card
                          className="history-card"
                          onClick={async () => {
                            setIsLoading(true);
                            await openChildItem(item.name);
                            props.history.push(
                              props.match.url + "/" + item.name
                            );
                            setIsLoading(false);
                          }}
                        >
                          <span>
                            {item.name}
                            {"@microsoft.graph.downloadUrl" in item ? (
                              <span className="pull-right">&darr;</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Card>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            style={{ boxShadow: "none", border: "none" }}
          >
            <AccordionSummary
              expandIcon={
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ borderBottom: "1px solid #ddd" }}
            >
              <div className="accordion-title-container">
                <Typography variant="h6">Others</Typography>
                <div style={{ paddingLeft: ".5rem" }}> </div>
                <div className="circle-badge">
                  <span className="badge-content">
                    {trainingList?.others.length}
                  </span>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row" style={{ width: "100%" }}>
                {trainingList?.others.map(
                  (item, index) =>
                    checkFilter(item, index) && (
                      <div key={index} className={"col-md-3"}>
                        <Card
                          className="history-card"
                          onClick={async () => {
                            setIsLoading(true);
                            await openChildItem(item.name);
                            props.history.push(
                              props.match.url + "/" + item.name
                            );
                            setIsLoading(false);
                          }}
                        >
                          <span>
                            {item.name}
                            {"@microsoft.graph.downloadUrl" in item ? (
                              <span className="pull-right">&darr;</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Card>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};

const TrainingContent = ({
  trainingData,
  trainingStatus,
  fileLinks,
  downloadFile,
  user,
  setRefresh,
}) => {
  const [moduleProgress, setModuleProgress] = useState(null);
  const [moduleData, setModuleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  const skipInGlossaries = useRef([]);

  const getProgressData = async () => {
    if (trainingData) {
      let progressPostData = {
        employee_id: user.employee_id,
        training_id: trainingData?.training_id,
      };
      const res = await axios.post(GET_MODULE_PROGRESS, progressPostData);
      if (res && !res.data.errorMessage && res?.data) {
        if (res?.data?.length > 0) {
          setModuleData(res.data);
          setModuleProgress(Math.round(res.data[0].progress_percentage));
        } else {
          setModuleData([]);
          setModuleProgress(0);
        }
      }
    }
  };

  useEffect(() => {
    const getProgress = async () => {
      setIsLoading(true);
      await getProgressData();
      setIsLoading(false);
    };
    getProgress();
  }, []);

  const getPresentationLinks = (data) => {
    const getFileDownloadLink = (fileName) => {
      const link = fileLinks.filter((item) => item.name === fileName);
      return link[0]["@microsoft.graph.downloadUrl"];
    };
    let documentLinks = data.document_links.map((videoLink) => {
      videoLink &&
        !videoLink.startsWith("http") &&
        !skipInGlossaries.current.includes(videoLink) &&
        skipInGlossaries.current.push(videoLink);

      return (
        <>
          {videoLink && videoLink.startsWith("http") ? (
            <a href={videoLink} target="_blank" rel="noreferrer">
              {videoLink}
            </a>
          ) : (
            <>
              <span
                className="link-decor"
                onClick={() => {
                  downloadFile(getFileDownloadLink(videoLink));
                }}
              >
                {videoLink}
              </span>
              <br />
            </>
          )}
        </>
      );
    });
    return documentLinks;
  };

  const getModuleProgress = (data) => {
    let filterData = moduleData?.filter(
      (x) =>
        x.module_id === data.module_id &&
        x.is_pass &&
        trainingData.training_id === x.training_id
    );
    return filterData.length > 0;
  };

  const handleWizard = (module_name) => {
    setShowWizard(true);
    let module = trainingData.modules.filter(
      (item) => item.module_name === module_name
    );
    if (module.length > 0) {
      module[0]["training_id"] = trainingData.training_id;
      setSelectedModule(module[0]);
    } else setSelectedModule({});
  };

  if (isLoading) return <Loader />;

  const { training_name, trainingDescription, trainer_name, modules } =
    trainingData;
  return (
    <>
      <TrainingWizard
        show={showWizard}
        videoLink={selectedModule}
        selectedPath={training_name}
        setShowWizard={setShowWizard}
        videoLinksTableData={modules}
        mandatory={trainingStatus ? true : false}
        setRefresh={setRefresh}
      />
      <h4>{training_name}</h4>
      {trainingDescription && <span>{trainingDescription}</span>}
      {!modules && <Typography>No training information available.</Typography>}
      <div>
        {trainer_name && (
          <div style={{ paddingTop: "2%" }}>
            <h5>Presenter : {trainer_name}</h5>
          </div>
        )}
        {modules && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
              height: "100%",
            }}
          >
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Presentation</th>
                </tr>
              </thead>
              <tbody>
                {modules?.map((videoLink) => {
                  if (videoLink?.video_link)
                    return (
                      <tr key={videoLink.module_name}>
                        <td>
                          <span
                            className="link-decor"
                            onClick={() => {
                              handleWizard(videoLink.module_name);
                            }}
                          >
                            {videoLink.module_name}
                          </span>
                          &nbsp;
                          {getModuleProgress(videoLink) && (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: "green" }}
                            />
                          )}
                        </td>
                        <td>{getPresentationLinks(videoLink)}</td>
                      </tr>
                    );
                  else return <React.Fragment key={videoLink.module_name} />;
                })}
              </tbody>
            </table>

            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {moduleProgress === 100 && (
                  <>
                    <TrainingCertificate
                      name={user?.employee_name}
                      trainingName={training_name}
                      date={moduleData[0]?.completion_date}
                      directorName={
                        user?.geography === "India"
                          ? "Mathesh Jayabal"
                          : "Niyaz"
                      }
                      designation={
                        user?.geography === "India"
                          ? "Managing Director"
                          : "Managing Partner"
                      }
                      signature={
                        user?.geography === "India" ? Signature : Signature1
                      }
                    />
                  </>
                )}
              </div>

              <div id="chart" className="border border-0">
                <NewChart series={moduleProgress} />
              </div>
            </div>
          </div>
        )}
      </div>

      {trainingStatus && trainingStatus?.completion_date && (
        <span style={{ fontWeight: "600" }}>
          <i>Last Taken: {trainingStatus.completion_date}</i>
          <br />
          <i>Expiry Date: {trainingStatus.expiry_date}</i>
          <br />
          <br />
        </span>
      )}
      {fileLinks.length > 0 &&
        fileLinks.length > skipInGlossaries.current.length && (
          <h5>Glossaries : </h5>
        )}
      {fileLinks.map(
        (item, index) =>
          !skipInGlossaries.current.includes(item.name) &&
          "@microsoft.graph.downloadUrl" in item && (
            <span
              className="link-decor"
              onClick={() => downloadFile(item["@microsoft.graph.downloadUrl"])}
              key={item.name}
            >
              {item.name}
              {index !== fileLinks.length - 1 && ", "}
            </span>
          )
      )}
    </>
  );
};

export default withRouter(TrainingHistory);
