import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Axios from "axios";
import { GET_MY_TRAININGS } from "../constants/urlConstants";
import { withRouter } from "react-router-dom";
import { Loader } from "../components/customComponents";

const MyTrainings = (props) => {
  const [certificationList, setCertificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  const getTrainings = () => {
    let postData = {
      employee_id: user.employee_id
    }
    setIsLoading(true);
    Axios.post(GET_MY_TRAININGS, postData).then((res) => {
      if (res && !res.data.errorMessage && res?.data) {
        setCertificationList(res?.data)
      }
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      setIsLoading(false);
    });
  }
  useEffect(() => {
    getTrainings();
  }, [])
  return (
    <div className="page-wrap">
      {
        isLoading ? <Loader /> :
          <>
            <h4>My Trainings</h4>
            <table className="table table-striped table-bordered table-hover training-history-table ">
              <thead>
                <tr>
                  <th>Training Name</th>
                  <th>Progress</th>
                  {/* <th>Youtube</th> */}
                </tr>
              </thead>
              <tbody>
                {certificationList?.map((certifications) => (
                  <tr>
                    {/* <td> <a href={certifications.video_link} target="_blank">{certifications.session_name}</a></td> */}
                    <td>
                      <span
                        className="link-decor"
                        onClick={() => {
                          //   handleWizard(certifications);
                          props.history.push("/trainings/completed/32323986/" + certifications.training_name)
                        }}
                      >
                        {certifications.training_name}
                        {certifications.progress_percentage === 100 ? <i className="fa fa-check-circle" style={{ color: "green", marginLeft: "1%" }} /> : ""}
                      </span>
                    </td>
                    <td>
                      {
                        <span
                        //   onClick={() => downloadFile(certifications.ppt_name)}
                        >
                          {certifications.progress_percentage}%
                        </span>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
      }
    </div>
  )
}

export default withRouter(MyTrainings);