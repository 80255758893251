import _ from "lodash";
import React, {useRef} from "react";
import Select from "react-select";
import Pagination from '../components/pagination/Pagination';

export const CustomTable = (tableProps) => {
  const { columns, rows, highlightText } = tableProps;
  const {currentPage, totalRecords, pageSize, setCurrentPage, setPageSize} = tableProps;
  const recordOptions = useRef([{ label: "10 Records", value: 10}, { label: "25 Records", value: 25},
  { label: "50 Records", value: 50}]);
  
  return (
    <>
      {!_.isEmpty(rows) && (
        <table
          id={tableProps?.id}
          className={`table table-bordered table-striped ${tableProps.className}`}
        >
          <thead>
            <tr>
              {columns.map((col) => {
                return <th>{col?.title? col.title:""}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row) => {
              return (
                <tr>
                  {columns.map((col) => {
                    let content = row[col?.accessor];
                    content = !_.isString(content) ? content : content.split(',').map((item) => {
                      if(highlightText?.toLowerCase().split(',').includes(item.toLowerCase())) {
                        return `<span class="highlight">${item}</span>`;
                      } else {
                        return `<span>${item}</span>`;
                      }
                    }).toString()
                    return _.isString(content) ? (
                      <td {...row.props} dangerouslySetInnerHTML={{ __html: content }}/>
                    ) : (
                      <td {...row?.props}>{content}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {pageSize && <div className="pagination-bar">
            <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={pageSize}
            onPageChange={page => {setCurrentPage(page)}}
          />
      </div>}
      {/* <div>
            <Select className="width-medium" options={recordOptions.current}
              defaultValue={{ label: "10 Records", value: 10 }}
              onChange={(option) => setPageSize(option.value)}></Select>
      </div> */}
    </>
  );
};
