import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GaEvent } from "../App";
import { MicControl } from "../components/AskQues";
import CustomAccordion from "../components/CustomAccordion";
import { Loader } from "../components/customComponents";
import { CustomWizard } from "../components/CustomWizard";
import MediaPlayer from "../components/MediaPlayer";
import { utilities } from "../components/utilities";
import { gaCategory } from "../constants/gaConstants";
import {
  GET_QUESTIONNAIRES,
  VALIDATE_QUESTIONNAIRES,
} from "../constants/urlConstants";
import "./TrainingWizard.css";

const TrainingWizard = ({
  show,
  videoLink,
  selectedPath,
  setShowWizard,
  videoLinksTableData,
  mandatory,
  setRefresh,
}) => {
  const [assesmentDetails, setAssesmentDetails] = useState([]);
  const [answers, setAnswers] = useState(null);
  const [score, setScore] = useState({ loading: false });
  const [isLoading, setIsLoading] = useState(false);
  const [noAnswer, setNoAnswers] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const closeModal = () => {
    setScore({ loading: false });
    setAnswers(null);
    setAssesmentDetails([]);
    setShowWizard(false);
    setNoAnswers(false);
    if (score.hasOwnProperty("score")) setRefresh((pre) => !pre);
  };
  const getPostData = (data) => {
    setAnswers({ ...data });
  };
  const user = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  const onSubmit = () => {
    if (videoLink?.video_link || answers) {
      if (Object.keys(videoLink).length > 0) {
        var date = new Date();
        let last_updated = date.toISOString().slice(0, 19).replace("T", " ");
        let postData = {
          module_id: videoLink?.module_id,
          training_id: videoLink?.training_id,
          answers: answers,
          cut_off: videoLink.cut_off,
          employee_id: user.employee_id,
          isVideo:
            assesmentDetails[0]?.questionnaires.length === 0 ||
            assesmentDetails.length === 0,
          module_length: videoLinksTableData.length,
          training_name: selectedPath,
          last_updated: last_updated,
          mandatory: mandatory,
        };
        setScore({ loading: true });
        Axios.post(VALIDATE_QUESTIONNAIRES, postData).then((res) => {
          if (res && !res?.data?.errorMessage && res?.data) {
            setScore({ ...res.data, loading: false });
          } else {
            if (res?.data?.errorMessage) {
              utilities.showToast(res?.data?.errorMessage);
              setScore({
                loading: false,
                errorMessage: res?.data?.errorMessage,
              });
            }
          }
          setAnswers(null);
        });
        return parseInt(score?.scorePercentage) < parseInt(videoLink?.cut_off);
      }
    } else {
      setNoAnswers(true);
    }
  };

  const steps = [
    {
      name: "Training",
      content:
        videoLink?.video_link && videoLink.video_link !== "NA" ? (
          <>
            {/* <p className="media-notes-container">
          <i>
            Note: 
            <ul>If video is mentioned as private, please login to youtube with your wavicle credentials</ul>
            <ul>If facing issues with the login, contact ITHelpDesk ({`<itsupport.india@wavicledata.com>, <ithelpdesk.usa@wavicledata.com>`})</ul>
          </i>
        </p> */}
            <MediaPlayer
              videoLink={videoLink}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
            <br></br>
            <MicControl
              courseId={videoLink?.training_id}
              trainerEmail={videoLink.trainer_email}
              setIsPlaying={setIsPlaying}
              studentEmail={user.email_id}
            />
          </>
        ) : (
          <center>
            <h3>Video isn't applicable for this training.</h3>
          </center>
        ),
    },
    {
      name: "Assessment",
      content: (
        <WizardAssesment
          assesmentDetails={assesmentDetails}
          assesmentTitle={selectedPath + " - " + videoLink?.module_name}
          videoLink={videoLink}
          getPostData={getPostData}
          isLoading={isLoading}
        />
      ),
    },
    {
      name: "Results",
      content: (
        <>
          {score.loading || !videoLink ? (
            <Loader />
          ) : (
            <ScoreCard
              trainingTitle={selectedPath + " - " + videoLink?.module_name}
              score={score}
              isPass={
                parseInt(score?.scorePercentage) >= parseInt(videoLink?.cut_off)
              }
              noAnswer={noAnswer}
            />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (videoLink?.module_id && videoLink?.training_id && show) {
      setIsLoading(true);
      let postData = {
        module_id: videoLink?.module_id,
        training_id: videoLink?.training_id,
      };
      Axios.post(GET_QUESTIONNAIRES, postData)
        .then((res) => {
          if (res && !res.data.errorMessage && res?.data) {
            setAssesmentDetails(res.data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setAssesmentDetails([]);
          setIsLoading(false);

          console.log(e);
        });
    }
  }, [show]);

  return (
    <Modal
      show={show}
      shouldCloseOnOverlayClick={false}
      centered={true}
      size="xl"
      onHide={closeModal}
      className="training-wizard-modal p-3 mh-100 "
    >
      <Modal.Header closeButton>
        {selectedPath + " - " + videoLink?.module_name}
      </Modal.Header>
      <CustomWizard
        steps={steps}
        setShowWizard={setShowWizard}
        className="m-2 training-wizard "
        onSubmit={onSubmit}
        setRefresh={setRefresh}
      />
      &nbsp;&nbsp;
    </Modal>
  );
};
const ScoreCard = ({ trainingTitle, score, isPass, noAnswer }) => {
  useEffect(() => {
    GaEvent(gaCategory.TRAINING_WIZARD, "Assesment Completed", trainingTitle);
  });
  return (
    <div>
      {score?.errorMessage ? (
        <div className="score-card-text">
          <h4>Something went wrong ! Contact Admin</h4>
        </div>
      ) : (
        <>
          {score?.successMessage ? (
            <>
              <div className="score-card-text">
                <h4>
                  You've completed the current module for {trainingTitle}.
                </h4>
              </div>
            </>
          ) : noAnswer ? (
            <>
              <div className="score-card-text">
                <h4>No answers or videos to submit</h4>
              </div>
            </>
          ) : (
            <>
              {isPass ? (
                <div className="score-card-text">
                  <h4>
                    You have succesfully completed training on {trainingTitle}.
                  </h4>
                  <p> Your Score is {score?.scorePercentage} %</p>
                </div>
              ) : (
                <div className="score-card-text">
                  <h4>Score less than Cut Off, Please try again</h4>
                  <p> Your Score is {score?.scorePercentage} %</p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const getAnswers = (data) => {
  let answersSet = ["a", "b", "c", "d", "e"];
  let answers = answersSet.map((ansData) => data[ansData]);
  return answers;
};

const MCQ = (ans, type, handleChange, list) => {
  return (
    <div className="col-flex">
      {ans?.map((a, index) => {
        if (!a) return <></>;
        return (
          <>
            <span className="display-flex assessment-option">
              <Form.Check
                inline
                type={type}
                id={`inline-${type}-${index}`}
                name={list.qn}
                onChange={(e) => {
                  handleChange(e, list, a, index);
                }}
              />
              {a}
            </span>
          </>
        );
      })}
    </div>
  );
};

const WizardAssesment = ({
  assesmentDetails,
  assesmentTitle,
  videoLink,
  getPostData,
  isLoading,
}) => {
  const [answers, setAnswers] = useState({});
  const options = ["a", "b", "c", "d", "e"];
  useEffect(() => {
    GaEvent(
      gaCategory.TRAINING_WIZARD,
      "Assesment Taken",
      videoLink.module_name
    );
  }, []);
  const handleChange = (e, list, q, index) => {
    let obj = { ...answers };
    if (e.target.type === "checkbox") {
      obj[list.qn_no] = {
        ...obj[list.qn_no],
        [options[index]]: e.target.checked,
      };
    } else {
      if (e.target.type === "radio") {
        obj[list.qn_no] = {
          [options[index]]: e.target.checked,
        };
      }
    }
    parseAnswers({ ...obj });
    setAnswers({ ...obj });
  };
  const parseAnswers = (data) => {
    let testObj = {};
    Object.keys(data).forEach((answerList) => {
      const test = data[answerList];
      let optionList = [];
      options.forEach((option) => {
        if (test[option]) {
          optionList.push(option);
        }
      });
      testObj[answerList] = optionList;
    });

    getPostData({ ...testObj });
  };

  const content = assesmentDetails[0]?.questionnaires.map((ass, index) => {
    return {
      header: ass.qn,
      body: MCQ(
        getAnswers(ass),
        `${
          ass.isMultipleAnswer.toLowerCase() === "yes" ? "checkbox" : "radio"
        }`,
        handleChange,
        ass,
        index
      ),
    };
  });

  return (
    <div id="scrollContainer">
      <div id="assesment-wizard">
        {isLoading ? (
          <Spinner animation="grow" variant="primary" />
        ) : content ? (
          <CustomAccordion items={content} alwaysOpen={true} />
        ) : (
          <p className="t-center">
            <b>No Assessment found for this module</b>
          </p>
        )}
      </div>
    </div>
  );
};
export default TrainingWizard;
