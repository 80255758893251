import React, {
    createContext, useContext, useEffect, useState
} from 'react';

import { InteractionType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import Axios from "axios";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { utilities } from "../components/utilities";
import { FETCH_USER } from "../constants/urlConstants";
import {
    setCurrentUserDetails, setCurrentUserID,
    setCurrentUserName
} from "../NavBar/action";
import config from './Office365Config';
import { getUser } from './Office365Graph';

export const appContext = createContext({
    user: undefined,
    error: undefined,
    signIn: undefined,
    signOut: undefined,
    displayError: undefined,
    clearError: undefined,
    authProvider: undefined,
    isLoading: undefined
});


export function useAppContext() {
    return useContext(appContext);
}


export default function ProvideAppContext({ children }) {
    const msal = useMsal();
    // const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    //     msal.instance,
    //     {
    //         account: msal.instance?.getActiveAccount(),
    //         scopes: config.scopes,
    //         interactionType: InteractionType.Popup
    //     }
    // );
    const auth = useProvideAppContext();
    return (
        <appContext.Provider value={auth}>
            {children}
        </appContext.Provider>
    );
}
function useProvideAppContext() {
    const msal = useMsal();
    const dispatch = useDispatch();
    const [user, setUser] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const displayError = (message, debug) => {
        setError({ message, debug });
    }

    const clearError = () => {
        setError(undefined);
    }
    const isLoading = () => loading;


    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
            account: msal.instance?.getActiveAccount(),
            scopes: [
                'user.read',
                'mailboxsettings.read',
                'calendars.readwrite',
                'files.read.all'
            ],
            interactionType: InteractionType.Popup
        }
    );
    // const history = useHistory();

    useEffect(() => {

        const checkUser = async () => {
            if (!user) {
                try {
                    // Check if user is already signed in
                    const account = msal.instance.getActiveAccount();
                    if (account) {
                        // Get the user from Microsoft Graph
                        const user = await getUser(authProvider);
                        setUser({
                            displayName: user.displayName || '',
                            email: user.mail || user.userPrincipalName || '',
                            timeFormat: user.mailboxSettings?.timeFormat || 'h:mm a',
                            timeZone: user.mailboxSettings?.timeZone || 'UTC'
                        });

                    }
                } catch (err) {
                    displayError(err.message);
                }
            }
        };
        checkUser();
    }, []);

    const signIn = async () => {
        let loginRequest = {
            scopes: ["User.ReadWrite", "files.read.all"],
            prompt: "select_account"
        }
        await msal.instance.loginPopup(loginRequest)
            .catch((error) => console.log(error))
        // Get the user from Microsoft Graph
        const user = await getUser(authProvider);
        setUser({
            displayName: user.displayName || '',
            email: user.mail || user.userPrincipalName || '',
            timeFormat: user.mailboxSettings?.timeFormat || '',
            timeZone: user.mailboxSettings?.timeZone || 'UTC'
        });
        dispatch(setCurrentUserID(user.mail || user.userPrincipalName || '',));
        dispatch(setCurrentUserName(user.displayName || '',));
        localStorage.setItem("email_id", user.mail || user.userPrincipalName || '');
        localStorage.setItem("emp_name", user.displayName || '');
        setLoading(true);
        Axios.post(
            FETCH_USER,
            {
                emailId: user.mail || user.userPrincipalName,
            }
        ).then((res) => {
            setLoading(false);
            if (!res?.data || _.isEmpty(res?.data)) {
                // history.push({ pathname: '/#/errorPage' })
            }
            dispatch(setCurrentUserDetails({
                ...res.data,
                isAdmin: !utilities.isEmptyOrNull(res.data.admin)
            }));
            // window.location.reload();
        }).catch(error => {
            setLoading(false);
            displayError(error);
        });

    };

    const signOut = async () => {
        // await msal.instance.logoutPopup();
        localStorage.clear();
        setTimeout(function () {
            setUser(undefined);
            window.location.reload();
            this.props?.setLogOut();
        }, 1);

    };

    return {
        user,
        error,
        signIn,
        signOut,
        displayError,
        isLoading,
        clearError,
        authProvider
    };
}