import React from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

const status = {
  "Pending": 1,
  "In Progress":1,
  "Is In Progress":1,
  "Approved": 2,
  "Rejected": 2,
  "Completed": 3,
};
const STATUS_TYPE_PENDING = "light";
const STATUS_TYPE_APPROVED = "info";
const STATUS_TYPE_REJECTED = "error";
const STATUS_TYPE_COMPLETED = "success";

export const WorkFlow = ({ currentStatus }) => {
  const statusIndex = status[currentStatus];
  return (
    <div>
      {statusIndex === 1 ? (
        <LevelBadge statusType={STATUS_TYPE_PENDING} tooltip={currentStatus} />
      ) : statusIndex === 2 ? (
        <>
          <LevelBadge statusType={STATUS_TYPE_PENDING} tooltip={"Pending"} />
          <span className="join-line">-</span>
          <LevelBadge
            statusType={
              currentStatus === "Approved"
                ? STATUS_TYPE_APPROVED
                : STATUS_TYPE_REJECTED
            }
            tooltip={currentStatus}
          />
        </>
      ) : statusIndex === 3 ? (
        <>
          <LevelBadge statusType={STATUS_TYPE_PENDING} tooltip={"Pending"} />
          <span className="join-line">-</span>
          <LevelBadge statusType={STATUS_TYPE_APPROVED} tooltip={"Approved"} />
          <span className="join-line">-</span>
          <LevelBadge
            statusType={STATUS_TYPE_COMPLETED}
            tooltip={currentStatus}
          />
        </>
      ) : (
        { currentStatus }
      )}
    </div>
  );
};

const LevelBadge = ({ statusType, tooltip }) => {
  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={<Tooltip className="custom-tooltip">{`${tooltip}`}</Tooltip>}
    >
      <span className='wf-badge'>
      <i className={`fa fa-circle fa-2x ${statusType}`} />
      {(statusType===STATUS_TYPE_COMPLETED) &&
        <i className = {"fa fa-check badge-logo"}/>
      }
      {statusType===STATUS_TYPE_REJECTED &&
        <i className = {"fa fa-times badge-logo"}/>
      }
      </span>
    </OverlayTrigger>
  );
};
