import axios from "axios";
import React, { Component } from "react";
import { Button, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { exportToExcel } from "../common/utilities";
import { utilities } from "../components/utilities";
import {
  FROM_EMAIL,
  SEND_MAIL_NOTIFICATION,
  TO_EMAIL,
  UPDATE_ENROLLMENR_STATUS,
} from "../constants/urlConstants";
import _ from 'lodash';

class ViewEnrollments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: "",
      display: "",
      enrollments: props.enrollments,
      app_rej: [],
      tot: [],
      enrolledDetail: [],
    };
  }
  
  Onapprove = (trainee_id, trainee_email, training_title) => {
    const payload = {
      trainingId: this.props.training_id,
      traineeId: trainee_id,
      status: "Approved",
    };
    axios.post(UPDATE_ENROLLMENR_STATUS, payload).then((res) => {
      const mailText = `Your enrollment for ${training_title} training has been Approved`;
      const subject = "Regarding: Apply Training";
      const emailPayload = {
        from: FROM_EMAIL,
        to: trainee_email,
        cc: TO_EMAIL,
        subject,
        text: mailText,
      };
      axios.post(SEND_MAIL_NOTIFICATION, emailPayload).then((response) => {
        utilities.showToast("Approved!!");
        setTimeout(function () {
          window.location.reload();
        }, 3);
      });
      // this.props.onHide()
      this.props.fetchEnrollments();
    });
  };
  Onreject = (trainee_id,trainee_email, training_title) => {
    const payload = {
      trainingId: this.props.training_id,
      traineeId: trainee_id,
      status: "Rejected",
    };
    axios.post(UPDATE_ENROLLMENR_STATUS, payload).then((res) => {
      const mailText = `Your enrollment for ${training_title} training has been Rejected`;
      const subject = "Regarding: Apply Training";
      const emailPayload = {
        from: FROM_EMAIL,
        to: trainee_email,
        cc: TO_EMAIL,
        subject,
        text: mailText,
      };
      axios.post(SEND_MAIL_NOTIFICATION, emailPayload).then((response) => {
        utilities.showToast("Rejected!!");
      });
      this.props.fetchEnrollments();
    });
  };
  handleExcel = () => {
    const { enrollments } = this.props;
    if (!enrollments || _.isEmpty(enrollments)) {
      utilities.showToast("No Enrollments yet!");
      return;
    }
    const dataList = enrollments.map((enrollment) => {
      return {
        "Employee Id": enrollment.employee_id,
        "Employee Name": enrollment.employee_name,
        "Status": enrollment.status,
      };
    });
    exportToExcel(
      dataList,
      `Enrollments_${enrollments[0].training_title}.xlsx`
    );
  };
  render() {
    const { display } = this.state;
    const { enrollments, isLoading } = this.props;

    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              View Enrollments
              {!isLoading && (
                <span className="float-right">
                  <i
                    className="fa fa-file-excel-o fa-2x top-icons"
                    onClick={this.handleExcel}
                  />
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <center>
                <Spinner animation="grow" variant="primary" />
              </center>
            ) : (
              <>
                {enrollments && enrollments.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Employee Id</th>
                        <th>Employee Name</th>
                        <th>Review</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enrollments.map((enrollment) => {
                        return (
                          <>
                            {enrollment ? (
                              <tr>
                                <td>{enrollment.employee_id}</td>
                                <td>{enrollment.employee_name}</td>
                                <td>
                                  <div className="wrappingUp">
                                    {enrollment.status !== "Pending" ? (
                                      <>
                                        <div>
                                          {enrollment.status === "Approved" ? (
                                            <p className="colorG">
                                              <span>
                                                Approved
                                              </span>
                                              <span>
                                                <i
                                                  class="fa fa-check"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>{" "}
                                            </p>
                                          ) : (
                                            <p className="colorR">
                                              <span>
                                                Rejected
                                              </span>
                                              <span>
                                                <i
                                                  class="fa fa-times"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>{" "}
                                            </p>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <Row>
                                        <Col>
                                          <Button
                                            className={`custom-green-btn ${
                                              display ? "d-none" : "d-block"
                                            }`}
                                            onClick={() =>
                                              this.Onapprove(
                                                enrollment.employee_id,
                                                enrollment?.email_id,
                                                enrollment.training_title
                                              )
                                            }
                                          >
                                            Approve
                                          </Button>
                                        </Col>
                                        <Col>-or-</Col>
                                        <Col>
                                          <Button
                                            variant="danger"
                                            className={`custom-red-btn ${
                                              display ? "d-none" : "d-block"
                                            }`}
                                            onClick={() =>
                                              this.Onreject(
                                                enrollment.employee_id,
                                                enrollment?.email_id,
                                                enrollment.training_title
                                              )
                                            }
                                          >
                                            Reject
                                          </Button>
                                        </Col>
                                      </Row>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <div>No enrollments yet!</div>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <p className="bold text-center">No enrollments yet!</p>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
          <val />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
  };
};
export default connect(mapStateToProps)(ViewEnrollments);
