import { CURRENT_ENVIRONMENT } from "./envConstants";
export const FETCH_USER = `https://rvgqa9h3eh.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const EMPLOYEES_API = `https://p3t9rpu17b.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const COURSE_LISTING = `https://kvbqgxq0s0.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const COURSE_MASTER_LIST = `https://22aohar5t2.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const ADD_COURSE = `https://yzqxxqfq9e.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const GET_COURSE_STATIC_DATA= `https://goj0md35x0.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;

export const FETCH_ADMIN = `https://o4yhg91un1.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const CERTIFICATIONS_LIST = `https://scyvgj7zi0.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const CERT_MASTER_LIST = `https://936yhro080.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const UPCOMING_TRAININGS = `https://ql5608jfa0.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const ADD_TRAINING = `https://sq51725ro8.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const ENROLL = `https://fkju1y8ic8.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const VIEW_ENROLLMENTS = `https://jsh43j2udc.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const UPDATE_ENROLLMENR_STATUS = `https://zehkt1haz4.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const UPDATE_ENROLLMENT_STATUS =`https://obq1s2tw2g.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const APPLY_CERTIFICATION = `https://jdhv8vxasg.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const UPDATE_CERTIFICATE_STATUS = `https://4v6wzjieh5.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const UPLOAD_CERTIFICATIONS = `https://cl9txe4knf.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const ASSIGN_COURSE = ` https://rv8lzyzy1b.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const LIST_MY_COURSE = `https://yjr2ped4w9.execute-api.us-east-1.amazonaws.com/myLearnings`;
export const UPDATE_MY_COURSE = `https://qcb9fi2h90.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const EDIT_TRAININGS = `https://acq4uir1cg.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const DELETE_TRAINING = `https://ne4kkp9m9b.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const DELETE_COURSE = `https://zt3mx6322h.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const BANNERS_OBJ_URL = `${CURRENT_ENVIRONMENT==='dev'?'https://wavicle-academy-home.s3.us-east-2.amazonaws.com/banners_dev.json':'https://wavicle-academy-home.s3.us-east-2.amazonaws.com/banners.json'}`;
export const HOME_CAROUSEL_OBJ_URL = `${CURRENT_ENVIRONMENT==='dev'?'https://wavicle-academy-home.s3.us-east-2.amazonaws.com/home_carouse_dev.json':'https://wavicle-academy-home.s3.us-east-2.amazonaws.com/home_carousel.json'}`;
export const HOME_CAROUSEL_OBJ_PREFIX = `https://wavicle-academy-home.s3.us-east-2.amazonaws.com/home_carousel_images/`;
export const BANNERS_OBJ_PREFIX = `https://wavicle-academy-home.s3.us-east-2.amazonaws.com/banner_images/`;
export const EMPLOYEE_BY_COMPETENCY = `https://4dna3txmq2.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const FROM_EMAIL = `academy@wavicledata.com`;
export const GET_GOAL_STATIC_DATA =`https://g3nn52ryle.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const SAVE_NEW_GOALS = `https://4fpmumxdbd.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const SAVED_GOAL_LIST = ` https://kss7tal2yj.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const GOAL_LIST= `https://df3f8l5lz2.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const SAVE_INDIVIDUAL_GOALS=`https://cek6ppy4ai.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const COURSE_AND_CERTIFICATIONS_LIST = `https://va36ehna81.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const ADD_COMPETENCY = `https://klng6pzgq2.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const ADD_CATEGORY = `https://ofdwf3i30b.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const ADD_SKILLTYPE = `https://mxdrgh0coi.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const COURSE_TYPE_DATA=`https://hvv16m4lck.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const TO_EMAIL = `academy@wavicledata.com`;
export const CC_MAIL = `padmanaban.jagan@wavicledata.com`;
export const UPLOAD_QUESTIONNAIRES = `https://63en8qyeh7.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const GET_QUESTIONNAIRES = `https://nfo0pndswl.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const VALIDATE_QUESTIONNAIRES = ` https://9uf85e8k1f.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}/validatequestionnaires`;
export const GET_MODULE_PROGRESS = `https://mgf69d5dm0.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const GET_MY_TRAININGS = `https://b772036ij3.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const SUBMIT_TRAININGS = ` https://ihf06nwts1.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}/submittrainingprogress`;
export const UPLOAD_FAQ= `https://5urcf7qwj8.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const DEFAULTERS_LIST= `https://wx72o8vgrd.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const MANDATORY_TRAINING =`https://fq326l8dk8.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const NOTIFICATION_CONFIG =`https://saknwftht2.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}`;
export const SEND_MAIL_NOTIFICATION = `https://3zjtlj6rkk.execute-api.us-east-1.amazonaws.com/${CURRENT_ENVIRONMENT}/sendemail`;