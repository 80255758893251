import React, { Component } from "react";
import axios from "axios";
import UpcomingCourses from "./UpcomingCourses";
import "./styles.css";
import ImageCarousel from "../components/ImageCarousel";
import { imageConstants } from "./imageData";
import { Col, Container, Row } from "react-bootstrap";
import { BANNERS_OBJ_URL, BANNERS_OBJ_PREFIX, UPCOMING_TRAININGS, HOME_CAROUSEL_OBJ_PREFIX, HOME_CAROUSEL_OBJ_URL } from "../constants/urlConstants";
import { openInNewTab } from "../common/utilities";

class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      courses: [],
    };
    this.banners = [];
    this.homeCarousel = [];
  }

  componentDidMount() {
    axios.get(UPCOMING_TRAININGS).then((res) => {
      if (!res.data.errorMessage)
        this.setState({
          courses: res.data,
        });
    }).catch((e)=>{
      console.log(e);
    });
    axios.get(BANNERS_OBJ_URL,{headers:{"Accept":"*/*"}}).then(res=>{
      this.banners = res?.data;
    }).catch(()=>{
      this.banners = [];
    })
    axios.get(HOME_CAROUSEL_OBJ_URL,{headers:{"Accept":"*/*"}}).then(res=>{
      this.homeCarousel = res?.data;
    }).catch(()=>{
      this.homeCarousel = [];
    })
  }

  render() {
    let { courses } = this.state;
    return (
      <div>
        <div className="page-wrap banner-wrap">
          <ImageCarousel>{imageConstants}</ImageCarousel>
        </div>
        <Container className="announcements-div">
          <Row>
            <Col
              sm={4}
              className={
                courses || courses.length !== 0
                  ? "courses-scroll-wrap"
                  : "courses-scroll-wrap-notrain"
              }
            >
              <h4 className="heading-title">
                UPCOMING TRAININGS
                <span>
                  &nbsp;
                  <i color="#fff" className="fa fa-graduation-cap" />
                </span>
              </h4>
              <div
                className={
                  courses && courses.length !== 0
                    ? "scroll-body"
                    : "scroll-body-notrain"
                }
              >
                {courses && courses.length !== 0 ? (
                  courses &&
                  courses.map((course) => {
                    return <UpcomingCourses course={course} key={course.id} />;
                  })
                ) : (
                  <p>No Upcoming trainings</p>
                )}
              </div>
            </Col>
            <Col>
              <Container className="announcements_bar">
                {/* Dynamic announcements can be added */}
                {this.banners.map((banner) => {
                  return (
                      <img
                        className="cursor-pointer"
                        alt=""
                        src={`${BANNERS_OBJ_PREFIX}${banner.imageName}`}
                        onClick={()=>openInNewTab(banner.url)}
                      />
                  );
                })}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;
