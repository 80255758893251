import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from "redux-thunk";
import App from './App';
import { EmployeeListReducer } from './Auth/reducer';
import './index.css';
import { CurrentUserReducer } from './NavBar/reducer';
import * as serviceWorker from './serviceWorker';

import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import {
  EventType, PublicClientApplication
} from '@azure/msal-browser';

import { MsalProvider } from '@azure/msal-react';
import config from './Office365/Office365Config';
import ProvideAppContext from './Office365/Office365Context';

export const msalInstance = new PublicClientApplication(config);

// Check if there are already accounts in the browser session
// If so, set the first account as the active account
const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    // Set the active account - this simplifies token acquisition
    const authResult = event.payload;
    msalInstance.setActiveAccount(authResult.account);
  }
});


const persistConfig = {
  key: 'root',
  storage,
}


const appReducer = combineReducers({
  CurrentUserReducer,
  EmployeeListReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}


const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, undefined, applyMiddleware(thunk));
const persistor = persistStore(store)

// const store = createStore( rootReducer);

ReactDOM.render(
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <MsalProvider instance={msalInstance}>
      <ProvideAppContext>
        <App />
      </ProvideAppContext>
    </MsalProvider>
  </PersistGate>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
