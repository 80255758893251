import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { GaEvent } from "../App";
import addLogo from "../assets/plus.png";
import { utilities } from "../components/utilities";
import {
  ADD_SKILLTYPE,
  GET_COURSE_STATIC_DATA
} from "../constants/urlConstants";

const SkillType = () => {
  return (
    <Container className="main-container">
      <Row>
        <Col>
          <SkillTypePanel />
        </Col>
      </Row>
    </Container>
  );
};

const SkillTypePanel = () => {
  const [skillType, setSkillType] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [SkillName, setSkillName] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isRedundant, setRedundantCourse] = useState(false);

  const reset = () => {
    setValidated(false);
    setSkillName("");
  };

  useEffect(() => {
    loadStaticData();
  }, [refreshKey]);

  const loadStaticData = async () => {
    try {
      setLoading(true);
      let result = await axios.get(GET_COURSE_STATIC_DATA);
      if (result) {
        if (result.data.errorType === "Error") {
          utilities.showToast(result.data.errorMessage);
        } else {
          setSkillType(result.data.filter((x) => x.type === "SkillLevel"));
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const form = e.currentTarget;
    let dataToCheck = skillType?.filter(courseData => courseData.description.toLowerCase() === SkillName.toLowerCase());
    if (dataToCheck.length > 0) {
      setRedundantCourse(true)
      e.preventDefault();
      e.stopPropagation();
      setLoading(false);
    } else {
      setRedundantCourse(false)
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setLoading(false);
      } else {
        e.preventDefault();
        const payload = { skillName: SkillName };
        axios.post(ADD_SKILLTYPE, payload).then((res) => {
          if (res.data.errorType === "Error") {
            utilities.showToast(res.data.errorMessage);
          } else if (res.data.affectedRows > 0) {
            GaEvent("Skill", "New Skill Type Added", SkillName);
            utilities.showToast(
              "Added " + res.data.affectedRows + " New Skill Type!!"
            );
            reset();
          } else if (res.data.errorMessage) {
            utilities.showToast(res.data.errorMessage);
          }
          setRefreshKey((oldKey) => oldKey + 1);
          setLoading(false);
        }).catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
        setShow(false);
      }
      setValidated(true);
    }
  };

  return (
    <>
      {isLoading && isLoading === true && <>
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '30rem' }}>
              <Spinner animation="grow" variant="primary" />
            </div>
          </Col>
        </Row>
      </>}
      {!isLoading && isLoading === false && (
        <Container>
          <div>
            <i
              className="fa fa-plus-circle fa-2x add-logo"
              onClick={handleShow}
              src={addLogo}
            />
            <Modal show={show} onHide={handleClose}>
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Modal.Header closeButton>
                  <Modal.Title>Manage SKill Level</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="formBasicSkillName">
                    <Form.Label>Skill Level</Form.Label>
                    <Form.Control
                      type="name"
                      maxLength={100}
                      value={SkillName}
                      onChange={(e) => {
                        setSkillName(e.target.value);
                        setRedundantCourse(false)
                      }}
                      autoComplete="off"
                      required
                    />
                    {isRedundant && (
                      <Form.Text type="invalid">
                        <div class="text-danger">The Skill level already Exists!</div>
                      </Form.Text>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Skill Level is mandatory
                    </Form.Control.Feedback>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>

          <p className="mt-5 mb-4 mx-0">Manage Skill Level</p>
          <table
            id="my-courses-list"
            className="table table-bordered table-striped"
          >
            <thead className="thead-light">
              <tr>
                <th>Skill Type</th>
              </tr>
            </thead>
            <tbody>
              {skillType && skillType.length > 0 ? (
                skillType.map((item) => {
                  return (
                    <tr>
                      <td>
                        <span className="vertical-sub">{item.description}</span>
                        <span className="float-right">
                          <EditAction item={item} onSave={() => setRefreshKey((oldKey) => oldKey + 1)} />
                          <DeleteAction item={item} onDelete={() => setRefreshKey((oldKey) => oldKey + 1)} />
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <h2>No Skill Level added.</h2>
              )}
            </tbody>
          </table>
        </Container>
      )}
    </>

  );
};

const EditAction = ({ item, onSave }) => {
  const [name, setName] = useState(item.description);
  const [show, setShow] = useState(false);
  const [btnText, setBtnText] = useState('Save');
  const [isSaving, setSaving] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    disableSave(true);
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      disableSave(false);
    } else {
      const payload = { skillId: item.id, skillName: name };
      axios.put(ADD_SKILLTYPE, payload).then((res) => {
        disableSave(false);
        handleClose();
        onSave();
        if (res.data.errorType === 'Error' || res.data.errorMessage) {
          utilities.showToast(res.data.errorMessage);
        } if (res.data.affectedRows > 0) {
          GaEvent("Skill Level", "Saved Skill Level", name);
          utilities.showToast("Skill Level Saved Successfully!");
        }
      }).catch((err) => {
        disableSave(false);
        console.log("err", err);
      });
    }
    setValidated(true);
  };


  const disableSave = (state) => {
    setBtnText(state ? 'Saving' : 'Save');
    setSaving(state);
  }

  return (
    <>
      <i className="fa fa-edit fa-lg cursor-pointer mx-2" onClick={handleShow} />
      <Modal show={show} onHide={handleClose} contentClassName="w-100">
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Skill Level</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicCompetencyName">
              <Form.Label>Skill Level</Form.Label>
              <Form.Control type="name"
                maxLength={100}
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                required />
              <Form.Control.Feedback type="invalid">
                Skill Level is mandatory
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="success" type="submit" disabled={isSaving}>{btnText}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

const DeleteAction = ({ item, onDelete }) => {
  const [show, setShow] = useState(false);
  const [btnText, setBtnText] = useState('Delete');
  const [isSaving, setSaving] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = (e) => {
    disableSave(true);
    if (item && item.id && item.description) {
      const payload = { skillId: item.id };
      axios.delete(ADD_SKILLTYPE , {data: payload}).then((res) => {
        disableSave(false);
        handleClose();
        onDelete();
        if (res.data.errorType === 'Error' || res.data.errorMessage) {
          utilities.showToast(res.data.errorMessage);
        } else if (res.data.affectedRows === 0) { 
          utilities.showToast(`Skill level ${item.description} cannot be deleted.`);
        } else if (res.data.affectedRows > 0) {
          GaEvent("Skill Level", "Removed Skill Level", item.description);
          utilities.showToast(`${item.description} skill level removed successfully.`);
        }
      }).catch((err) => {
        disableSave(false);
        console.log("err", err);
      });
    } else {
      disableSave(false);
      handleClose();
      utilities.showToast("Skill level details not found.");
    }
  };

  const disableSave = (state) => {
    setBtnText(state ? 'Deleting' : 'Delete');
    setSaving(state);
  }

  return (
    <>
      <i className="fa fa-trash fa-lg cursor-pointer" onClick={handleShow} />
      <Modal show={show} onHide={handleClose} contentClassName="w-100">
        <Modal.Header closeButton>
          <Modal.Title>Delete Skill Level</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to delete the <b>{item.description}</b> skill level?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="danger" disabled={isSaving} onClick={handleDelete}>{btnText}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SkillType
