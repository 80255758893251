import { DateRangePicker } from "react-date-range";
import { getUTCTimeStamp } from "../common/utilities";
import { useState } from "react";
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

const RangeDatePicker = ({title ,setStartDate, setEndDate, startDate, endDate}) => {

  const currentDate = new Date();
  const maxDate = title === "Expiry Date"? new Date(currentDate.getFullYear() + 4, currentDate.getMonth(), currentDate.getDate()) : new Date();
  const [showPicker, setShowPicker] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [],
  });
  const selectedDateRange  = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const onClickClear = () => {
    setStartDate(null);
    setEndDate(null);
    setShowPicker(false);
      
};
  const handleDateRangeChange = (ranges) => {
    setStartDate(getUTCTimeStamp(ranges.selection.startDate));
    setEndDate(getUTCTimeStamp(ranges.selection.endDate));
  };
  const handleShowPicker = (e) => {
    setShowPicker((prevValue) => !prevValue);
  };
return(
  <>
  <span>
    <div className="font-bold">{title }:</div>
    <span onClick={handleShowPicker} className="cursor-pointer link-decor" ref={setReferenceElement}>
      {startDate ?`${startDate.toDateString()} - ${endDate.toDateString()}` : 'All Dates'}
      &nbsp;
      <i className={`fa fa-angle-${showPicker ? "down" : "right"}`} />
    </span>
   {startDate ? <span
      className="text-danger cursor-pointer mx-1"
      style={{fontSize: "15px"}}
      onClick={onClickClear}>
        clear
  </span> : ""}
  </span>
  {showPicker && createPortal(<div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
    <DateRangePicker
      ranges={[selectedDateRange]}
      onChange={handleDateRangeChange}
      maxDate={maxDate}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
    />
  </div>, document.body)}
  </>
)
}

export default RangeDatePicker;