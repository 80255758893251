import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { utilities } from "../components/utilities";
import {
  ENROLL, FROM_EMAIL,
  SEND_MAIL_NOTIFICATION, TO_EMAIL
} from "../constants/urlConstants";

const EnrollmentForm = (props) => {
  
  const employeeList = useSelector(
    (state) => state.EmployeeListReducer.employeeList
  );
  const currentUserDetail = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  const [Trainee_ID, setTrainee_ID] = useState("");
  const [Status, setStatus] = useState("");
  const [Comments, setComments] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    const trainer_name = props.trainer_name;
    const training_id = props.training_id;
    const training_title = props.training_title;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const subject = "Apply for Training";
      const payload = {
        training_id,
        traineeid: currentUserDetail.employee_id,
        comments: Comments,
      };
      axios
        .post(ENROLL, payload)
        .then((res) => {
          if (res.data.errorType == "Error") {
            utilities.showToast(res.data.errorMessage);
            Trainee_ID = "";
            Status = "";
            Comments = "";
          } else if (res.data.affectedRows > 0) {
                props.fetchEnrollments();
                const trainer = employeeList.find((trainer) => {
                  return trainer.employee_name === trainer_name;
                });
                const AdminText = `This is to notify that ${currentUserDetail.employee_name} has requested to enroll for 
                ${training_title} training.
                Comments: ${Comments}`;

                const emailPayload = {
                  from: FROM_EMAIL,
                  to: TO_EMAIL,
                  cc: currentUserDetail.email_id,
                  subject,
                  text: AdminText,
                };
                axios
                  .post(SEND_MAIL_NOTIFICATION, emailPayload)
                  .then((response) => {
                    utilities.showToast("Request Mail has been sent!");
                  });
              // });
          } else if (res.data.errorMessage) {
            utilities.showToast("You have already requested Enrollment");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      props.onHide();
    }
    setValidated(true);
  };
  return (
    <Modal {...props}>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Modal.Header closeButton>
          <Modal.Title>Enroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicComment">
            <Form.Label>Comments:</Form.Label>
            <Form.Control
              type="id"
              value={Comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
              autocomplete="off"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default EnrollmentForm;