import React from "react";
import { Modal } from "react-bootstrap";
import MediaPlayer from "../components/MediaPlayer";
const BrownbagsWizard = ({ show, videoLink, setShowWizard }) => {
  const closeModal = () => {
    setShowWizard(false);
  };
  return (
    <Modal
      show={show}
      shouldCloseOnOverlayClick={false}
      size="lg"
      centered={true}
      onHide={closeModal}
      className="training-wizard-modal p-3 mh-100 "
    >
      <Modal.Header closeButton>
        {videoLink?.module_name}
      </Modal.Header>
      <div className="browbag-wizard-container">
        <MediaPlayer videoLink={videoLink} />
      </div>
    </Modal>
  );
};

export default BrownbagsWizard;
