import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Loader } from "../components/customComponents";
import Charts from "./Chart";
import { GoalProgress } from "./GoalProgress";


export const SavedGoalPanel = ({ userDetails, values, Loading, onChange }) => {
    const [isLoading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [percentComplete, setPercentComplete] = useState(0);
    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
        initValues();
    }, []);

    useEffect(() => {
        setLoading(Loading);
    }, [Loading]);

    const initValues = () => {
        if (values) {
            getGoalDates(values);
            getPercentComplete(values);
            setCourseList(groupCourses(values, "Technical", "Training"))   
        }
    }

    const getGoalDates = (items) => {
        if (items) {
            for (const [key, value] of Object.entries(items)) {
                for(var record of value) {
                    if(record && record.from_date && record.to_date) {
                        let startDate = record?.from_date ? new Date(record?.from_date) : null;
                        let endDate = record?.to_date ? new Date(record?.to_date) : null;
                        if(startDate) { setFromDate(startDate.toLocaleDateString("en-IN")); }
                        if(endDate) { setToDate(endDate.toLocaleDateString("en-IN")); }
                        return;
                    }
                }
            }
        }
    }

    const getWeightAgeValue = (items) => {
        let count = Object.keys(items).length;
        let totalWeight = 0;
        if (count > 0) {
            if (count === 1) {
                totalWeight = parseFloat(items[0].weightage);
            } else {
                items.forEach(item => {
                    totalWeight += item ? parseFloat(item.weightage) : 0;
                });
            }
        }
        return totalWeight;
    }

    const getPercentComplete = (items) => {
        let percentage = 0;
        if (items) {
            for (const [key, value] of Object.entries(items)) {
                for(var record of value) {
                    let startDate = record?.from_date ? new Date(record?.from_date) : null;
                    let endDate = record?.to_date ? new Date(record?.to_date) : null;
                    if(startDate) { setFromDate(startDate.toLocaleDateString("en-IN")); }
                    if(endDate) { setToDate(endDate.toLocaleDateString("en-IN")); }
                }
                switch (key) {
                    case "Technical":
                        percentage += calcPercentage(value, "course_status", "Approved");
                        break;
                    case "Non Technical":
                        percentage += calcPercentage(value, "course_status", "Approved");
                        break;
                    case "Certifications":
                        percentage += calcPercentage(value, "certfication_status", "Completed");
                        break;
                    case "Training":
                        percentage += calcPercentage(value, "is_complete", 1);
                        break;
                    default:
                        percentage += calcPercentage(value, "additional_course_status", "Completed");
                        break;
                }
            }
        }
        setPercentComplete(percentage.toFixed(0));
    }

    const calcPercentage = (items, key, value) => {
        let percent = 0;
        if (items && items?.length > 0) {
            items?.forEach(item => {
                if (item[key] === value) {
                    percent += item ? parseFloat(item.weightage) : 0;
                }
            });
        }
        return percent;
    }

    const groupCourses = (original, source, target) => {
        if (original) {
            if (original[source] && original[target]) {
                original[source] = [...original[source], ...original[target]];
                delete original[target];
            }
        }
        return original;
    }

    return (
        <Container className="main-container container tab-container">
            {isLoading && <>
                <Loader />
            </>}

            {!isLoading && <>
                <Row className="mx-3 mb-3">
                    <Col lg={10} className="competency-row px-0" >
                        <p className="font-semi-bold" style={{ fontSize: '18px' }}>My Progress</p>
                    </Col>
                    <Col lg={2} className="competency-row px-0" >
                        {fromDate && toDate && <>
                            <p className="font-semi-bold" style={{ fontSize: '18px', textAlign: 'right' }}>{fromDate} - {toDate}</p>
                        </>}
                    </Col>
                </Row>
                <Row className="mx-2">
                    <Col xs lg={8}>
                        {courseList && Object.keys(courseList).map((key, index) => (
                            <Row className="mb-3">
                                <Col>
                                    <GoalProgress label={key} lists={courseList[key]} weight={getWeightAgeValue(courseList[key])} onUpdate={() => onChange()} />
                                </Col>
                            </Row>
                        ))}
                    </Col>
                    <Col>
                        <div className="d-flex align-items-center justify-content-center gird-match-height">
                            <Charts series={percentComplete} />
                        </div>
                    </Col>
                </Row>
            </>}
        </Container>
    );
}
