import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Button, Card, FormCheck, Overlay,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { checkAccessList, showEllipsis } from "../common/utilities";
import { utilities } from "../components/utilities";
import {
  UPDATE_ENROLLMENT_STATUS, VIEW_ENROLLMENTS
} from "../constants/urlConstants";
import EditTraining from "./EditTraining";
import EnrollmentForm from "./EnrollmentForm";
import "./style.css";
import ViewEnrollments from "./viewEnrollments";

const TrainingSummary = ({
  topic,
  isAdmin,
  deleteTraining,
  fetchCourses,
  changeCourseStatus,
  staticValues
}) => {
  const description = topic.description.slice(0, 50);
  const [modalShow, setModalShow] = useState(false);
  const [Show, setShow] = useState(false);
  const start_date = utilities.getDateFromTimeStamp(topic.start_date);
  const end_date = utilities.getDateFromTimeStamp(topic.end_date);
  const [enrollments, setEnrollments] = useState([]);
  const [status, setStatus] = useState(false);
  const [isEnrollmentsLoading, setIsLoading] = useState(false);
  const [isEnrollmentOn, setIsEnrollmentOn] = useState(
    topic.is_enrollment_open
  );

  const currentUser = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );

  const fetchEnrollments = () => {
    setIsLoading(true);
    const payload = {
      trainingId: topic.training_id,
    };
    axios
      .post(VIEW_ENROLLMENTS, payload)
      .then((response) => {
        setEnrollments(response.data);
        setIsLoading(false);
        const enrolled = response.data.find(
          ({ employee_id }) => employee_id === currentUser.employee_id
        );
        if (enrolled) {
          setStatus(enrolled.status);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const [showDeletePrompt, setDeletePromptShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const deleteClick = (event) => {
    setDeletePromptShow(!showDeletePrompt);
    setTarget(event.target);
  };

  const handleEnrollStatus = (event) => {
    const newStatus = !isEnrollmentOn;
    setIsEnrollmentOn(newStatus);
    const payload = {
      training_id: topic.training_id,
      is_enrollment_open: newStatus,
    };
    axios
      .post(UPDATE_ENROLLMENT_STATUS, payload)
      .then((res) => {
        if (res.data.errorType == "Error") {
          utilities.showToast(res.data.errorMessage);
        } else if (res.data.affectedRows > 0) {
          utilities.showToast(
            "Updated " + topic.training_title + " Training!!"
          );
        } else if (res.data.errorMessage) {
          utilities.showToast("Couldn't Update!");
        }
      })
      .then(() => changeCourseStatus(topic))
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    fetchEnrollments();
  }, []);
  return (
    <div className="col-md-6 col-lg-3">
      <Card className="training-card-container shadow-box" bg="light">
        <OverlayTrigger
          trigger="hover"
          placement="right"
          overlay={
            <Popover id="popover-basic">
              <Popover.Header  as="h3">{topic.training_title}</Popover.Header>
              <Popover.Body>
                {topic.description.split("<br />").map((str) => (
                  <>
                    {str}
                    <br />
                  </>
                ))}
                <div>
                  Prerequisite: {topic.pre_requisites}
                </div>
                <div>Meeting Type: {topic.type}</div>
                <div>Meeting Detail: {topic.details}</div>
                <div>Presenter: {topic.trainer_name}</div>
              </Popover.Body>
            </Popover>
          }
        >
          <Card.Body className="training-card">
            <div className="training_operation">
              <Overlay
                show={showDeletePrompt}
                target={target}
                placement="left"
                container={ref}
                containerPadding={50}
              >
                <Popover id="popover-contained">
                  <Popover.Body>
                    <div>
                      <h6>Confirm Delete?</h6>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setDeletePromptShow(false);
                            deleteTraining(topic);
                          }}
                        >
                          <i className="fa fa-check cursor-pointer" />
                        </Button>
                      </div>
                      <div className="col-md-6">
                        <Button
                          size="sm"
                          variant="light"
                          onClick={() => {
                            setDeletePromptShow(false);
                          }}
                        >
                          <i className="fa fa-times cursor-pointer" />
                        </Button>
                      </div>
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
            <div className="col-flex flex-space-between full-height">
              <div>
            <Card.Title className="flex-space-between">
              {showEllipsis(topic.training_title, 20)}

              {checkAccessList("crud_course") && (
                <span className="flex-space-between">
                  <div ref={ref} className="trash_icon">
                    <i
                      className="fa fa-trash cursor-pointer delete-training"
                      onClick={deleteClick}
                    />
                  </div>
                  <div>
                    {" "}
                    <EditTraining topic={topic} fetchCourses={fetchCourses} staticValues={staticValues} />
                  </div>
                </span>
              )}
            </Card.Title>
              <div>
                <Card.Subtitle className="mb-2 text-muted card-overflow-text">
                  {description.length > 50 ? (
                    <>
                      {description
                        .substring(0, 50)
                        .split("<br />")
                        .map((str) => (
                          <>
                            {str}
                            <br />
                          </>
                        ))}
                      ...
                    </>
                  ) : (
                    <>
                      {description.split("<br />").map((str) => (
                        <>
                          {str}
                          <br />
                        </>
                      ))}
                    </>
                  )}
                </Card.Subtitle>
                {!utilities.isEmptyOrNull(topic.pre_requisites) && (
                  <Card.Title className="card-badge pre-req-card card-overflow-text">
                    {topic.pre_requisites}
                  </Card.Title>
                )}
                {!utilities.isEmptyOrNull(topic.type) && (
                  <Card.Title className="card-badge bg-info pre-req-card">
                    {topic.type}
                  </Card.Title>
                )}
                <Card.Subtitle className="mb-2 text-muted">
                  {start_date} - {end_date}
                </Card.Subtitle>
              </div>
              </div>
              <div>
                <div className="enrollment">
                  <div className="viewEnroll">
                    {checkAccessList("review_enroll") && (
                      <Button
                        className="viewEnrollBtn"
                        onClick={() => setShow(true)}
                      >
                        {enrollments.length} Enrollments
                      </Button>
                    )}
                  </div>
                  <div className="switchEnroll">
                    {checkAccessList("review_enroll") && (
                      <FormCheck custom type="switch">
                        <FormCheck.Input checked={isEnrollmentOn} />
                        <FormCheck.Label
                          onClick={() => {
                            handleEnrollStatus();
                          }}
                        >
                          {isEnrollmentOn ? `Open` : `Closed`}
                        </FormCheck.Label>
                      </FormCheck>
                    )}
                  </div>
                </div>
                <div className="display-flex">
                  {!isEnrollmentsLoading ? (
                    topic.is_enrollment_open ? (
                      !status ? (
                        <>
                        {checkAccessList("cour_cert_train_enroll") &&
                        <span
                          className="blue-btn full-width text-center"
                          onClick={() => setModalShow(true)}
                        >
                          Enroll
                        </span>}
                        </>
                      ) : (
                        <EnrollStatus status={status} />
                      )
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Card.Body>
        </OverlayTrigger>
      </Card>
      <EnrollmentForm
        fetchEnrollments={fetchEnrollments}
        show={modalShow}
        onHide={() => setModalShow(false)}
        training_id={topic.training_id}
        training_title={topic.training_title}
        trainer_name={topic.trainer_name}
      />
      <ViewEnrollments
        enrollments={enrollments}
        isLoading={isEnrollmentsLoading}
        fetchEnrollments={fetchEnrollments}
        show={Show}
        training_id={topic.training_id}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      />
    </div>
  );
};

// const TopicsCovered = (topics) => {
//   const topicDetail = topics.topics;
//   return (
//     <ul>
//       {topicDetail.map((topic) => {
//         return <li key={topic.id}>{topic.name}</li>;
//       })}
//     </ul>
//   );
// };
export const EnrollStatus = ({ status }) => {
  return (
    <>
      {status === "Approved" ? (
        <span className="colorG">
          <span>{status}</span>
          <i className="fa fa-check" aria-hidden="true"></i>
        </span>
      ) : status === "Rejected" ? (
        <span className="colorR">
          <span>{status}</span>
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
      ) : (
        <span className="colorBlue">{status}</span>
      )}
    </>
  );
};
export default TrainingSummary;
