import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { GaEvent } from '../App';
import addLogo from '../assets/plus.png';
import { dateFormatter } from '../common/utilities';
import { utilities } from '../components/utilities';
import { gaCategory } from '../constants/gaConstants';
import {
  ADD_TRAINING,
  CC_MAIL, FROM_EMAIL, SEND_MAIL_NOTIFICATION
} from '../constants/urlConstants';

const currentDate = dateFormatter(new Date());
const AddTraining = (props) => {
  const staticValues = props.staticValues;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Course_Technical_Radio, setCourse_Technical_Radio] = useState("Technical");
  const [Course_Category_Options, setCourse_Category_Options] = useState(staticValues.filter(x => x.type === 'Category'));
  const [Course_SkillSet_Options, setCourse_SkillSet_Options] = useState(staticValues.filter(x => x.type === 'SkillLevel'));
  const [Course_Skill_Level, setCourse_Skill_Level] = useState();
  const [Course_Category_type, setCourse_Category_type] = useState();

  const [Training_Title, setTraining_Title] = useState('');
  const [Description, setDescription] = useState('');
  const [Type, setType] = useState('Virtual');
  const [Details, setDetails] = useState('');
  const [Pre_Requisites, setPre_Requisites] = useState('');
  const [Total_Capacity, setTotal_Capacity] = useState('');
  const [fromDate, setfromDate] = useState('');
  const [toDate, settoDate] = useState('');
  const [Trainer_Id, setTrainer_Id] = useState('');
  const [Trainer_Name, setTrainer_Name] = useState('');
  const [validated, setValidated] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchComboValues();
  }, [staticValues]);

  useEffect(() => {
    const employeeList = props.employeeList ? props.employeeList : [];
    const results = employeeList.filter((employee) =>
      employee.employee_name.toUpperCase().startsWith(Trainer_Name.toUpperCase())
    );
    setSearchResults(results);
  }, [Trainer_Name]);

  const fetchComboValues = () => {
    setCourse_Category_Options(staticValues.filter(x => x.type === 'Category'));
    setCourse_SkillSet_Options(staticValues.filter(x => x.type === 'SkillLevel'));
  }

  const reset = () => {
    setValidated(false);
    setTraining_Title('');
    setCourse_Skill_Level('');
    setCourse_Category_type('');
    setCourse_Technical_Radio("Technical")

    setDescription('');
    setType('Virtual');
    setDetails('');
    setPre_Requisites('');
    setfromDate('');
    settoDate('');
    setTrainer_Name('');
    setTrainer_Id('');
    setSearchResults([]);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const { currentUserDetail } = props;
      const payload = {
        training_title: Training_Title,
        description: Description.replace(/(?:\r\n|\r|\n)/g, '<br />'),
        type: Type,
        details: Details,
        pre_requisites: Pre_Requisites,
        total_capacity: Total_Capacity,
        start_date: fromDate,
        end_date: toDate,
        trainer_id: Trainer_Id,
        trainer_name: Trainer_Name,
        Skill_Id: Course_Skill_Level,
        Category_Id: Course_Category_type,
        Course_Type: Course_Technical_Radio
      };
      axios
        .post(ADD_TRAINING, payload)
        .then((res) => {
          if (res.data.errorType === 'Error') {
            utilities.showToast(res.data.errorMessage);
          } else if (res.data.affectedRows > 0) {
            GaEvent(gaCategory.TRAININGS, "New Training Added", Training_Title);
            utilities.showToast(
              'Added ' + res.data.affectedRows + ' New Training!!'
            );

            const mailText = `You have succesfully created ${Training_Title} training in Wavicle Academy`;

            const subject = 'Wavicle: Added New Training';
            const emailPayload = {
              from: FROM_EMAIL,
              to: currentUserDetail.email_id,
              cc: CC_MAIL,
              subject,
              text: mailText
            }
            axios
              .post(SEND_MAIL_NOTIFICATION, emailPayload)
              .then((response) => {
                // window.location.reload();
                props.fetchCourses();
                reset();
              });
          } else if (res.data.errorMessage) {
            utilities.showToast(res.data.errorMessage);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
      setShow(false);
    }
    setValidated(true);
  };

  const suggestionSelected = (value) => {
    setSearchResults('');
    setTrainer_Name(value.employee_name);
    setTrainer_Id(value.employee_id);
  };
  
  return (
    <>
      <i
        className="fa fa-plus-circle fa-2x add-logo"
        onClick={handleShow}
        src={addLogo}
      />

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>Add Trainings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicTrainingTitle">
              <Form.Label>Training Title</Form.Label>
              <Form.Control
                type="id"
                value={Training_Title}
                maxlength={150}
                onChange={(e) => {
                  setTraining_Title(e.target.value);
                }}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a Training Title.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="Description"
                as="textarea"
                rows="3"
                value={Description}
                maxlength={150}
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="formBasicLevel">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                custom
                value={Type}
                onChange={(e) => setType(e.target.value)}
                autoComplete="off"
                required
              >
                <option value="Virtual">Virtual</option>
                <option value="Classroom">Classroom</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide a Type.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Details</Form.Label>
              <Form.Control
                type="Details"
                as="textarea"
                rows="1"
                value={Details}
                maxlength={150}
                onChange={(e) => setDetails(e.target.value)}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a Details.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicCategoryType">
              <Form.Label className='paddding_right'>Course Type:</Form.Label>

              <Form.Check
                inline
                label="Technical"
                name="group1"
                type={'radio'}
                checked={Course_Technical_Radio === "Technical"}
                onChange={(e) => {
                  setCourse_Technical_Radio("Technical");
                }}
                id={'Techical_Radio'}
              />
              <Form.Check
                inline
                label="Non Technical"
                name="group1"
                type={'radio'}
                id={'NTechical_Radio'}
                checked={Course_Technical_Radio === "Non Technical"}
                onChange={(e) => {
                  setCourse_Technical_Radio("Non Technical");
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please provide Category Type.
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group controlId="formBasicCategoryType">

              <Form.Label>Category Type</Form.Label>
              <Form.Control as="select"
                required
                value={Course_Category_type}
                onChange={(e) => setCourse_Category_type(e.target.value)}
              >
                <option></option>
                {
                  Course_Category_Options?.map(x => { return <option value={x.id} >{x.description}</option> })
                }

              </Form.Control>

              <Form.Control.Feedback type="invalid">
                Please provide Category Type.
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group controlId="formBasicSkillLevel">
              <Form.Label>Skill Level</Form.Label>
              <Form.Control as="select"
                required
                value={Course_Skill_Level}
                onChange={(e) => setCourse_Skill_Level(e.target.value)}
              >
                <option></option>
                {
                  Course_SkillSet_Options?.map(x => { return <option value={x.id} >{x.description}</option> })
                }

              </Form.Control>


              <Form.Control.Feedback type="invalid">
                Please provide Skill Level.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Pre Requisites</Form.Label>
              <Form.Control
                type="pre_requisites"
                value={Pre_Requisites}
                maxlength={150}
                onChange={(e) => setPre_Requisites(e.target.value)}
                autoComplete="off"
              />
            </Form.Group>
            {Type !== 'Virtual' && (
              <Form.Group controlId="formBasicTitle">
                <Form.Label>Total Capacity</Form.Label>
                <Form.Control
                  type="number"
                  value={Total_Capacity}
                  onChange={(e) => setTotal_Capacity(e.target.value)}
                  autoComplete="off"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Total Capacity.
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group controlId="formBasicLevel">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                min={currentDate}
                onChange={(e) => setfromDate(e.target.value)}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a start date.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicLevel">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                min={currentDate}
                value={toDate}
                onChange={(e) => settoDate(e.target.value)}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide an end date.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Trainer Name</Form.Label>
              <Form.Control
                type="Trainer Name"
                value={Trainer_Name}
                onChange={(e) => setTrainer_Name(e.target.value)}
                autoComplete="off"
                required
              />
              {(!utilities.isEmptyOrNull(Trainer_Name) && searchResults.length > 0 && !searchResults.find(({ employee_name }) => employee_name === Trainer_Name)) ? (
                <ul className="background-design">
                  {searchResults.map((item) => (
                    <li onClick={() => suggestionSelected(item)}>
                      {item.employee_name}
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}
              <Form.Control.Feedback type="invalid">
                Please provide a Trainer Name.
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    employeeList: state.EmployeeListReducer.employeeList,
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
  };
};
export default connect(mapStateToProps)(AddTraining);
