import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { checkAccessList, exportToExcel } from "../common/utilities";
import { Loader } from "../components/customComponents";
import { CustomTable } from "../components/CustomTable";
import CustomTabs from "../components/CustomTabs";
import { utilities } from "../components/utilities";
import { DEFAULTERS_LIST, MANDATORY_TRAINING } from "../constants/urlConstants";
import { reportTableHeaders } from "./reportsConstants";
import "./styles.css";
import RangeDatePicker from "./RangeDatePicker";
import { Button } from "react-bootstrap";

const Reports = () => {
  const tabs = [
    {
      title: "Mandatory Trainings",
      key: "search",
      content: <DefaultorSearch />,
    },
    [],
  ];

  return (
    <div className="page-wrap">
      <CustomTabs tabs={tabs} />
    </div>
  );
};

const DefaultorSearch = () => {
  const [region, setRegion] = useState({ label: "All", value: "All" });
  const [competency, setCompetency] = useState({ label: "All", value: "All" });
  const [course, setCourse] = useState({ label: "All", value: 0 });
  const [status, setStatus] = useState({ label: "All", value: 0 });
  const [searchBy, setSearchBy] = useState("employee_name");
  const [searchVal, setSearchVal] = useState("");
  const [regionOptions, setRegionOptions] = useState();
  const [competencyOptions, setCompetencyOptions] = useState();
  const [statusOptions, setStatusOptions] = useState();
  const [isLoading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [defaultors, setDefaultors] = useState(null);
  const [joiningStartDate, setJoiningStartDate] = useState(null);
  const [joiningEndDate, setJoiningEndDate] = useState(null);
  const [expiryStartDate, setExpiryStartDate] = useState(null);
  const [expiryEndDate, setExpiryEndDate] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [mandatoryData, setMandatoryData] = useState([]);
  const { staticData } = useSelector((state) => state.EmployeeListReducer);
  let reportTableHeadersList = reportTableHeaders(
    staticData,
    checkAccessList("training_admin")
  );

  const formWhereParms = () => {
    let whereParams = "";
    if (region.label !== "All") {
      whereParams += `&geography=${region.value}`;
    }
    if (competency.label !== "All") {
      whereParams += `&competency=${competency.label}`;
    }
    if (course.label !== "All") {
      whereParams += `&training_name=${course.value}`;
    }
    if (status.label !== "All") {
      whereParams += `&status=${status.label}`;
    }
    if (joiningStartDate) {
      whereParams += `&joining_start_date=${formDate(joiningStartDate)}`;
    }
    if (joiningEndDate) {
      whereParams += `&joining_end_date=${formDate(joiningEndDate)}`;
    }
    if (expiryStartDate) {
      whereParams += `&expiry_start_date=${formDate(expiryStartDate)}`;
    }
    if (expiryEndDate) {
      whereParams += `&expiry_end_date=${formDate(expiryEndDate)}`;
    }
    if (searchVal) {
      whereParams += `&${searchBy}=${searchVal}`;
    }
    return whereParams;
  };
  const formDate = (givenDate) => {
    const year = givenDate.getFullYear();
    const month = String(givenDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(givenDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getMandatoryData = async () => {
    try {
      const response = await axios.get(MANDATORY_TRAINING);
      if (response.data.errorType === "Error") {
        console.error(response.data.errorMessage);
      } else {
        let mandatory = response.data; //.filter(item => item.mandatory === 1)
        const resultArray = [{ label: "All", value: "All" }];

        for (let i = 0; i < mandatory.length; i++) {
          const obj = {
            label: mandatory[i].training_name,
            value: mandatory[i].training_name,
          };
          resultArray.push(obj);
        }
        setMandatoryData(resultArray);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [
    region,
    competency,
    course,
    status,
    searchVal,
    pageSize,
    joiningEndDate,
    expiryEndDate,
  ]);

  useEffect(() => {
    const fetchDefaultorData = (source) => {
      try {
        axios
          .get(
            `${DEFAULTERS_LIST}?pageSize=${pageSize}&pageNumber=${currentPage}${formWhereParms()}`,
            { cancelToken: source.token }
          )
          .then((response) => {
            if (response.status === 200) {
              setDefaultors(response.data.results);
              setTotalRecords(response.data.recordCount);
              setLoading(false);
            } else {
              console.error(`HTTP error! Status: ${response.status}`);
            }
          });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("err", error);
        }
      }
    };
    const source = axios.CancelToken.source();
    setLoading(true);
    fetchDefaultorData(source);

    return () => {
      source.cancel();
    };
  }, [
    currentPage,
    region,
    competency,
    course,
    status,
    searchVal,
    pageSize,
    joiningEndDate,
    expiryEndDate,
  ]);

  useEffect(() => {
    getMandatoryData();
    setRegionOptions([
      { value: "All", label: "All" },
      { value: "India", label: "India" },
      { value: "Canada", label: "Canada" },
      { value: "US", label: "US" },
    ]);
    setStatusOptions([
      { value: "All", label: "All" },
      { value: "Not Completed", label: "Not Completed" },
      { value: "Expired", label: "Expired" },
      { value: "Completed", label: "Completed" },
      { value: "Expiring Today", label: "Expiring Today" },
    ]);
    if (staticData) {
      let competencies = [{ value: 0, label: "All" }];
      setCompetencyOptions([
        ...competencies,
        ...staticData
          .filter((x) => x.type === "competency")
          .map((x) => {
            return { value: x.id, label: x.description };
          }),
      ]);
    }
  }, [staticData]);

  const searchRef = useRef(null);

  const debounce_search = _.debounce(() => {
    setSearchVal(searchRef.current.value);
  }, 1000);

  const searchHandler = (e) => {
    debounce_search();
  };

  const options = [
    { value: "employee_name", label: "Employee Name" },
    { value: "employee_id", label: "Employee ID" },
    { value: "email_id", label: "Email ID" },
  ];

  const handleExcel = () => {
    setDownloading(true);

    axios
      .get(
        `${DEFAULTERS_LIST}?pageSize=${1000000}&pageNumber=${1}${formWhereParms()}`
      )
      .then((response) => {
        if (!response.data.results) {
          utilities.showToast("No Records to export!");
        } else utilities.showToast("Generating your report. Please wait...");

        const dataList = response.data.results.map((emp) => {
          let record = {};
          reportTableHeadersList.forEach((field) => {
            record = {
              ...record,
              [field.title]: emp[field.accessor],
            };
          });
          return record;
        });
        exportToExcel(dataList, "DefaultorsList");

        setDownloading(false);
      });
  };
  return (
    <div className="mt-4">
      <div className="filter-container">
        <div
          className="collapse-header-container cursor-pointer"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <p className="collapse-header-title font-semi-bold">Search Filters</p>
          <span class={`fa fa-angle-${isCollapsed ? "down" : "up"}`}></span>
        </div>
        <div
          className={`grid-container ${
            isCollapsed ? "collapsed" : "expanded"
          } collapsible-container`}
        >
          <div>
            <p className="font-bold m-0">{"Search By: "}&nbsp;&nbsp;</p>
            <Select
              className="width-medium"
              options={options}
              defaultValue={{ label: "Employee Name", value: "employee_name" }}
              onChange={(option) => setSearchBy(option.value)}
              menuPortalTarget={document.body}
            ></Select>
          </div>
          {/* <div>
          <span className="font-bold m-0">{"Geography: "}&nbsp;&nbsp;</span>
          <Select className="width-medium" options={regionOptions}
            defaultValue={{ label: "All", value: 'All' }}
            onChange={(option) => setRegion(option)}
            menuPortalTarget={document.body}></Select>
        </div> */}
          <div className="date-grid2">
            <RangeDatePicker
              title={"Expiry Date"}
              setStartDate={setExpiryStartDate}
              setEndDate={setExpiryEndDate}
              startDate={expiryStartDate ? expiryStartDate : null}
              endDate={expiryEndDate ? expiryEndDate : null}
            ></RangeDatePicker>
          </div>
          <div>
            <p className="font-bold m-0">{"Competency: "}&nbsp;&nbsp;</p>
            <Select
              className="width-medium"
              options={competencyOptions}
              defaultValue={{ label: "All", value: 0 }}
              onChange={(option) => setCompetency(option)}
              menuPortalTarget={document.body}
            ></Select>
          </div>
          <div className="training-grid">
            <p className="font-bold m-0">{"Training: "}&nbsp;&nbsp;</p>
            <Select
              className="width-medium"
              options={mandatoryData}
              defaultValue={{ value: "All", label: "All" }}
              onChange={(option) => setCourse(option)}
              menuPortalTarget={document.body}
            ></Select>
          </div>
          <div className="has-search searchbox">
            <input
              type="text"
              id="header-search"
              className="form-control"
              placeholder="Search"
              name="search"
              ref={searchRef}
              onChange={searchHandler}
              style={{ border: "none" }}
            />
            <span className="fa fa-search icon-style"></span>
          </div>
          <div className="date-grid">
            <RangeDatePicker
              title={"Joining Date"}
              setStartDate={setJoiningStartDate}
              setEndDate={setJoiningEndDate}
              startDate={joiningStartDate ? joiningStartDate : null}
              endDate={joiningEndDate ? joiningEndDate : null}
            ></RangeDatePicker>
          </div>
          <div className="status-grid">
            <p className="font-bold m-0">{"Status: "}&nbsp;&nbsp;</p>
            <Select
              className="width-medium"
              options={statusOptions}
              defaultValue={{ label: "All", value: "All" }}
              onChange={(option) => setStatus(option)}
              menuPortalTarget={document.body}
            ></Select>
          </div>

          <div className="align-center download-grid">
            <Button
              variant="primary"
              size="sm"
              onClick={handleExcel}
              disabled={downloading}
            >
              <i className="fa fa-file-excel-o export-icon" />
              Export
            </Button>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      {!isLoading && totalRecords === 0 && (
        <center>
          <p className="no_data">No Records to Show !!</p>
        </center>
      )}
      {!isLoading && totalRecords > 0 && (
        <CustomTable
          id="defaultors-list-matrix"
          columns={reportTableHeadersList}
          rows={defaultors}
          highlightText={searchVal}
          currentPage={currentPage}
          totalRecords={totalRecords}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          className={"custom-table"}
        />
      )}
    </div>
  );
};

export default Reports;
