import React, { useState, useRef } from "react";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import "./styles.css";
import { utilities } from "../components/utilities";
import { GET_GOAL_STATIC_DATA, NOTIFICATION_CONFIG } from "../constants/urlConstants";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Competency from "./Competency";
import { Loader } from "../components/customComponents";

const NotificationConfig = () => {
  return (
    <Container className="main-container">
      <Row>
        <Col>
          <NotificationConfigPanel />
        </Col>
      </Row>
    </Container>
  );
};

const NotificationConfigPanel = () => {
  const { employeeList, staticData, staticDataLoading } = useSelector(
    (state) => state.EmployeeListReducer
  );

  const [formData, setFormData] = useState([]);
  const [isLoading, setLoading] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [emailSuggestionsList, setEmailSuggestionsList] = useState([]);
  const [defaultTags, setDefaultTags] = useState([]);


  const loadEmpData = async () => {
    try {
      setLoading(true);

      // Assuming employeeList is the array containing employee data
      const employeeSuggestions = employeeList.map((employee) => {
        return {
          id: employee.employee_id,
          email: employee.email_id,
        };
      });

      setEmailSuggestionsList(employeeSuggestions);

      setLoading(false);
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    }
  };

  const handleTagDataChange = (index, tags) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      competency: tags.competency,
      email_id: tags.email_id,
    };
    setFormData(updatedFormData);
  };

  const handleTagRemove = (index, tagsToRemove) => {
    // console.log(tagsToRemove, "}}}}}}}}}}")
  
    // Iterate through formData to find the matching competency
    const updatedFormData = formData.map((data) => {
      if (data.competency === tagsToRemove.competency) {
        // Remove the email_id from the email_id array
        data.email_id = data.email_id.filter((email) => email !== tagsToRemove.email_id);
      }
      return data;
    });

    setFormData(updatedFormData);
  };
  
  

  

  const handleSubmit = async(event) => {
    event.preventDefault();
    setLoading(true)
    setLoading(true)
    // Convert formData to JSON 
    // You can replace this with your desired logic to save the JSON data
    const payload = formData
    try {
     await axios
        .put(
          NOTIFICATION_CONFIG,
          {payload}
        )
        .then((res) => {
          if (res.data.errorType === "Error") {
            utilities.showToast(res.data.errorMessage);
          } else  {
            utilities.showToast("Email Addresses Updated");
            setRefreshKey((oldKey) => oldKey + 1);
            setRefreshKey((oldKey) => oldKey + 1);
          }
        
        
        });
    } catch (error) {
      utilities.showToast("Error posting data");
      setLoading(false)
      setLoading(false)
      // Handle error cases
    }
  };

 
 
  useEffect(() => {
    loadEmpData();
    loadData();
  }, [refreshKey]);



  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(NOTIFICATION_CONFIG);
      if (response.data.errorType === "Error") {
        utilities.showToast(response.data.errorMessage);
      } else {
        // const fetchedDefaultTags = response.data.map((item) => item.email_id);
        setDefaultTags(response.data);
        setFormData(response.data)
        
      }
    } catch (error) {
      utilities.showToast("Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  



  return (
    <Container>
      {isLoading? (<Loader/>):(<Form onSubmit={handleSubmit}>
        <div className="save-as">
          <p className="mt-5 mb-4 mx-0">
            Notification Settings - Manage Mandatory training defaulters Notifications.
          </p>

          <Button variant="success" type="submit" className="">
            Save
          </Button>
        </div>
        {defaultTags?.map((data, index) => (
          <Row className="mt-3 mb-2" key={index}>
            <Col className="col-2 div-align-text-center">{data.competency === "All"? "CC Email List" : data.competency}:</Col>
            <Col md={6} lg={9}>
              <TagsInput 
                suggestions={emailSuggestionsList}
                defaultTags={defaultTags[index]|| []}
                onTagsChange={(tags) => handleTagDataChange(index, tags)}
                onTagRemove={(tags) => handleTagRemove(index, tags)}
              />
            </Col>
          </Row>
        ))}
      </Form>)}
    </Container>
  );
};


export const TagsInput = ({ suggestions, defaultTags,onTagsChange, onTagRemove }) => {
  const [tags, setTags] = useState([]);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [usedSuggestions, setUsedSuggestions] = useState([]); 
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);
  const suggestionContainerRef = useRef(null);



  useEffect(() => {
    if (defaultTags && Object.keys(defaultTags).length > 0) {
      
       setTags(defaultTags.email_id)

      
    }
  }, [defaultTags]);

  const removeTag = (indexToRemove) => {
    const removedTag = tags[indexToRemove];
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);
    if (onTagRemove) {
      onTagRemove({email_id:removedTag,competency:defaultTags.competency});
    }
  };
  

  const addTag = (tag,competency) => {

    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      tag
    );

    if (isValidEmail) {
      setTags([...tags, tag]);
      setEmailSuggestions(
        emailSuggestions.filter((emailObj) => emailObj.email !== tag)
      );
      setUsedSuggestions([...usedSuggestions, tag]);
      setErrorMessage("");
      if (onTagsChange) {
        onTagsChange({email_id: [...tags, tag],competency:competency});
      }
      inputRef.current.value = ""; // Clear the input field
      setShowSuggestions(false); // Close the suggestion list
    } else if (tag.trim() !== "") {
      setErrorMessage("Invalid email address");
    }
  };

  const handleInput = (event) => {
    const inputText = event.target.value;

    if (inputText === "") {
      setErrorMessage("");
      setShowSuggestions(false);
      return;
    }

    const filteredSuggestions = suggestions.filter(
      (emailObj) =>
        emailObj.email.startsWith(inputText) &&
        !usedSuggestions.includes(emailObj.email) &&
        !tags.includes(emailObj.email)
    );

    setEmailSuggestions(filteredSuggestions);
    setShowSuggestions(true);
  };

  useEffect(() => {
    setEmailSuggestions(suggestions);
  }, [suggestions]);

  useEffect(() => {
    inputRef.current.value = "";
    const handleOutsideClick = (event) => {
      if (
        suggestionContainerRef.current &&
        !suggestionContainerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="tags-input">
        <ul id="tags">
          {tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <span className="tag-close-icon" onClick={() => removeTag(index)}>
                x
              </span>
            </li>
          ))}
        </ul>
        <div className="suggestion-container" ref={suggestionContainerRef}>
          <input
            ref={inputRef}
            type="text"
            onBlur={() => {
              if (inputRef.current) {
                addTag(inputRef.current.value, defaultTags?.competency);
              }
            }} //() => addTag(emailSuggestions[0]?.email || "")}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (inputRef.current) {
                  addTag(inputRef.current.value, defaultTags?.competency);
                }
              }
            }}
            onChange={handleInput}
            placeholder="Press enter the email"
            list="email-suggestions"
          />
          {showSuggestions && (
            <ul className="suggestion-list">
              {emailSuggestions.slice(0, 10).map((emailObj) => (
                <li onClick={() => addTag(emailObj.email,defaultTags?.competency)} key={emailObj.id}>
                  {emailObj.email}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <span>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </span>
    </>
  );
};

export default NotificationConfig;
