import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { GaEvent } from "../App";
import { dateFormatter } from "../common/utilities";
import { utilities } from "../components/utilities";
import { CC_MAIL, EDIT_TRAININGS, FROM_EMAIL, SEND_MAIL_NOTIFICATION } from "../constants/urlConstants";
const EditTraining = ({ topic, employeeList, currentUserDetail, fetchCourses, staticValues }) => {
  const start_date =dateFormatter(new Date(topic.start_date));
  const end_date = dateFormatter(new Date(topic.end_date));
  const currentDate = dateFormatter(new Date());
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Training_Title, setTraining_Title] = useState(topic.training_title);
  const [Course_Technical_Radio, setCourse_Technical_Radio] = useState(topic.course_type);
  const [Course_Skill_Level, setCourse_Skill_Level] = useState(topic.skill_id);
  const [Course_Category_type, setCourse_Category_type] = useState(topic.category_id);
  const [Description, setDescription] = useState(topic.description);
  const [Type, setType] = useState(topic.type);
  const [Details, setDetails] = useState(topic.details);
  const [Pre_Requisites, setPre_Requisites] = useState(topic.pre_requisites);
  const [Total_Capacity, setTotal_Capacity] = useState(parseInt(topic.totalcapacity));
  const [fromDate, setfromDate] = useState(start_date);
  const [toDate, settoDate] = useState(end_date);
  const [Trainer_Id, setTrainer_Id] = useState(topic.trainer_id);
  const [Trainer_Name, setTrainer_Name] = useState(topic.trainer_name);
  const [validated, setValidated] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [Course_Category_Options, setCourse_Category_Options] = useState(staticValues.filter(x => x.type === 'Category'));
  const [Course_SkillSet_Options, setCourse_SkillSet_Options] = useState(staticValues.filter(x => x.type === 'SkillLevel'));

  useEffect(() => {
    fetchComboValues();
  }, [staticValues]);

  useEffect(() => {
    const results = employeeList.filter((employee) =>
      employee.employee_name
        .toUpperCase()
        .startsWith(Trainer_Name.toUpperCase())
    );
    setSearchResults(results);
  }, [Trainer_Name]);

  const fetchComboValues = () => {
    setCourse_Category_Options(staticValues.filter(x => x.type === 'Category'));
    setCourse_SkillSet_Options(staticValues.filter(x => x.type === 'SkillLevel'));
  }
  
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const payload = {
        training_id: topic.training_id,
        training_title: Training_Title,
        description: Description,
        type: Type,
        details: Details,
        pre_requisites: Pre_Requisites,
        totalcapacity: Total_Capacity,
        start_date: fromDate,
        end_date: toDate,
        trainer_id: Trainer_Id,
        trainer_name: Trainer_Name,
        Skill_Id: Course_Skill_Level,
        Category_Id: Course_Category_type,
        Course_Type: Course_Technical_Radio
      };
      axios
        .post(EDIT_TRAININGS, payload)
        .then((res) => {
          if (res.data.errorType == "Error") {
            utilities.showToast(res.data.errorMessage);
          } else if (res.data.affectedRows > 0) {
            fetchCourses();
            utilities.showToast("Updated " + Training_Title + " Training!!");
            GaEvent("Training", "Edit Training", Training_Title);
            const mailText = `You have succesfully updated for ${Training_Title} training in Wavicle Academy`;

            const subject = `Wavicle: Updated ${Training_Title}Training`;
            const emailPayload = {
              from: FROM_EMAIL,
              to: currentUserDetail.email_id,
              cc: CC_MAIL,
              subject,
              text: mailText
            }
            axios
              .post(SEND_MAIL_NOTIFICATION, emailPayload);
          } else if (res.data.errorMessage) {
            utilities.showToast("Couldn't Update!");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
      setShow(false);
    }
    setValidated(true);
  };

  const suggestionSelected = (value) => {
    setSearchResults("");
    setTrainer_Name(value.employee_name);
    setTrainer_Id(value.employee_id);
  };

  return (
    <span>
      <i className="fa fa-edit cursor-pointer edit-training" onClick={handleShow} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Training</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicTrainingTitle">
              <Form.Label>Training Title</Form.Label>
              <Form.Control
                type="id"
                value={Training_Title}
                maxlength={150}
                onChange={(e) => {
                  setTraining_Title(e.target.value);
                }}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a Training Title.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="Description"
                as="textarea"
                rows="3"
                value={Description}
                maxlength={150}
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="formBasicLevel">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                custom
                value={Type}
                onChange={(e) => setType(e.target.value)}
                autoComplete="off"
                required
              >
                <option value="Virtual">Virtual</option>
                <option value="Classroom">Classroom</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide a Type.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Details</Form.Label>
              <Form.Control
                type="Details"
                as="textarea"
                rows="2"
                value={Details}
                maxlength={150}
                onChange={(e) => setDetails(e.target.value)}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a Details.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicCategoryType">
              <Form.Label className='paddding_right'>Course Type:</Form.Label>

              <Form.Check
                inline
                label="Technical"
                name="group1"
                type={'radio'}
                checked={Course_Technical_Radio === "Technical"}
                onChange={(e) => {
                  setCourse_Technical_Radio("Technical");
                }}
                id={'Techical_Radio'}
              />
              <Form.Check
                inline
                label="Non Technical"
                name="group1"
                type={'radio'}
                id={'NTechical_Radio'}
                checked={Course_Technical_Radio === "Non Technical"}
                onChange={(e) => {
                  setCourse_Technical_Radio("Non Technical");
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please provide Category Type.
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group controlId="formBasicCategoryType">

              <Form.Label>Category Type</Form.Label>
              <Form.Control as="select"
                required
                value={Course_Category_type}
                onChange={(e) => setCourse_Category_type(e.target.value)}
              >
                <option></option>
                {
                  Course_Category_Options?.map(x => { return <option value={x.id} >{x.description}</option> })
                }

              </Form.Control>

              <Form.Control.Feedback type="invalid">
                Please provide Category Type.
              </Form.Control.Feedback>
            </Form.Group>


            <Form.Group controlId="formBasicSkillLevel">
              <Form.Label>Skill Level</Form.Label>
              <Form.Control as="select"
                required
                value={Course_Skill_Level}
                onChange={(e) => setCourse_Skill_Level(e.target.value)}
              >
                <option></option>
                {
                  Course_SkillSet_Options?.map(x => { return <option value={x.id} >{x.description}</option> })
                }

              </Form.Control>


              <Form.Control.Feedback type="invalid">
                Please provide Skill Level.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Pre Requisites</Form.Label>
              <Form.Control
                type="pre_requisites"
                value={Pre_Requisites}
                maxlength={150}
                onChange={(e) => setPre_Requisites(e.target.value)}
                autoComplete="off"
              />
            </Form.Group>
            {Type !== "Virtual" && (
              <Form.Group controlId="formBasicTitle">
                <Form.Label>Total Capacity</Form.Label>
                <Form.Control
                  type="number"
                  value={Total_Capacity}
                  onChange={(e) => setTotal_Capacity(e.target.value)}
                  autoComplete="off"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a Total Capacity.
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group controlId="formBasicLevel">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                onChange={(e) => setfromDate(e.target.value)}
                min={currentDate}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a start date.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicLevel">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                onChange={(e) => settoDate(e.target.value)}
                min={currentDate}
                autoComplete="off"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide an end date.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Trainer Name</Form.Label>
              <Form.Control
                type="Trainer Name"
                value={Trainer_Name}
                onChange={(e) => setTrainer_Name(e.target.value)}
                autoComplete="off"
                required
              />
              {(!utilities.isEmptyOrNull(Trainer_Name) && searchResults.length > 0 && !searchResults.find(({ employee_name }) => employee_name === Trainer_Name)) ? (
                <ul className="background-design">
                  {searchResults.map((item) => (
                    <li onClick={() => suggestionSelected(item)}>
                      {item.employee_name}
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}

              <Form.Control.Feedback type="invalid">
                Please provide a Trainer Name.
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.EmployeeListReducer.employeeList,
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
  };
};
export default connect(mapStateToProps)(EditTraining);
