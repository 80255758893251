import React, { Component } from "react";
import Chart from "react-apexcharts";

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          height: 350,
          type: "radialBar",
        },
      
        series: 67,
        colors: ["#20E647"],
        plotOptions: {
          radialBar: {
            offsetY:20,
            hollow: {
              margin: 0,
              size: "70%",
              background: "white"
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15
              }
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "green",
                fontSize: "13px"
              },
              value: {
                color: "black",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#87D4F9"],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Progress"]
      },

      // series: [this.props?.series]   //send the props for percentage
    };
  }

  render() {
    const {series} = this.props;
   
    return (
      <div className="donut"  >
        <Chart
          options={this.state.options}
          series={series?[series]:[0]}
          type="radialBar"
          width="340"
         
        />
      </div>
    );
  }
}

export default Charts;
