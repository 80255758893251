import React from "react";
import jsPDF from "jspdf";
import wav_certificate from "../../assets/wavicle-certificate.jpg";
import { GaEvent } from "../../App";
import { gaCategory } from "../../constants/gaConstants";

export const TrainingCertificate = (props) => {
    const calculateXAxis = (doc, text, docWidth) => {
        const textWidth = doc.getTextWidth(text);
        const xAxis = (docWidth - textWidth) / 2;
        return xAxis;
    }
    
    const adjustFontSize = (doc, text, maxWidth, initialFontSize) => {
        let fontSize = initialFontSize;
        doc.setFontSize(fontSize);
        let textWidth = doc.getTextWidth(text);
    
        while (textWidth > maxWidth && fontSize > 1) {
            fontSize -= 1;
            doc.setFontSize(fontSize);
            textWidth = doc.getTextWidth(text);
        }
    
        return fontSize;
    }
    
    const generateCertificate = () => {
        GaEvent(gaCategory.TRAININGS, "Certificate Issued", props.trainingName);
        const today = new Date(props?.date);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
    
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
    
        const formattedToday = dd + '/' + mm + '/' + yyyy;
    
        const doc = new jsPDF('l', "pt", [1000, 707]);
        const docWidth = 1000; // The width of the document
        const img = new Image();
        const img1 = new Image();
        img.src = wav_certificate;
        img1.src = props.signature;
        doc.addImage(img, 'JPEG', 0, 0, 1000, 707);
        doc.setFont("HelveticaNeue", "Italic");
        doc.setTextColor("#5583AF");
    
        const nameFontSize = adjustFontSize(doc, props?.name, docWidth - 50, 53); // Adjust font size for name
        doc.setFontSize(nameFontSize);
        const nameXAxis = calculateXAxis(doc, props?.name, docWidth);
        doc.text(props?.name, nameXAxis, 340, "justify");
    
        const trainingNameFontSize = adjustFontSize(doc, props.trainingName, docWidth - 50, 53); // Adjust font size for training name
        doc.setFontSize(trainingNameFontSize);
        const trainingNameXAxis = calculateXAxis(doc, props.trainingName, docWidth);
        doc.text(props.trainingName, trainingNameXAxis, 470, "justify");
    
        doc.addImage(img1, 'JPEG', 450, 500, 90, 43);
        doc.setFontSize(16);
        doc.text(props?.directorName, 450, 555, "justify");
        doc.text(props?.designation, 445, 580, "justify");
        doc.setFontSize(16);
        doc.setFont("HelveticaNeue", "Bold");
        doc.text(formattedToday, 470, 615);
        doc.save("Certificate.pdf");
    }

  return (
    <span className="link-decor" onClick={generateCertificate}>
      Download Certificate{" "}
      <i className="fa fa-certificate fa-1x" style={{ color: "#5776E7" }} />
    </span>
  );
};
