import React from "react";
import { useTable, usePagination } from "react-table";
import './styles.css';
import { Form } from "react-bootstrap";

const getCurrentDate = () => {
  var CurDate = new Date();
  var dd = String(CurDate.getDate()).padStart(2, "0");
  var mm = String(CurDate.getMonth() + 1).padStart(2, "0");
  var yyyy = CurDate.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  ...rest
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [curDate, setCurDate] = React.useState("");

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onJoiningDateChange = (e, rest) => {
    setValue(e.target.value);
    let [yyyy, mm, dd] = e.target.value.split("-");
    updateMyData(index, id, `${dd}-${mm}-${yyyy}`);
  };


  // We'll only update the external data when the input is blurred
  const onBlur = (e, rest) => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    if((id === "joining_date") && (initialValue !== "")){
      let [dd, mm, yyyy] = initialValue.split("-");
      setValue(`${yyyy}-${mm}-${dd}`)
    }
    else{
      setValue(initialValue);
    }
    let cDate = getCurrentDate();
    setCurDate(cDate);
  }, [initialValue]);



  if (rest && rest.cell && rest.cell.column.title === '') {
    return (
      <div title="Delete row" className="delete-icon-table" onClick={() => updateMyData(index, id, {}, true)}>
        {rest.data.length > 1 && index !== 0 &&
          <span className="fa fa-trash-o add-logo" />}
      </div>)
  }

  if (rest && rest.cell && rest.cell.column.type === 'combo') {
   const dropDownList = rest.cell.column.valueList
    return (
      <div className="table-field">
        <select className="editable-cell"
          value={value}
          required={rest.cell.column.isRequired}
          onChange={onChange}
          onBlur={(e) => onBlur(e, rest)}>
          {dropDownList.map((item, index) => (
            <option key={index} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    )
  }

  if (rest && rest.cell && rest.cell.column.type === 'date') {
     return (
       <div className="table-field" style={{marginLeft: '5px', width: '105%'}}>
         <Form.Control
            value={value}
            required
            max={curDate}
            onChange={(e) => { onJoiningDateChange(e, rest) }}
            type="date"/>
       </div>
     )
   }

  
  if (rest && rest.cell && rest.cell.column.title !== '') {
    return (
      <div className="table-field">
        <input
          value={value}
          className="editable-cell"
          onChange={onChange}
          required={rest.cell.column.isRequired}
          onBlur={(e) => onBlur(e, rest)}
        />
      </div>
    );
  }
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
export const EditableTable = ({
  columns,
  data,
  updateMyData,
  skipPageReset,
}) => {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      {/* {data && data?.size > 0 && ( */}
      <>
        <table {...getTableProps()} className="tableBorder" style={{width: '100%'}}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tableBorder">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className='tableHeader'>
                    {column.render("title")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination flex-space-between">
          <span>
            <span
              className={`${!canPreviousPage ? "disabled-ctrl" : "page-ctrl"}`}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </span>{" "}
            <span
              className={`${!canPreviousPage ? "disabled-ctrl" : "page-ctrl"}`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </span>{" "}
            <span
              className={`${!canNextPage ? "disabled-ctrl" : "page-ctrl"}`}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </span>{" "}
            <span
              className={`${!canNextPage ? "disabled-ctrl" : "page-ctrl"}`}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </span>{" "}
            &nbsp;&nbsp;
          </span>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="float-right">
            <span>
              Go to page:&nbsp;
              <input
                type="number"
                className="editable-cell"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                id='pagination-input'
              />
            </span>{" "}
            <select className="custom-combo"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}

                </option>
              ))}
            </select>
          </span>
        </div>
      </>
      {/* )} */}
    </>
  );
};