import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useSpeechSynthesis } from "react-speech-kit";
import SpeechRecognition, {
    useSpeechRecognition
} from 'react-speech-recognition';
import { getPauseAndAskUrl } from '../common/pauseAndAskUtils';
import { Loader } from './customComponents';


export const MicControl = ({ courseId, studentEmail, trainerEmail, setIsPlaying }) => {
    const [isLoadingAnswer, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [product, setProduct] = useState(null);
    const { speak } = useSpeechSynthesis();
    const {
        transcript,
        resetTranscript,
        listening
    } = useSpeechRecognition();

    const handleSubmit = e => {
        SpeechRecognition.stopListening();
        setIsPlaying(false);
        const params = transcript && !isEmpty(transcript) ? transcript : searchText;
        setSearchText('');
        setIsLoading(true);
        axios.get(getPauseAndAskUrl(courseId, params, trainerEmail, studentEmail))
            .then(data => {
                setProduct(data.data);
                speak({text:data?.data});
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false);
                setSearchText('');
            });
        resetTranscript();
    };

    useEffect(() => {
        return ()=>{
            setIsPlaying(true);
            SpeechRecognition.stopListening();
            setSearchText('');
            resetTranscript();
        }
    }, []);
    useEffect(() => {
            setSearchText(transcript);
    }, [transcript]);
    const handleFocus = () =>{
        SpeechRecognition.stopListening();
        const newSearchText = transcript?transcript:searchText?searchText:'';
        resetTranscript();
        setTimeout(()=>setSearchText(newSearchText),500);
    }
    const listenContinuously = () => {
        setIsPlaying(false);
        SpeechRecognition.startListening({
            continuous: true,
            language: 'en-GB',
        });
    };

    return (
        <div className='horizontal-padding'>
            <InputGroup className="mb-3 flex-grow: 0; flex-basis: 10px;">
                <Button variant='light'
                    onClick={listenContinuously}
                    onMouseUp={SpeechRecognition.stopListening}>
                    {listening ? (
                        <i className='fa fa-microphone'></i>
                    ) : (
                        <i className='fa fa-microphone-slash'></i>
                    )}
                </Button>
                <Form.Control
                    type="id"
                    id='ad'
                    placeholder='Ask your Question...'
                    value={searchText}
                    onFocus = {handleFocus}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    autoComplete="off"
                />

                <i className="fa fa-paper-plane send-icon" onClick={handleSubmit} />
            </InputGroup>
            {
                isLoadingAnswer ?
                    <Loader />
                    :
                    <p>{product}</p>
            }
        </div>
    );
};