import React from "react";
import { checkAccessList } from "../common/utilities";
import CustomTabs from "../components/CustomTabs";
import Category from './Category';
import Competency from './Competency';
import CourseType from "./CourseType";
import SkillType from './SkillType';
import "./styles.css";
import Mandatory from "./Mandatory";
import NotificationConfig from "./NotificationConfig";
import { useSelector } from "react-redux";

const Configuration = (props) => {
  const tabs = [];

  if (checkAccessList("crud_employee")) {
    tabs.push(
      { title: "Competency", key: "competency", content: <Competency /> },
      { title: "Category", key: "category", content: <Category /> },
      { title: "Skill Level", key: "skill", content: <SkillType /> },
      { title: "Course Type", key: "CourseType", content: <CourseType /> },
    );
  }
  
  if (checkAccessList("training_admin")) {
    tabs.push( { title: "Mandatory Training", key: "mandatoryTraining", content: <Mandatory /> },
    { title: "Notification", key: "notification", content: <NotificationConfig /> });
  }

  return (
    <div className="page-wrap">
      <CustomTabs tabs={tabs} />
    </div>
  );
};

export default Configuration;
