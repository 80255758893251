import Axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector } from "react-redux";
import w_logo from "../assets/wavicle.png";
import { checkAccessList, exportToExcel, getUTCTimeStamp } from "../common/utilities";
import { CustomCombo, Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import {
  SEND_MAIL_NOTIFICATION,
  TO_EMAIL, UPDATE_CERTIFICATE_STATUS
} from "../constants/urlConstants";
const currentDate = new Date();

const CertMasterList = ({ fetchCertMasterList, masterList, isLoading }) => {
  const employeeList = useSelector(
    (state) => state.EmployeeListReducer.employeeList
  );
  const [showRangePicker, setShowPicker] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [status, setStatus] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [tableData, setTableData] = useState(masterList);
  const searchRef = useRef(null);

  const handleShowDateRange = (e) => {
    setShowRange(e.target.checked);
  };
  const handleShowPicker = (e) => {
    setShowPicker((prevValue) => !prevValue);
  };
  const fetchMasterList = () => {
    const payload = {
      ...(showRange
        ? { fromDate: startDate, toDate: endDate }
        : { fromDate: "", toDate: "" }),
      status: status,
    };
    fetchCertMasterList(payload);
  };
  useEffect(() => {
    fetchMasterList();
  }, [status, startDate, endDate, showRange]);
  useEffect(() => {
    setTableData(masterList)
  }, [masterList]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchBy(e.target.value);
  };

  const handleDateRangeChange = (ranges) => {
    setStartDate(getUTCTimeStamp(ranges.selection.startDate));
    setEndDate(getUTCTimeStamp(ranges.selection.endDate));
  };
  const StatusDropDown = () => {
    const options = [
      { value: "", text: "All" },
      { value: "pending", text: "Pending" },
      { value: "completed", text: "Completed" },
      { value: "Approved", text: "Approved" },
      { value: "Rejected", text: "Rejected" },
    ];
    const selectProps = {
      value: status,
      id: "status-combo",
      onChange: handleStatusChange,
    };
    return <CustomCombo selectProps={selectProps} options={options} />;
  };

  const SearchDropDown = () => {
    const options = [
      { value: "", text: "All" },
      { value: "Name", text: "Name" },
      { value: "Certificate Name", text: "Certificate Name" },
    ];
    const selectProps = {
      value: searchBy,
      id: "search-combo",
      onChange: handleSearchChange,
    };
    return <CustomCombo selectProps={selectProps} options={options} />;
  };

  const ApprovalBoard = ({ certification }) => {
    const {
      certification_id,
      certification_title,
      employee_id,
      employee_name,
    } = certification;
    const status = certification.enrollment_status;
    const handleApprovalAction = (approval) => {
      const newStatus = approval ? "Approved" : "Rejected";
      const applicant = employeeList.find(
        (emp) => emp.employee_id === employee_id
      );
      const payload = { certification_id, employee_id, status: newStatus };
      const subject = `Certification Request ${newStatus}`;
      const mailText = `This is to notify that Certification request by ${employee_name} for 
      ${certification_title} has been ${newStatus}.`;
      Axios.post(UPDATE_CERTIFICATE_STATUS, payload).then(() => {
        utilities.showToast("Certification " + newStatus);
        const emailPayload = {
          to: TO_EMAIL,
          cc: applicant.email_id,
          subject,
          text: mailText,
        };
        Axios.post(SEND_MAIL_NOTIFICATION, emailPayload);
        fetchMasterList();
      });
    };
    return (
      <>
        {status === "Pending" ? (
          <>
            {checkAccessList("approve_cert") &&
              <div className="flex-space-between align-center">
                <div>
                  <Button
                    className="custom-green-btn"
                    onClick={() => handleApprovalAction(true)}
                  >
                    Approve
                  </Button>
                </div>
                <div>&nbsp;&nbsp;-or-&nbsp;&nbsp;</div>
                <div>
                  <Button
                    className="custom-red-btn"
                    onClick={() => handleApprovalAction(false)}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            }
          </>
        ) : status === "Approved" ? (
          <span className="colorG">
            <i class="fa fa-check" aria-hidden="true" />
            {status}
          </span>
        ) : status === "Rejected" ? (
          <span className="colorR">
            <i class="fa fa-times" aria-hidden="true" />
            {status}
          </span>
        ) : (
          <span>{status}</span>
        )}
      </>
    );
  };
  const defaultSelectionRange = {
    startDate,
    endDate,
    key: "selection",
  };
  const handlePdf = () => {
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = w_logo;
    const columns = ["Employee Name", "Certificate Name", "Status"];
    const certMasterTable = [];
    masterList.forEach((cert) => {
      certMasterTable.push([cert.employee_name, cert.certification_title, cert.enrollment_status]);
    });
    doc.addImage(img, "png", 15, 5, 100, 30);

    const totalPagesExp = "{total_pages_count_string}";

    doc.setFont("HelveticaNeue", "normal");
    doc.setFontSize(13);
    doc.text(`Certificates Master List`, 15, 50);
    doc.text(`From Date:       ${startDate}`, 15, 75);
    doc.text(`To Date:       ${endDate}`, 15, 100);
    let columnStyles = { text: { cellWidth: "wrap" } };
    doc.autoTable(columns,certMasterTable, {
      margin: { left: 15 },
      startY: 150,
      styles: {
        overflow: "linebreak",
        cellWidth: "wrap",
        font: "HelveticaBold",
        fontSize: 11,
        textColor: "black",
      },
      columnStyles,
      headStyles: {
        fillColor: "#d6d8db",
        textColor: "black",
      },
      didDrawPage: (data) => {
        doc.setFontSize(11);
      },
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.setFontSize(11);
    doc.save("CertificateMasterList.pdf");
  };
  const handleExcel = () => {
    if (!masterList) return;
    const data = searchVal ? tableData : masterList
    const dataList = data.map((cert) => {
      return {
        "Employee Name": cert.employee_name,
        "Certification Name": cert.certification_title,
        Status: cert.enrollment_status,
      };
    });
    exportToExcel(dataList, "CertificateMasterList");
  };

  const debounce_search = _.debounce(() => {
    setSearchVal(searchRef.current.value);
  }, 1000);
  const searchHander = (e) => {
    debounce_search();
  };
  useEffect(() => {
    let tempTableData = masterList;

    if (searchBy === "Name") {
      tempTableData = masterList.filter((item) =>
        item.employee_name.toLowerCase().includes(searchVal.toLowerCase())
      );
    } else if (searchBy === "Certificate Name") {
      tempTableData = masterList.filter((item) =>
        item.certification_title.toLowerCase().includes(searchVal.toLowerCase())
      );
    } else {
      tempTableData = masterList.filter(
        (item) =>
          item.employee_name.toLowerCase().includes(searchVal.toLowerCase()) ||
          item.certification_title.toLowerCase().includes(searchVal.toLowerCase())
      );
    }
    setTableData(tempTableData);
  }, [searchBy, searchVal]);


  return (
    <div>
      <div className="col-flex">
        <div className="flex-space-between my-3">
          <div>
            {"Status : "}
            <StatusDropDown />
          </div>
          <div>
            {"SearchBy : "}
            <SearchDropDown />
          </div>
          <div class="form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              id="header-search"
              class="form-control"
              placeholder="Search"
              name="search"
              ref={searchRef}
              onChange={searchHander}
            />
          </div>

          <div>
            <i
              className="fa fa-file-pdf-o fa-2x top-icons"
              onClick={handlePdf}
            />
            <i
              className="fa fa-file-excel-o fa-2x top-icons"
              onClick={handleExcel}
            />
          </div>
        </div>

        <div className="d-flex">
          <div>
            <Form.Check type="switch"
              id="custom-switch"
              label="All Time"
              onChange={handleShowDateRange}
            />
          </div>
          <div className="col-flex">
            {showRange && (
              <span className="mx-2">
                <span>Date:</span>
                <span onClick={handleShowPicker} className="cursor-pointer link-decor mx-2" >
                  {`${startDate.toDateString()} - ${endDate.toDateString()}   `}
                  &nbsp;
                  <i className={`fa fa-angle-${showRangePicker ? "down" : "right"}`} />
                </span>
              </span>
            )}
            {showRange && showRangePicker && (
              <DateRangePicker
                ranges={[defaultSelectionRange]}
                onChange={handleDateRangeChange}
                maxDate={currentDate}
              />
            )}
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {tableData && !utilities.isEmptyOrNull(tableData) ? (
              <>
                <table
                  id="cert-master-table"
                  className="table table-bordered table-striped"
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Employee Name</th>
                      <th>Certification Name</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {tableData.map((certification) => (
                        <tr>
                          <td>{certification.employee_name}</td>
                          <td>{certification.certification_title}</td>
                          <td>
                            <ApprovalBoard certification={certification} />
                          </td>
                          {/* <td>{certification.employee_name}</td> */}
                        </tr>
                      ))}
                    </>
                  </tbody>
                </table>
              </>
            ) : (
              <div>No Certifications !</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CertMasterList;
