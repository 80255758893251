import Axios from "axios";
import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { checkAccessList } from "../common/utilities";
import {
  CERTIFICATIONS_LIST,
  CERT_MASTER_LIST
} from "../constants/urlConstants";
import { CertificationList } from "./CertificationSummary";
import CertMasterList from "./CertMasterList";
import MyCertsList from "./MyCertifications";
import "./styles.css";
import UploadCertifications from "./UploadCertifications";

const defaultPayload = {fromDate: "", toDate: "", status: ""};
class Certifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdateStatus: false,
      certificationData: null,
      isLoading: true,
      isMasterListLoading: true,
      masterList: [],
      myCerts: [],
    };
  }
  componentDidMount() {
    this.getCertificationsDetails();
  }
  fetchCertMasterList = (payload= defaultPayload) => {
    this.setState({ isMasterListLoading: true });
    Axios.post(CERT_MASTER_LIST, payload)
      .then((response) => {
        const myCerts = response.data.filter(({employee_id})=>employee_id ===this.props.userDetails.employee_id)
        this.setState({
          masterList: response.data,
          myCerts,
          isMasterListLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ isMasterListLoading: false });
        console.log(err);
      });
  };
  getCertificationsDetails = () => {
    this.setState({ isLoading: true });
    Axios.get(CERTIFICATIONS_LIST)
      .then((response) => {
        if (response.data.errorMessage) {
          this.setState({ isLoading: false });
        } else
          this.setState({
            isLoading: false,
            certificationData: response.data,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { myCerts, isMyCertsLoading,isLoading, masterList, isMasterListLoading, certificationData } = this.state;
    return (
      <div className="page-wrap">
        <Tabs defaultActiveKey="certifications">
          <Tab eventKey="certifications" title="CERTIFICATIONS">
            <CertificationList certificationData={certificationData} isLoading={isLoading} myCerts={myCerts} isMyCertsLoading={isMyCertsLoading} fetchCertMasterList={this.fetchCertMasterList} />
          </Tab>
          <Tab eventKey="myCerts" title="MY CERTIFICATIONS">
            <MyCertsList
              myCerts={myCerts}
              getMyCerts={this.fetchCertMasterList}
              isLoading={isMasterListLoading}
            />
          </Tab>
          {checkAccessList("export_cert") && (
            <Tab eventKey="certMasterList" title="CERTIFICATION HISTORY">
              <CertMasterList
                fetchCertMasterList={this.fetchCertMasterList}
                isLoading={isMasterListLoading}
                masterList={masterList}
              />
            </Tab>
           )}
           {checkAccessList("upload_cert") && (
            <Tab eventKey="uploadCertifications" title="UPLOAD CERTIFICATIONS">
              <UploadCertifications
                existingCertifications={certificationData}
                fetchCertMasterList={this.fetchCertMasterList}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.CurrentUserReducer.currentUserDetail,
    isAdmin: state.CurrentUserReducer.currentUserDetail.isAdmin,
    accessListDetails: state.CurrentUserReducer.accessList
  };
};
export default connect(mapStateToProps)(Certifications);
