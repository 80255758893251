import Axios from "axios";
import React, { useRef, useState } from "react";
import { Button, Card, Overlay, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { checkAccessList, openInNewTab, showEllipsis } from "../common/utilities";
import { utilities } from "../components/utilities";
import {
  ASSIGN_COURSE, FROM_EMAIL,
  SEND_MAIL_NOTIFICATION,
  TO_EMAIL
} from "../constants/urlConstants";
import AssignModal from "./AssignModal";
import EditCourse from './EditCourse';

const CourseSummary = ({
  course,
  myCourses,
  isMyCourseLoading,
  getMyCourses,
  deleteCourse,
  courseList,
  fetchCourses,
  staticValues
}) => {
  const currentUserDetail = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  
  const [showAssignModal, setShowAssignModal] = useState(false);
  const ref = useRef(null);
  const [showDeletePrompt, setDeletePromptShow] = useState(false);
  const [target, setTarget] = useState(null);

  const deleteClick = (event) => {
    setDeletePromptShow(!showDeletePrompt);
    setTarget(event.target);
  };

  const enroll = (course, assignee = currentUserDetail) => {
    
    const payload = {
      course_id: course.Course_Id,
      employee_id: assignee.employee_id,
      learning_status: "Pending",
      assigned_by_emp_id: currentUserDetail.employee_id,
    };
    Axios.post(ASSIGN_COURSE, payload)
      .then((res) => {
        if (res.data.errorType == "Error") {
          if (res.data.errorMessage.indexOf("Duplicate entry") >= 0) {
            utilities.showToast("Course already assigned to this User.");
          }
        } else if (res.data.affectedRows > 0) {
          getMyCourses();
          const subject = `Course Assigned : ${course.Course_Title}`;
          let mailText = `Hi ${assignee.employee_name},\n\n      You've been assigned to the course '${course.Course_Title}'`;
          if (assignee.employee_name !== currentUserDetail.employee_name){
              mailText = `${mailText} by ${currentUserDetail.employee_name}`
          }
          const emailPayload = {
            from: FROM_EMAIL,
            to: assignee.email_id,
            cc: currentUserDetail.email_id,
            subject,
            text: `${mailText}.`,
          };
          Axios.post(SEND_MAIL_NOTIFICATION, emailPayload);
          if (currentUserDetail.employee_id === assignee.employee_id) {
            utilities.showToast(
              "You have succesfully enrolled for the course."
            );
          } else {
            utilities.showToast(
              "Assigned " +
              '"' +
              course.Course_Title +
              '"' +
              " course to " +
              assignee.employee_name
            );
          }
        } else if (res.data.errorMessage) {
          utilities.showToast("Invalid assignee.");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const isApply = (course_id) => {
    if (isMyCourseLoading) return <></>;
    const isAppliedCourse = myCourses.find((myCourse) => {
      return myCourse.Course_Id === course_id;
    });

    if (isAppliedCourse) {
      if (
        isAppliedCourse.learning_status === "Pending" ||
        isAppliedCourse.learning_status === "In Progress"
      ) {
        return (
          <div
            class="text-primary text-capitalize text-center m-2"
            style={{ borderRadius: "0.3rem" }}
          >
            Enrolled
          </div>
        );
      } else if (isAppliedCourse.learning_status == "Completed") {
        return (
          <div
            class=" text-success text-capitalize text-center m-2"
            style={{ borderRadius: "0.3rem" }}
          >
            Done <i class="fa fa-check" aria-hidden="true"></i>
          </div>
        );
      }
    } else if (checkAccessList("cour_cert_train_enroll")) {
      return (
        <span
          className="orange-btn display-flex margin-bottom"
          onClick={() => enroll(course, currentUserDetail)}
        >
          Enroll
        </span>
      );
    } else {
      return <></>;
    }
  };


  return (
    <div className="col-md-6">
      <Card className="course-card" bg="light">
        <Card.Body className="col-flex">
          <div className="course_operation">
            <Overlay
              show={showDeletePrompt}
              target={target}
              placement="left"
              container={ref}
              containerPadding={50}
            >
              <Popover id="popover-contained">
                <Popover.Body>
                  <div>
                    <div>
                      <h6>Confirm Delete?</h6>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setDeletePromptShow(false);
                            deleteCourse(course);
                          }}
                        >
                          <i className="fa fa-check cursor-pointer" />
                        </Button>
                      </div>
                      <div className="col-md-6">
                        <Button
                          size="sm"
                          variant="light"
                          onClick={() => {
                            setDeletePromptShow(false);
                          }}
                        >
                          <i className="fa fa-times cursor-pointer" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
          <Card.Title
            title={course.Course_Title}
            className="flex-space-between"
          >
            {showEllipsis(course.Course_Title, 60)}
            {checkAccessList("crud_course") && (
              <span className="flex-space-between">
                <div ref={ref} className="trash_icon">
                  <i
                    className="fa fa-trash cursor-pointer delete-training"
                    onClick={deleteClick}
                  />
                </div>
                <div>
                  <EditCourse  course = {course} courseList = {courseList} fetchCourses = {fetchCourses} staticValues = {staticValues}/>
                </div>
              </span>
            )}
          </Card.Title>
          <Card.Text className="mb-2 text-muted">
            {showEllipsis(course.Course_Description, 150)}
          </Card.Text>
          <div className="course-footer col-flex">
            <div>
              <span className="expertise-level">
                {showEllipsis(course.Course_Skill_Level, 40)}
              </span>
              <span className="learning-btn color-orange">
                {/* <CustomTooltip tooltip="Start Learning"> */}
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    openInNewTab(course.URL);
                  }}
                >
                  {" "}
                  View Course »
                </span>
                {/* </CustomTooltip> */}
              </span>
            </div>
            <div className="flex-space-between">
              {isApply(course.Course_Id)}
              {checkAccessList("assign_course") && (
                <span
                  className="orange-btn margin-bottom"
                  onClick={() => setShowAssignModal(true)}
                >
                  Assign
                </span>
              )}

              <AssignModal
                show={showAssignModal}
                onHide={() => setShowAssignModal(false)}
                assignCourse={enroll}
                course={course}
                course_title={course.Course_Title}
                getMyCourses={getMyCourses}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default CourseSummary;
