import React, { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage:""
    };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorMessage) {
    this.setState({hasError:true, errorMessage})
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="display-center">
          <span className="error-msg">Something went wrong!</span>
        </div>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}
