import Axios from "axios";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { utilities } from "../components/utilities";
import { UPLOAD_FAQ } from "../constants/urlConstants";

const validFaqFileHeaders = ["question", "answer"];
class UploadFAQ extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: {},
      questionData: "",
      dropdownOptions: [],
      selectedModule: "",
      videoLinkData: {},
      trainerName: "",
      trainerId: "",
      searchResults: [],
    };
  }

  fileValidation() {
    var fileInput = document.getElementById("faq_upload");

    var filePath = fileInput.value;

    var allowedExtensions = /(\.xlsx|\.xlsm|\.xml|\.xls)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  }

  headerCheck = (header) => {
    let flag = 0;
    if (header?.length > 0) {
      header.forEach((data) => {
        if (!validFaqFileHeaders.includes(data)) {
          flag++;
        }
      });
    } else {
      flag = 1;
    }
    return flag === 0;
  };

  handleFile = (e) => {
    const value = e.target.files[0];
    this.setState({
      file: value,
    });
  };

  handleUpload = () => {
    const { videoLinkData, trainerEmail } = this.state;
    if (this.fileValidation()) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = ({ target: { result } }) => {
        const wb = XLSX.read(result, { type: rABS ? "binary" : "array" });
        let faqQuest = [];
        let faqAnswer = [];
        for (let i = 0; i < wb.SheetNames.length; i++) {
          const wsname = wb.SheetNames[i];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);

          faqQuest = data.map((e) => {
            return { question: e.question };
          });
          faqAnswer = data.map((e) => {
            return { answer: e.answer };
          });

          let header = "";
          if (data.length > 0) header = Object.keys(data[0]);
          if (!this.headerCheck(header)) {
            utilities.showToast("Invalid data in sheet " + wsname);
            document.getElementById("faq_upload").value = null;
            return true;
          }
        }

        const postData = {
          training_id: videoLinkData?.training_id,
          trainer_email: trainerEmail,
          answers: faqAnswer,
          questions: faqQuest,
        };

        Axios.post(UPLOAD_FAQ, postData)
          .then((res) => {
            if (res) {
              utilities.showToast("Uploaded Successfullly");
              this.setState({
                selectedModule: "",
              });
              document.getElementById("faq_upload").value = null;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      reader.readAsBinaryString(this.state.file);
    } else {
      utilities.showToast(
        "Unsupported file format. Supported formats are .xlsx, xlsm, xml, xls"
      );
      document.getElementById("faq_upload").value = null;
    }
  };

  selectChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({
      selectedModule: e.target.value,
    });

    const self = this;
  
      let dataToFetch = this.props.readmeData;
      let filteredIndex = dataToFetch.findIndex(
        (data) => data.training_name === selectedValue
      );
      let fetchedVideoData = [];
      if (filteredIndex >= 0) fetchedVideoData = dataToFetch[filteredIndex];
      self.setState({
        videoLinkData: fetchedVideoData ? fetchedVideoData : {},
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.trainerName !== this.state.trainerName) {
      const employeeList = this.props.employeeList
        ? this.props.employeeList
        : [];

      const results = employeeList.filter((employee) =>
        employee.employee_name
          .toUpperCase()
          .startsWith(this.state.trainerName.toUpperCase())
      );

      this.setState({ searchResults: results });
    }
  }

  suggestionSelected = (value) => {
    this.setState({
      SearchResults: "",
      trainerName: value.employee_name,
      trainerId: value.employee_id,
      trainerEmail: value.email_id,
    });
  };

  render() {
    const { selectedModule } = this.state;

    return (
      <Form>
        <div>
          <div className="upload-questionnaires-container">
            <h3>Upload FAQ</h3>
            <i>
              {" "}
              <p>
                Please upload Excel in following format :{" "}
                {validFaqFileHeaders.join(", ")}
              </p>
            </i>
          </div>
          <Form.Label>Choose Training</Form.Label>
          <div>
            <select
              value={selectedModule}
              className="select-dropdown"
              onChange={this.selectChange}
            >
              <option value={""}>Choose a value</option>
              {this.props.dropDownData.map((data) => (
                <option value={data.path}>{data.name}</option>
              ))}
            </select>
          </div>

          {/* <Form.Group className="trainerName" controlId="formBasicTitle">
            <Form.Label>Trainer Name</Form.Label>
            <Form.Control
              type="Trainer Name"
              className="input-box"
              value={this.state.trainerName}
              onChange={(e) =>
                this.setState({
                  trainerName: e.target.value,
                })
              }
              autoComplete="off"
              required
            />
            {!utilities.isEmptyOrNull(this.state.trainerName) &&
            this.state.searchResults.length > 0 &&
            !this.state.searchResults.find(
              ({ employee_name }) => employee_name === this.state.trainerName
            ) ? (
              <ul className="background-design">
                {this.state.searchResults.map((item) => (
                  <li onClick={() => this.suggestionSelected(item)}>
                    {item.employee_name}
                  </li>
                ))}
              </ul>
            ) : (
              <></>
            )}
            <Form.Control.Feedback type="invalid">
              Please provide a Trainer Name.
            </Form.Control.Feedback>
          </Form.Group> */}

          <div className="question_upload">
            <input
              type="file"
              id="faq_upload"
              onChange={(e) => {
                if (this.state.selectedModule) {
                  this.handleFile(e);
                } else {
                  utilities.showToast(
                    "Choose a module for uploading questionnaire"
                  );
                }
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary my-2"
              onClick={(e) => {
                if (this.state.selectedModule) {
                  this.handleUpload();
                } else {
                  utilities.showToast(
                    "Choose a module for uploading questionnaire"
                  );
                }
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    employeeList: state.EmployeeListReducer.employeeList,
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
  };
};


export default connect(mapStateToProps)(UploadFAQ);
