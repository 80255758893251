import { openInNewTab } from "../common/utilities";
import { BROWNBAGS_PROJECT_ID } from "../constants/oneDriveConstants";
const showHelpDoc = () =>{
  openInNewTab(`${window.location.origin}/#/helpDoc`);
}

const configuration =() =>{
}

const profileMenu = [
  { label: "Help",to:'/', onClick : showHelpDoc, key: "320", accessKey:"default_access" },
  { label: "Configuration",to:'/configuration', onClick : configuration, accessKey:["system_configuration","training_admin"] },
  { label: "My Trainings",to:'/mytrainings', key: "322", accessKey:"default_access" },
  { label: "SignOut",to:'/', key: "321", accessKey:"default_access" }
];


export const mainMenu = [
  {
    id: "1",
    title: "COURSES",
    hasMenu: false,
    to:'/courses',
    accessKey:"cour_cert_train_view"
  },
  {
    id: "2",
    title: "TRAININGS",
    hasMenu: false,
    to:'/trainings',
    accessKey:"cour_cert_train_view"
  },
  {
    id: "3",
    title: "BROWNBAGS",
    hasMenu: false,
    to:'/brownbags/completed/'+BROWNBAGS_PROJECT_ID,
    accessKey: "default_access"
  },
  {
    id:"4",
    title:"CERTIFICATIONS",
    hasMenu:false,
    to:'/certifications',
    accessKey:"cour_cert_train_view"
  },
  {
    id:"5",
    title:"EMPLOYEES",
    hasMenu:false,
    to:'/employees',
    isSuperAdminView : true,
    accessKey:["search_employee","crud_employee"]
  },  
  {
    id:"6",
    title:"GOALS",
    hasMenu:false,
    to:'/goals',
    isSuperAdminView : true,
    accessKey: ["goal_access"]
  },  
  {
    id: "7",
    title: "REPORTS",
    isSuperAdminView : true,
    hasMenu: false,
    to:'/reports',
    accessKey: "training_admin"
  },
  {
    id: "8",
    title: "MY PROFILE",
    hasMenu: true,
    menuItems: profileMenu,
    accessKey: "default_access"
  },
];
