import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { checkAccessList } from "../common/utilities";
import { utilities } from "../components/utilities";
import "./styles.css";

import { connect } from "react-redux";

const AssignModal = (props) => {
  const {
    course,
    course_title,
    employeeList,
  } = props;
  const [validated, setValidated] = useState(false);

  const [assignee, setAssignee] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    const employeeList = props.employeeList ? props.employeeList : [];
    const results = employeeList.filter((employee) =>
      employee.employee_name.toUpperCase().startsWith(assignee.toUpperCase())
    );
    setSearchResults(results);
  }, [assignee]);

  const assignCourse = (e) => {
    e.preventDefault();
    const results = employeeList.filter((employee) =>
      employee.employee_name.toUpperCase().startsWith(assignee.toUpperCase())
    );
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      props.assignCourse(course,results[0]);
      props.onHide();
    }
    setAssignee("");
    setValidated(true);
  };

  const suggestionSelected = (item) => {
    setAssignee(item.employee_name);

    setSearchResults("");
  };
  return (
    <Modal {...props}>
      <Form onSubmit={assignCourse} noValidate validated={validated}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(checkAccessList("assign_course")) ? (
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Assign To</Form.Label>
              <Form.Control
                type="Assignee Name"
                value={assignee}
                onChange={(e) => setAssignee(e.target.value)}
                autoComplete="off"
                required
              />
              {searchResults.length > 0 && !utilities.isEmptyOrNull(assignee) ? (
                <ul className="background-design">
                  {searchResults.map((item) => (
                    <li onClick={() => suggestionSelected(item)}>
                      {item.employee_name}
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}
              <Form.Control.Feedback type="invalid">
                Please provide a Assignee Name.
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <Form.Group controlId="formBasicComment">
              Are you sure you want to assign "{course_title} " course to
              yourself?
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Assign
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    employeeList: state.EmployeeListReducer.employeeList,
    isAdmin: state.CurrentUserReducer.currentUserDetail.isAdmin,
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
  };
};
export default connect(mapStateToProps)(AssignModal);
