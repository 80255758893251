import Axios from "axios";
import React, { Component } from "react";
import * as XLSX from "xlsx";
import { utilities } from "../components/utilities";
import { UPLOAD_QUESTIONNAIRES } from "../constants/urlConstants";
import "./style.css";
import UploadFAQ from "./UploadFAQ";


const validQuestFileHeaders = [
  "qn_no",
  "qn",
  "a",
  "b",
  "c",
  "d",
  "e",
  "isMultipleAnswer",
  "weightage",
  "correctAnswer",
];

class UploadQuestionnaires extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      file: {},

      questionData: "",
      selectedModule: "",
      videoLinkData: {},
    };
  }

  fileValidation() {
    var fileInput = document.getElementById("quest_upload");

    var filePath = fileInput.value;

    var allowedExtensions = /(\.xlsx|\.xlsm|\.xml|\.xls)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  }

  headerCheck = (header) => {
    let flag = 0;
    if (header?.length > 0) {
      header.forEach((data) => {
        if (!validQuestFileHeaders.includes(data)) {
          flag++;
        }
      });
    } else {
      flag = 1;
    }
    return flag === 0;
  };

  handleFile = (e) => {
    const value = e.target.files[0];
    this.setState({
      file: value,
    });
  };

  handleUpload = () => {
    const { videoLinkData, selectedModule, file } = this.state;
    if (this.fileValidation()) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = ({ target: { result } }) => {
        const wb = XLSX.read(result, { type: rABS ? "binary" : "array" });

        /* Get first worksheet */
        let trainingWizardData = [];

        for (let i = 0; i < wb.SheetNames.length; i++) {
          const wsname = wb.SheetNames[i];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws);
          let header = "";
          if (data.length > 0) header = Object.keys(data[0]);
          if (!this.headerCheck(header)) {
            utilities.showToast("Invalid data in sheet " + wsname);
            document.getElementById("quest_upload").value = null;
            return true;
          }
          let obj = [];
          obj.push(wb.SheetNames[i]);
          obj.push(videoLinkData.training_id);
          obj.push(selectedModule);
          obj.push(JSON.stringify(data));
          trainingWizardData.push(obj);
        }
        let data = {
          trainingWizardData,
        };
        Axios.post(UPLOAD_QUESTIONNAIRES, data)
          .then((res) => {
            if (res) {
              utilities.showToast("Uploaded Successfullly");
              this.setState({
                selectedModule: "",
              });
              document.getElementById("quest_upload").value = null;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      reader.readAsBinaryString(file);
    } else {
      utilities.showToast(
        "Unsupported file format. Supported formats are .xlsx, xlsm, xml, xls"
      );
      document.getElementById("quest_upload").value = null;
    }
  };

  selectChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({
      selectedModule: e.target.value,
    });
    const self = this;
        let dataToFetch = self.props.readmeData;
    let filteredIndex = dataToFetch.findIndex(
      (data) => data.training_name === selectedValue
    );
    let fetchedVideoData = [];
    if (filteredIndex >= 0) fetchedVideoData = dataToFetch[filteredIndex];
    self.setState({
      videoLinkData: fetchedVideoData ? fetchedVideoData : {},
    });
  };

  render() {
    const { selectedModule } = this.state;
    const { dropDownData } = this.props;
    return (
      <div className="margin-top">
        <div>
          <h3>Upload Assessment</h3>{" "}
          <div className="upload-questionnaires-container">
            <p>
              <i>
                Please upload Excel in following format :{" "}
                {validQuestFileHeaders.join(", ")}
              </i>
            </p>
          </div>
          <div>
            <select
              value={selectedModule}
              className="select-dropdown"
              onChange={this.selectChange}
            >
              <option value={""}>Choose a value</option>
              {dropDownData.map((data) => (
                <option value={data.path}>{data.name}</option>
              ))}
            </select>
          </div>
          <div className="question_upload">
            <input
              type="file"
              id="quest_upload"
              onChange={(e) => {
                if (this.state.selectedModule) {
                  this.handleFile(e);
                } else {
                  utilities.showToast(
                    "Choose a module for uploading questionnaire"
                  );
                }
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary my-2"
              onClick={(e) => {
                if (this.state.selectedModule) {
                  this.handleUpload();
                } else {
                  utilities.showToast(
                    "Choose a module for uploading questionnaire"
                  );
                }
              }}
            >
              Upload
            </button>
          </div>
        </div>
        <hr />
        <div>
          <UploadFAQ dropDownData={this.props.dropDownData} readmeData={this.props.readmeData}/>
        </div>
      </div>
    );
  }
}

export default UploadQuestionnaires