import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { showEllipsis } from "../common/utilities";
import { utilities } from "../components/utilities";
import { VIEW_ENROLLMENTS } from "../constants/urlConstants";
import EnrollmentForm from "../Trainings/EnrollmentForm";
import { EnrollStatus } from '../Trainings/TrainingSummary';
import "./styles.css";

const UpcomingCourses = ({ course }) => {
  const currentUser = useSelector(
    (state) => state.CurrentUserReducer.currentUserDetail
  );
  const [modalShow, setModalShow] = useState(false);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enrollments, setEnrollments] = useState([]);
  const fetchEnrollmentDetails = () => {
    setIsLoading(true);
    const payload = {
      trainingId: course.training_id,
    };
    axios
      .post(VIEW_ENROLLMENTS, payload)
      .then((response) => {
        setEnrollments(response.data);
        setIsLoading(false);
        const enrolled = response.data.find(
          ({ employee_id }) => employee_id === currentUser.employee_id
        );
        if (enrolled) {
          setStatus(enrolled.status);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchEnrollmentDetails();
  }, []);
  const handleEnroll = () => {
    setModalShow(true);
  };
  const start_date = utilities.getDateFromTimeStamp(course.start_date);
  const end_date = utilities.getDateFromTimeStamp(course.end_date);
  const currentDate = new Date(start_date);
  const shortMonthName = new Intl.DateTimeFormat("en-US", { month: "short" })
    .format;
  const date = currentDate.getDate();
  const shortName = shortMonthName(currentDate);
  return (
    <div className="scroll-course-card" bg="light">

      <div className="row-flex">
        <div className="flex-space-between">
          <span className="date-card">
            <span className="month-name color-white">{shortName}</span>
            <span className="date-text">{date}</span>
          </span>
          <div className="calender-card-title">
            {showEllipsis(course.training_title, 15)}
            <span className="badge badge-success new-badge">&nbsp;New</span>
          </div>
        </div>
        <Card.Body className="training-summary">
          <Card.Text className="mb-2 text-muted">
            {course.description.split("<br />").map((str) => (
              <>
                {str}
                <br />
              </>
            ))}
          </Card.Text>
          <Card.Title>
            {/* <span className="badge badge-secondary">{course.pre_requisites}</span> */}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {start_date} - {end_date}
          </Card.Subtitle>
          {!isLoading ? (
            (course?.is_enrollment_open && !status) ? (
              <Button
                variant="primary"
                className="blue-btn"
                onClick={handleEnroll}
              >
                Enroll
              </Button>
            ) : (
              <EnrollStatus status={status} />
            )
          ) : (
            <></>
          )}
        </Card.Body>
        <EnrollmentForm
          fetchEnrollments={fetchEnrollmentDetails}
          trainer_name={course.trainer_name}
          show={modalShow}
          onHide={() => setModalShow(false)}
          training_id={course.training_id}
          training_title={course.training_title}
        />
      </div>
    </div>
  );
};

export default UpcomingCourses;
