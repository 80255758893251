import React from 'react';
import { Col, Row, Spinner } from "react-bootstrap";

export const CustomCombo = ({ selectProps, options }) => {
    if (!options) return <></>;
    return (
        <select className='customCombo' {...selectProps}>
            <>
                {options.map(option => {
                    return <option value={option.value}>{option.text}</option>
                })
                }
            </>
        </select>
    )
}

export const Loader = () => {
    return (
        <Row>
            <Col>
                <div className="d-flex justify-content-center align-items-center" style={{ height: '30rem' }}>
                    <Spinner animation="grow" variant="primary" />
                </div>
            </Col>
        </Row>
    )
}