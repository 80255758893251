import {clientIds, msClientIds, msTenantIds, redirectUri, CURRENT_ENVIRONMENT} from '../constants/envConstants';

export const getClientId = () => {
    return clientIds[CURRENT_ENVIRONMENT];
}

export const getMSClientIds = () => {
    return msClientIds[CURRENT_ENVIRONMENT];
}

export const getMsTenantIds = () => {
    return msTenantIds[CURRENT_ENVIRONMENT]
}

export const getRedirectUrl = () => {
    return redirectUri[CURRENT_ENVIRONMENT]
};
