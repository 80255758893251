export const mandatoryTrainingsEmail = (employeeName, mailContent) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 30);
  const dd = String(currentDate.getDate()).padStart(2, "0");
  const mm = String(currentDate.getMonth() + 1).padStart(2, "0");
  const yyyy = currentDate.getFullYear();
  const deadline = `${dd}-${mm}-${yyyy}`;

  return `<body>
  Hi ${employeeName},
  
  <p>
  Welcome to Wavicle Data Solutions! We're excited to have you on board. To ensure a smooth and safe work environment, we kindly ask you to complete our mandatory trainings. 
  These trainings are designed to acquaint you with our company policies and procedures, among other essential information.

  Please complete the following courses by <b>${deadline}</b>
  </p>
  <table style="border-collapse: collapse;">
  <thead>
    <th style="border: 1px solid #ddd; padding: 4px;">Training</th>
    <th style="border: 1px solid #ddd; padding: 4px;">Module</th>
  </thead>
    <tbody>
      ${mailContent}
    </tbody>
  </table>
  <p>
  Your compliance with these mandatory trainings is one of the parameters that will be considered for your probation confirmation.
  </p>
  <p>
  So we kindly request you to prioritize the completion of these trainings as soon as possible.
  </p>
  
  <p>
    Best regards,<br>
    Wavicle Academy<br>
  </p>
  </body>`;
};
