import { store } from "..";

export const 
reportTableHeaders =(staticData, isAdmin)=> {
  
 let competency  = [{value:"", label:'Select'}, ...staticData.filter(x => x.type === 'competency').map(x => { return { value: x.description, label: x.description } })];
  
  return [
  {
    title: "Employee ID",
    accessor: "employee_id",
    isRequired:true
  },
  {
    title: "Employee Name",
    accessor: "employee_name",
    isRequired:true
  },
  {
    title: "Email ID",
    accessor: "email_id",
    isRequired:true
  },
  {
    title: "Competency",
    accessor: "competency",
    type: 'combo',
    valueList: competency,
  },
  {
    title: "Training Name",
    accessor: "training_name",
    type: 'combo',
  },
  {
    title: "Joining Date",
    accessor: "joining_date",
    type: 'combo',
  },
  {
    title: "Completion Date",
    accessor: "completion_date",
    type: 'combo',
  },
  {
    title: "Expiry Date",
    accessor: "expiry_date",
    type: 'combo',
  },
  {
    title: "Age(In Days)",
    accessor: "age",
    type: 'combo',
  },
  {
    title: "Status",
    accessor: "status",
    type: 'combo',
  },
]};
