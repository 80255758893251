import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import {
  Button, Col,
  Container, Form, Row
} from "react-bootstrap";
import Select from "react-select";
import { checkAccessList } from "../common/utilities";
import "./styles.css";

export const AdditionalGoalCourse = forwardRef(({ label, values, onChange, comboOptions, isLoading, courseId, disableMaxField }, ref) => {
  const [showMessage, setShowMessage] = useState(style.hideError);
  const [inputFields, setInputFields] = useState([]);
  const [selectValue, setSelectValue] = useState([]);

  useEffect(() => {
    if (values) {
      let inputs = values.map((x) => {
        let minimum = x.Minimum !== undefined ? parseInt(x.Minimum) : 0;
        let weight = x.Weightage !== undefined ? parseFloat(x.Weightage) : 0;
        let minValue = ((!isNaN(minimum) && minimum > 0) ? minimum : '');
        let weightAge = ((!isNaN(weight) && weight > 0) ? ((weight > 100) ? 100 : weight) : '');

        return {
          Name: x.Name,
          Minimum: minValue,
          Weightage: weightAge,
          CourseTypeId: x.CourseTypeId,
          Course: { value: x.CourseTypeId, label: x.Name }
        }
      });
      setInputFields(inputs);
    }
  }, [values]);

  useImperativeHandle(ref, () => ({
    validate() {
      let validFields = inputFields[0];
      if (validFields && validFields.Name && validFields.Name !== "") {
        setShowMessage(style.hideError);
        return true;
      } else {
        setShowMessage(style.showError);
        return false;
      }
    },
  }));

  const loadComboOptions = () => {
    let options = [];
    if (comboOptions) {
      let s = [...comboOptions];
      let iFields = [...inputFields];

      s.forEach((data) => {
        let index = iFields.findIndex((a) => a.Name === data.label);
        if (index < 0) {
          options.push(data);
        }
      });
    }
    return options;
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ Name: "", Minimum: "", Weightage: "", CourseTypeId: "", Course: [] });
    setInputFields([...values]);
  };

  const handleRemoveFields = (index, e) => {
    let fields = [...inputFields];
    fields.splice(index, 1);
    let selectedValue = [...selectValue];
    selectedValue.splice(index, 1);
    setSelectValue(selectedValue);
    handleUpdate(fields);
  };

  const handleChange = (index, options) => {
    let fields = [...inputFields];
    fields[index].Course = options;
    fields[index].Name = options.label;
    fields[index].CourseTypeId = options.value;
    handleUpdate(fields);
  };

  const handleInputChange = (index, event) => {
    const fields = [...inputFields];

    if (event.target.name === "Minimum") {
      fields[index].Minimum = event.target.value;
    } else if (event.target.name === "Weightage") {
      fields[index].Weightage = event.target.value;
    }
    handleUpdate(fields);
  };

  const handleUpdate = (fields) => {
    onChange(fields);
  }

  return (
    <Container className="main-container">
      <Row>
        <Col className="padding-addgoals">
          <span className="font-semi-bold" style={{ marginRight: '10px' }}>{label}</span>
          <Button variant="primary" size="sm"
              disabled={checkAccessList("goal_read_access") || comboOptions?.length === inputFields?.length}
              onClick={handleAddFields}>
              <i className="fa fa-plus-circle mx-1"></i>
              <span>Add</span>
            </Button>
        </Col>
      </Row>

      {inputFields.map((inputField, index) => (
        <Row className="mb-1">
          <Col md={6}>
            <Form.Group className="form-mb-3" controlId="formBasicEmail">
              <Form.Label className="font-semi-bold">Course:</Form.Label>
              <Select
                style={{ width: "100%" }}
                name="Course"
                value={inputField.Course}
                options={loadComboOptions()}
                onChange={(e) => handleChange(index, e)}
                isDisabled={checkAccessList("goal_read_access")}
              ></Select>
              <span style={showMessage}>{"Please select a course"}</span>
            </Form.Group>
          </Col>
          <Col md={3}>
            {!disableMaxField && (
              <Form.Group as={Col} controlId="formBasicEmail">
                <Form.Label className="font-semi-bold">Min#:</Form.Label>
                <Form.Control
                  style={{ width: "100%" }}
                  step=""
                  value={inputField.Minimum}
                  required
                  min="1"
                  max="10"
                  name="Minimum"
                  onChange={(event) => handleInputChange(index, event)}
                  type="number"
                  disabled={checkAccessList("goal_read_access")}
                />
              </Form.Group>
            )}
          </Col>
          <Col md={3}>
            {!disableMaxField && (
              <Form.Group as={Col} controlId="formBasicEmail">
                <Row>
                  <Col md={10}>
                    <Form.Label className="font-semi-bold">Weightage(%):</Form.Label>
                    <Form.Control
                      value={inputField.Weightage}
                      required
                      min="1"
                      max="100"
                      name="Weightage"
                      onChange={(event) => handleInputChange(index, event)}
                      type="number"
                      style={{ width: "100%" }}
                      disabled={checkAccessList("goal_read_access")}
                    />
                  </Col>
                  <Col md={2}>
                    {!checkAccessList("goal_read_access") &&
                      <i title="Delete row"
                        className=" fa fa-trash-o position-sticky float-right add-logo px-2"
                        onClick={(inputFields) =>
                          handleRemoveFields(index, inputFields)
                        }
                      ></i>
                    }
                  </Col>
                </Row>
              </Form.Group>
            )}
          </Col>
        </Row>
      ))}
    </Container>
  );
});


const style = {
  showError: {
    color: "#dc3545",
    display: "inline-block",
    fontSize: "0.875em",
  },
  hideError: {
    color: "#dc3545",
    display: "none",
    fontSize: "0.875em"
  }
}
