import { SET_CURRENT_USER,SET_CURRENT_USER_ID,SET_CURRENT_USER_NAME ,SET_CURRENT_USER_DETAILS,USER_LOGOUT,SET_CURRENT_ACCESS_DETAILS} from './action';
const initState={isAuthenticated:false};


export const CurrentUserReducer = (state=initState,{type,payload})=>{
    switch(type){
        case SET_CURRENT_USER: 
            return {...state , currentUser:payload};
        case SET_CURRENT_USER_ID:
            return{...state,currentUserID:payload};
        case SET_CURRENT_USER_NAME:
            return {...state,currentUserName:payload};
        case SET_CURRENT_USER_DETAILS:
            return {...state,currentUserDetail:payload,isAuthenticated:true}; 
        case SET_CURRENT_ACCESS_DETAILS:
            return {...state,accessList:payload}
        case USER_LOGOUT:
            return state = undefined;
        default:
            return state;
    }
}