import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Loader } from "../components/customComponents";
import CertCompletionForm from "./CompletionForm";
import "./styles.css";
import { WorkFlow } from "./Workflow";

const MyCertsList = ({ myCerts, getMyCerts, isLoading }) => {
  const [showCompletionModal, setShowModal] = useState(false);
  const [completedCert, setCompletedCert] = useState();
  const handleClick = (certification) => {
    setShowModal(true);
    setCompletedCert(certification);
  };
  useEffect(() => {
    getMyCerts();
  }, []);
  const StatusWorkFlow = ({ certificationDetail }) => {
    return (
      <>
        <WorkFlow currentStatus={certificationDetail.enrollment_status} />
        {certificationDetail.enrollment_status === "Approved" && (
          <Button
            className='custom-blue-btn'
            onClick={() => handleClick(certificationDetail)}
          >
            Mark Complete
          </Button>
        )}
      </>
    );
  };
  return (
    <>
      <CertCompletionForm
        show={showCompletionModal}
        onHide={() => setShowModal(false)}
        refresh={getMyCerts}
        certification={completedCert}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <table
          id="my-certs-table"
          className="table table-bordered table-striped"
        >
          <thead className="thead-light">
            <tr>
              <th>Certification Name</th>
              <th>Status</th>
              <th>Approval Workflow</th>
            </tr>
          </thead>
          <tbody>
            {myCerts && myCerts.length > 0 ? (
              myCerts.map((certificationDetail) => {
                return (
                  <tr>
                    <td className="vertical-middle">
                      <span>{certificationDetail.certification_title}</span>
                    </td>
                    <td className="vertical-middle">
                      <span className="enr-status">
                        {certificationDetail.enrollment_status}
                      </span>
                    </td>
                    <td classname="t-center vertical-middle">
                      <StatusWorkFlow
                        certificationDetail={certificationDetail}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <p>No Certifications Applied.</p>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default MyCertsList;
