import Axios from "axios";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, Col, Container, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { checkAccessList } from "../common/utilities";
import CustomAccordion from "../components/CustomAccordion";
import { EMPLOYEE_BY_COMPETENCY } from "../constants/urlConstants";
import Charts from "../Goals/Chart";
import { GoalContext } from "./GoalContext";

const EmployeeGoals = (props) => {
  const currentUserDetail = props.currentUserDetail;
  const staticValues = useContext(GoalContext);
  const [region, setRegion] = useState({ label: "All", value: 'All' });
  const [competency, setCompetency] = useState({ label: "All", value: 0 });
  const [band, setBand] = useState({ label: "All", value: 0 });
  const [regionOptions, setRegionOptions] = useState();
  const [competencyOptions, setCompetencyOptions] = useState();
  const [bandOptions, setBandOptions] = useState();
  const [masterList, setMasterList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setRegionOptions([{ value: 'All', label: 'All' }, { value: 'India', label: 'India' }, { value: 'Canada', label: 'Canada' }, { value: 'US', label: 'US' }]);
    loadDefaults();
    loadEmployeesGoals();
  }, []);

  // This method will load list of default competencies and bands.
  const loadDefaults = () => {
    if (staticValues) {
      if (!checkAccessList('competency_selection')) {
        setCompetency({ value: currentUserDetail.competency, label: currentUserDetail.competency });
      }

      let competencies = [{ value: 0, label: 'All' }];
      let bands = [{ value: 0, label: 'All' }];
      setCompetencyOptions([...competencies, ...staticValues.filter(x => x.type === 'competency').map(x => { return { value: x.id, label: x.description } })]);
      setBandOptions([...bands, ...staticValues.filter(x => x.type === 'band').map(x => { return { value: x.id, label: x.description } })]);
    }
  }

  const loadEmployeesGoals = () => {
    setLoading(true);
    let payload = {
      competency: currentUserDetail.competency,
      roleId: currentUserDetail.role_id
    };
    Axios.post(EMPLOYEE_BY_COMPETENCY, payload)
      .then((response) => {
        if (response?.data?.length > 0) {
          renderEmployees(response?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const renderEmployees = (records) => {
    let employees = records.map((data) => {
      let weightAge = 0;
      let grouped_courses = [];
      let isgoalset = false;
      if (data.Courses.length > 0) {
        grouped_courses = _.groupBy(data.Courses, "course_type_id");
        weightAge = getPercentComplete(grouped_courses);
        grouped_courses = groupCourses(grouped_courses, "1", "50");
        isgoalset = true;
      }

      return {
        "Name": data.employee_name,
        "Region": data.geography,
        "Competency": data.competency,
        "Band": data.band,
        "State": weightAge === 100 ? "Completed" : (isgoalset ? "Set" : "Unset"),
        "Weightage": weightAge,
        "isGoalSet": isgoalset,
        grouped_courses
      }
    });
    setMasterList(employees);
    setEmployeeList(employees);
  }

  const groupCourses = (original, source, target) => {
    if (original) {
      if (original[source] && original[target]) {
        original[source] = [...original[source], ...original[target]];
        delete original[target];
      }
    }
    return original;
  }

  const getPercentComplete = (items) => {
    let percentage = 0;
    if (items) {
      for (const [key, value] of Object.entries(items)) {
        switch (key) {
          case "1":
          case "2":
            percentage += calcPercentage(value, "course_status", "Approved");
            break;
          case "3":
            percentage += calcPercentage(value, "certfication_status", "Completed");
            break;
          case "50":
            percentage += calcPercentage(value, "is_complete", 1);
            break;
          default:
            percentage += calcPercentage(value, "additional_course_status", "Completed");
        }
      }
    }
    return percentage.toFixed(0);
  }

  const calcPercentage = (items, key, value) => {
    let percent = 0;
    if (items && items.length > 0) {
      items.forEach(item => {
        if (item[key] === value) {
          percent += item ? parseFloat(item.weightage) : 0;
        }
      });
    }
    return percent;
  }

  const getHeader = (item) => {
    return (
      <Container fluid>
        <Row >
          <Col className="flex-two">
            <span className="mx-2">{item?.Name}</span>
          </Col>
          <Col>{item?.Region}</Col>
          <Col className="flex-two">{item?.Competency}</Col>
          <Col>Band : {item?.Band}</Col>
          <Col>Goal : <span>
            {item?.Weightage === 100 ? (
              <i class="fa fa-check-circle icon-success" aria-hidden="true"></i>
            ) : (
              <>
                {item?.isGoalSet ? (
                  <i class="fa fa-check-circle icon-warning" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-check-circle icon-default" aria-hidden="true"></i>
                )}
              </>
            )}</span>
            <span className="mx-1">{item?.State}</span></Col>
          <Col>Progress : {item?.Weightage}%</Col>
        </Row>
      </Container>
    )
  }

  const getBody = (item) => {
    return (
      <div style={{ justifyContent: "space-between" }}>
        <Row>
          <Col xs sm lg={9}>
            <div>
              {typeof item == "string"
                ? item
                : renderContent(item?.grouped_courses)}
            </div>
          </Col>
          <Col xs sm lg={3}>
            <div className="d-flex align-items-center justify-content-center gird-match-height">
              <Charts series={item?.Weightage} />
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const getCourseType = (key) => {
    if (key === null || key === undefined) return 0;
    if (!staticValues && staticValues === undefined) return 0;
    let course = staticValues.filter(x => x.type === 'course_type' && x.id === parseInt(key));
    let course_name = course && course[0] ? course[0].description : 0;
    return course_name;
  }

  const content = () => {
    let arr = [];
    employeeList.forEach(data => {
      arr.push({
        header: getHeader(data),
        body: getBody(data)
      })
    })
    return arr;
  };

  const getWeightAgeValue = (items) => {
    let count = Object.keys(items).length;
    let totalWeight = 0;
    if (count > 0) {
      if (count === 1) {
        totalWeight = parseFloat(items[0].weightage);
      } else {
        items?.forEach(item => {
          totalWeight += item ? parseFloat(item.weightage) : 0;
        });
      }
    }
    return totalWeight.toFixed(0);
  }

  const renderContent = (content) => {
    if (content) {
      let dataToRender = [];
      Object.keys(content).forEach((data) => {
        dataToRender.push(
          <Card className="my-2">
            <Card.Header>
              <Row>
                <Col className="font-semi-bold">
                  <span>{getCourseType(data)}</span>
                  <span style={{ fontSize: '12px', marginLeft: '5px' }}>(Minimum : <span className="font-semi-bold">{content[data].length})</span></span>
                </Col>
                <Col className="text-right">Weightage:<span className="font-semi-bold">{getWeightAgeValue(content[data])}%</span></Col>
              </Row>
            </Card.Header>
            {content[data].length > 0 ? (
              <ListGroup>
                {content[data].map((subData) => (
                  <ListGroup.Item>
                    <Row>
                      <Col>{subData?.title || subData?.certification_title}</Col>
                      <Col className="text-right">
                        <CourseBadge value={subData} />
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <></>
            )}
          </Card>
        );
      });
      return dataToRender;
    } else {
      return null;
    }
  };

  const applyFilter = (selectedRegion, selectedCompetency, selectedBand) => {
    setRegion(selectedRegion);
    setCompetency(selectedCompetency);
    setBand(selectedBand);
    let newList = masterList;
    let ftRegion = selectedRegion?.label;
    let ftCompetency = selectedCompetency?.label;
    let ftBand = selectedBand?.label;

    if (ftRegion !== 'All' && ftCompetency === 'All' && ftBand === 'All') {
      newList = masterList.filter(x => x.Region === ftRegion);
    } else if (ftRegion === 'All' && ftCompetency !== 'All' && ftBand === 'All') {
      newList = masterList.filter(x => x.Competency === ftCompetency);
    } else if (ftRegion === 'All' && ftCompetency === 'All' && ftBand !== 'All') {
      newList = masterList.filter(x => x.Band === ftBand);
    } else if (ftRegion !== 'All' && ftCompetency !== 'All' && ftBand === 'All') {
      newList = masterList.filter(x => x.Region === ftRegion && x.Competency === ftCompetency);
    } else if (ftRegion !== 'All' && ftCompetency === 'All' && ftBand !== 'All') {
      newList = masterList.filter(x => x.Region === ftRegion && x.Band === ftBand);
    } else if (ftRegion === 'All' && ftCompetency !== 'All' && ftBand !== 'All') {
      newList = masterList.filter(x => x.Competency === ftCompetency && x.Band === ftBand);
    } else if (ftRegion !== 'All' && ftCompetency !== 'All' && ftBand !== 'All') {
      newList = masterList.filter(x => x.Region === ftRegion && x.Competency === ftCompetency && x.Band === ftBand);
    }
    
    setEmployeeList(newList);
  }

  return (
    <div style={{ padding: "1%" }}>
      <Row className="mb-3">
        <Col>
          <Row>
            <Form.Group controlId="forRegionOptions">
              <Form.Label className="font-semi-bold">Region</Form.Label>
              <Select options={regionOptions}
                defaultValue={{ label: "All", value: 'All' }}
                onChange={(option) => applyFilter(option, competency, band)}></Select>
            </Form.Group>
          </Row>
        </Col>
        {checkAccessList('competency_selection') && (
          <Col>
            <Row>
              <Form.Group controlId="forCompetencyOptions">
                <Form.Label className="font-semi-bold">Competency</Form.Label>
                <Select options={competencyOptions}
                  defaultValue={{ label: "All", value: 0 }}
                  onChange={(option) => applyFilter(region, option, band)}></Select>
              </Form.Group>
            </Row>
          </Col>
        )}
        <Col>
          <Row>
            <Form.Group controlId="forBandOptions">
              <Form.Label className="font-semi-bold">Band</Form.Label>
              <Select options={bandOptions}
                defaultValue={{ label: "All", value: 0 }}
                onChange={(option) => applyFilter(region, competency, option)}></Select>
            </Form.Group>
          </Row>
        </Col>
      </Row>

      {isLoading ? (
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '30rem' }}>
              <Spinner animation="grow" variant="primary" />
            </div>
          </Col>
        </Row>
      ) : (
        <CustomAccordion items={content()} alwaysOpen={false} />
      )}
    </div>
  );
}

const CourseBadge = (value) => {
  const item = value?.value;
  const [text, setText] = useState('');
  const [color, setColor] = useState('');
  const [type, setType] = useState('course');

  useEffect(() => {
    loadValues();
  }, []);

  const loadValues = () => {
    switch (item.course_type) {
      case "Technical":
      case "Non Technical":
        setType("course");
        processState(item.course_status);
        break;
      case "Certifications":
        setType("certification");
        processState(item.certfication_status);
        break;
      case "Training":
        setType("training");
        if (item.is_complete) {
          setText('Completed');
          setColor('success');
        } else {
          setText('Pending');
          setColor('warning');
        }
        break;
      default:
        setType("additional");
        processState(item.additional_course_status);
    }
  }

  const processState = (state) => {
    if (state) {
      switch (state) {
        case 'Pending':
          setText('Pending');
          setColor('warning');
          break;
        case 'Completed':
          setText('Completed');
          setColor('success');
          break;
        case 'Approved':
          setText('Approved');
          setColor('success');
          break;
        case 'Rejected':
          setText('Rejected');
          setColor('danger');
          break;
      }
    } else {
      setText('Pending');
      setColor('warning');
    }
  }

  return (
    <>
      {type === 'additional' && text === 'Pending' && <Badge bg={'warning'} text="light">Pending</Badge>}
      {type === 'additional' && text === 'Completed' && <Badge bg={'success'} text="light">Completed</Badge>}

      {type === 'training' && text === 'Pending' && <Badge bg={'warning'} text="light">Pending</Badge>}
      {type === 'training' && text === 'Completed' && <Badge bg={'success'} text="light">Completed</Badge>}

      {type === 'course' && text === 'Pending' && <Badge bg={'warning'} text="light">Pending</Badge>}
      {type === 'course' && text === 'Completed' && <Badge bg={'warning'} text="light">Pending</Badge>}
      {type === 'course' && text === 'Approved' && <Badge bg={'success'} text="light">Completed</Badge>}
      {type === 'course' && text === 'Rejected' && <Badge bg={'danger'} text="light">Rejected</Badge>}

      {type === 'certification' && text === 'Pending' && <Badge bg={'warning'} text="light">Pending</Badge>}
      {type === 'certification' && text === 'Approved' && <Badge bg={'warning'} text="light">Pending</Badge>}
      {type === 'certification' && text === 'Completed' && <Badge bg={'success'} text="light">Completed</Badge>}
      {type === 'certification' && text === 'Rejected' && <Badge bg={'danger'} text="light">Rejected</Badge>}
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.CurrentUserReducer.isAuthenticated,
    currentUser: state.CurrentUserReducer.currentUser,
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
    accessListDetails: state.CurrentUserReducer.accessList,
  };
};

export default connect(mapStateToProps)(EmployeeGoals);
