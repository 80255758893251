import React, { Component, Fragment } from "react";
import { WithWizard } from "react-albus";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";

// import Dictaphone from "./Speech";

export class TopNavigation extends Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
    this.itemClick = this.itemClick.bind(this);
  }

  getClassName(steps, step, index, stepItem) {
    if (steps.indexOf(step) === index) {
      return "step-doing";
    } else if (steps.indexOf(step) > index || stepItem.isDone) {
      stepItem.isDone = false;
      return "step-done";
    }
  }

  itemClick(step, stepItem, steps, index, push) {
    if (this.props.disableNav) {
      return;
    }
    this.props.topNavClick(step, stepItem, steps, push);
  }

  render() {
    return (
      <WithWizard
        render={({ next, previous, step, steps, go, push }) => (
          <ul
            className={
              "nav nav-tabs " +
              this.props.className +
              (this.props.disableNav ? " disabled" : "")
            }
          >
            {steps.map((stepItem, index) => {
              if (!stepItem.hideTopNav) {
                return (
                  <li
                    key={index}
                    className={
                      "nav-item " +
                      this.getClassName(steps, step, index, stepItem)
                    }
                  >
                    <NavLink
                      to="#"
                      location={{}}
                      className="nav-link"
                      onClick={() => {
                        console.log(step, steps, index, stepItem);
                        this.itemClick(step, stepItem, steps, index, push);
                      }}
                    >
                      <span>{stepItem.name}</span>
                      <small>{stepItem.desc}</small>
                    </NavLink>
                  </li>
                );
              } else {
                return <Fragment key={index} />;
              }
            })}
          </ul>
        )}
      />
    );
  }
}

export const BottomNavigation = ({
  setShowWizard,
  onSubmit,
  bodyId,
  setRefresh,
}) => {
  const closeModal = () => {
    setShowWizard(false);
    setRefresh((pre) => !pre);
  };

  return (
    <WithWizard
      render={({ next, previous, step, steps }) => (
        <div
          className={`bottom-nav-btn ${
            steps.indexOf(step) === 1 || steps.indexOf(step) === 2
              ? "flex-space-between"
              : ""
          }`}
        >
          {steps.indexOf(step) === 1 && (
            <>
              <button className="orange-btn" onClick={previous}>
                <i className="fa fa-arrow-left" /> Back
              </button>
              <span
                className=" btn m-9 scroll-top cursor-pointer"
                variant="light"
                onClick={() => {
                  const element = document.getElementById(bodyId);
                  element.scrollTop = 0;
                }}
              >
                Go to Top
              </span>
            </>
          )}

          {steps.indexOf(step) < steps.length - 2 && (
            <div className="text-right ">
              <button
                className=" blue-btn  "
                onClick={(e) => {
                  next(e);
                }}
              >
                Continue <i className="fa fa-arrow-right" />
              </button>
            </div>
          )}

          {steps.indexOf(step) === 1 && (
            <button
              className="blue-btn"
              onClick={(e) => {
                next(e);
                onSubmit();
              }}
            >
              Submit
            </button>
          )}

          {steps.indexOf(step) === 2 && (
            <>
              <button className="orange-btn" onClick={previous}>
                <i className="fa fa-arrow-left" /> Retake test
              </button>
              <button
                className="blue-btn"
                variant="secondary"
                onClick={closeModal}
              >
                Close
              </button>
            </>
          )}
        </div>
      )}
    />
  );
};

export const options = {
  autoStart: false,
  continous: false,
};

export class Dictaphone extends Component {
  render() {
    const { listening, transcript, browserSupportsSpeechRecognition } =
      this.props;

    const handleListen = (event) => {
      this.props.startListening();
      if (listening) {
        this.props.abortListening();
      }
      event.target.classList.toggle("record");
    };

    const resetListen = () => {
      this.props.resetTranscript();
    };

    if (!browserSupportsSpeechRecognition) {
      return null;
    }

    return (
      <div className="container">
        <div className="wrapper">
          <Button className="button start" onClick={handleListen} />
          <Button className="button reset" onClick={resetListen} />
          {/* <div id="interim" className="interim">
            {interimTranscript}
          </div> */}
          <div id="final" className="final">
            {transcript}
          </div>
        </div>
      </div>
    );
  }
}
