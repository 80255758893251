import axios from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { GaEvent } from '../App';
import { checkAccessList, dateFormatter } from "../common/utilities";
import { utilities } from '../components/utilities';
import { gaCategory } from "../constants/gaConstants";
import {prepareContentUrl, README_FILE_NAME, TRAINING_PROJECT} from '../constants/oneDriveConstants';
import { getFileFromOneDrive } from '../Office365/Office365Graph';
import { useAppContext } from '../Office365/Office365Context';

import {
  COURSE_AND_CERTIFICATIONS_LIST, GOAL_LIST,
  SAVE_NEW_GOALS
} from '../constants/urlConstants';
import { AdditionalGoalCourse } from "./AdditionalGoalCourse";
import { GoalContext } from "./GoalContext";
import { GoalCourse } from "./GoalCourse";
import { GoalsCombo, GoalsDateField } from "./GoalsComponents";

const CreateNewGoals = (props) => {
  const currentUserDetail = props.currentUserDetail;
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, [])

  if (!didMount) {
    return null;
  }

  return (
    <Container className="main-container">
      <Row>
        <Col>
          <CompetencyPanel userDetails={currentUserDetail} />
        </Col>
      </Row>
    </Container>
  );
}

const CompetencyPanel = (userDetails) => {
  const staticValues = useContext(GoalContext);
  const [btnText, setBtnText] = useState('Save');
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [goalId, setGoalId] = useState(0);
  const [competency, setCompetency] = useState("");
  const [band, setBand] = useState("");
  const [competencyOptions, setCompetencyOptions] = useState();
  const [bandOptions, setBandOptions] = useState();
  const [staticDataLoad, setStaticDataLoad] = useState(false);
  const [staticCourseDataLoad, setStaticCourseDataLoad] = useState(false);

  const [staticCertificationOption, setStaticCertificationOption] = useState();
  const [staticCourseOption, setStaticCourseOption] = useState([]);
  const [staticTrainingOption, setStaticTrainingOption] = useState();
  const [additionalGoalsOptions, setadditionalGoalsOptions] = useState();

  const [goalsDetails, setGoalsDetails] = useState({});
  const [technicalCourseDetails, setTechnicalCourseDetails] = useState({});
  const [nonTechnicalCourseDetails, setNonTechnicalCourseDetails] = useState({});
  const [certificationDetails, setCertificationDetails] = useState({});
  const [additionalGoalsDetails, setAdditionalGoalDetails] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [validated, setValidated] = useState(false);
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState();
  const [skillFilter, setSkillFilter] = useState();
  const [isLoading, setLoading] = useState(false);
  const app = useAppContext();



  useEffect(() => {
    setBtnDisabled(true);
    setStaticCourseDataLoad(false);
    Promise.all([loadDefaults(), loadCoursesAndCertifications(), getReadmeData()])
  }, []);

  // This method will load list of default competencies and bands.
  const loadDefaults = () => {
    if (staticValues) {
      setStaticDataLoad(true);
      getDefaultValue(staticValues.filter((x) => x.type === "competency"));
      setCompetencyOptions(staticValues.filter(x => x.type === 'competency').map(x => { return { value: x.id, label: x.description } }));
      setBandOptions(staticValues.filter(x => x.type === 'band').map(x => { return { value: x.id, label: x.description } }));
      setadditionalGoalsOptions(staticValues.filter((x) => x.type === "course_type" && x.mandatory === "N").map(x => { return { value: x.id, label: x.description } }));
      setStaticDataLoad(false);
    }
  }

  //set competency as default
  const getDefaultValue = (data) => {
    const competencyId = userDetails?.userDetails?.competency;
    let competency = {};
    if (data?.length > 0) {
      data.forEach((item) => {
        if (item.description === competencyId) {
          competency = { label: item.description, value: item.id };
        }
      });
      setCompetency(competency);
    }
  };

  // This method will get list courses, trainings and certifications from server and load it into thier states
  const loadCoursesAndCertifications = async () => {
    try {
      let result = await axios.get(COURSE_AND_CERTIFICATIONS_LIST);
      if (result) {
        if (result.data.errorType === 'Error') {
          utilities.showToast(result.data.errorMessage);
        } else {
          let certifications = result.data.CertificationsList.map(x => {
            return {
              typeId: getCourseTypeId("Certifications"),
              key: x.level,
              value: x.certification_id,
              label: x.certification_title,
              type: "Certification"
            }
          });
          setStaticCertificationOption(sortOptions(certifications));

          let courses = result.data.CoursesList.map(x => {
            return {
              typeId: getCourseTypeId(x.Course_Type),
              key: x.Course_Type,
              value: x.Course_Id,
              label: x.Course_Title,
              type: x.Course_Type
            }
          });
          setStaticCourseOption(staticCourseOption => { return [...staticCourseOption, ...courses] });
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  }

  const getReadmeData = async () => {
    setStaticCourseDataLoad(true);
    try {
      const readmePath = prepareContentUrl(TRAINING_PROJECT, README_FILE_NAME)
      let response = await getFileFromOneDrive(app.authProvider, readmePath)
        if (response && response.training_history_data) {
          let records = response.training_history_data.map(x => {
            return {
              typeId: getCourseTypeId("Training"),
              key: 'Technical',
              value: x.training_id,
              label: x.training_name,
              type: "Training"
            }
          });
          setStaticCourseOption(staticCourseOption => { return [...staticCourseOption, ...records] });
        }
      setStaticCourseDataLoad(false);
    } catch (error) {
      setStaticCourseDataLoad(false);
      console.log(error);
      utilities.showToast(error.message);
    }

  }

  // This method helps to get list of courses, trainings and certifications from server which is previously stored for selected competency and band.
  const getGoalList = async (selectedCompetency, selectedBand) => {

    setLoading(true);

    try {
      setIsCreate(true);
      let competencyId = 0, bandId = 0;
      if (selectedCompetency && selectedCompetency.value !== undefined) {
        competencyId = selectedCompetency.value;
        setCompetency(selectedCompetency);
      }

      if (selectedBand && selectedBand.value !== undefined) {
        bandId = selectedBand.value;
        setBand(selectedBand);
      }

      if (competencyId && bandId) {
        let response = await axios.post(GOAL_LIST, { competencyId: competencyId, bandId: bandId });
        if (response && response.data) {
          let records = response.data;
          if (records.errorType === 'Error') {
            utilities.showToast(records.errorMessage);
          } else {
            if (records && records.GoalList && records.GoalList[0]) {
              setIsCreate(false);
              let data = records.GoalList[0];

              if (data.goal_id) {
                setGoalId(data.goal_id);
              }

              if (data.from_date) {
                let start_date = dateFormatter(new Date(data.from_date));
                setStartDate(start_date);
              }

              if (data.to_date) {
                let end_date = dateFormatter(new Date(data.to_date));
                setEndDate(end_date);
              }

              if (data.goals) {

                setGoalsDetails(data.goals);

                if (data.goals.Technical_Courses) {
                  let technicalCourses = data.goals.Technical_Courses;
                  setTechnicalCourseDetails(technicalCourses);
                } else {
                  setTechnicalCourseDetails({})
                }

                if (data.goals.Certifications) {
                  let certifications = data.goals.Certifications;
                  setCertificationDetails(certifications);
                } else {
                  setCertificationDetails({})
                }

                if (data.goals.Non_Technical_Courses) {
                  let nonTechnicalCourses = data.goals.Non_Technical_Courses;
                  setNonTechnicalCourseDetails(nonTechnicalCourses);
                } else {
                  setNonTechnicalCourseDetails({})
                }

                if (data.goals.Additional_Goals) {
                  let additionalGoals = data.goals.Additional_Goals;
                  setAdditionalGoalDetails(additionalGoals);
                } else {
                  setAdditionalGoalDetails([]);
                }
              }
            } else {
              resetForm();
            }
          }
        } else {
          resetForm();
        }
        setBtnDisabled(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  }

  // This method is used to get the course type id of course types like technical, non techinical, certifications, trainings etc.
  const getCourseTypeId = (key) => {
    if (key === null || key === undefined) return 0;
    if (!staticValues && staticValues === undefined) return 0;
    let course = staticValues.filter(x => x.type === 'course_type' && x.description.toLowerCase() === key.toLowerCase());
    let courseId = course && course[0] ? course[0].id : 0;
    return courseId;
  }

  // This method is used to load training, certifications and courses options to their appropriate combo boxes.
  const getComboOptions = (key) => {
    var course = staticCourseOption?.filter(x => !key || x.key === key);
    var trainings = staticTrainingOption?.filter(x => !key || x.key === key);
    var res = [];
    if (course)
      res = res.concat(course);

    if (trainings) {
      res = res.concat(trainings);
      if (categoryFilter)
        res = res.filter(x => x.categoryType === categoryFilter);
    }

    if (trainings) {
      res = res.concat(trainings);
      if (skillFilter)
        res = res.filter(x => x.skillType === skillFilter);
    }

    return sortOptions(res);
  }

  // This method is used to load previously selected values of courses, trainings and certifications of selected competency and band. 
  const getSelectedValues = (key) => {
    switch (key) {
      case "Technical_Courses":
        return technicalCourseDetails.List?.map(x => { return { typeId: x.typeId, value: x.id, label: x.name, type: x.type } });
      case "Certifications":
        return certificationDetails.List?.map(x => { return { typeId: x.typeId, value: x.id, label: x.name, type: x.type } });
      case "Non_Technical_Courses":
        return nonTechnicalCourseDetails.List?.map(x => { return { typeId: x.typeId, value: x.id, label: x.name, type: x.type } });
      case "Additional_Goals":
        return additionalGoalsDetails;
    }
  }

  //
  const getTotalWeightAge = (payload) => {
    let totalWeight = 0;
    if (payload && payload.goals &&
      payload.goals.Technical_Courses.Weightage &&
      payload.goals.Non_Technical_Courses.Weightage &&
      payload.goals.Certifications.Weightage
    ) {

      totalWeight += parseFloat(payload.goals.Technical_Courses.Weightage) || 0;
      totalWeight += parseFloat(payload.goals.Non_Technical_Courses.Weightage) || 0;
      totalWeight += parseFloat(payload.goals.Certifications.Weightage) || 0;

      if (payload.goals.Additional_Goals &&
        payload.goals.Additional_Goals.length > 0) {
        payload.goals.Additional_Goals.forEach((item) => {
          if (item.Weightage) {
            totalWeight += parseFloat(item.Weightage)
          }
        })
      }
    }
    return totalWeight;
  }

  // This method is used to validate the form before submitting the values to server.
  const validateForm = () => {
    let tech = TechnicalPanel.current.validate();
    let comp = CompetencyPanel.current.validate();
    let band = BandPanel.current.validate();
    let cert = CertificatePanel.current.validate();
    let nont = NonTechnicalPanel.current.validate();
    let addc = AdditionalPanel.current.validate();
    return tech && comp && band && cert && nont;
  }

  //requierd for validations
  const validatePayloads = (payload) => {

    //validates additional goals
    const validateAdditionalGoals = (payload) => {
      if (payload.goals.Additional_Goals && payload.goals.Additional_Goals.length > 0) {
        let e = [...payload.goals.Additional_Goals];

        let index = e.findIndex((a) => {
          return a.Name === "" || a.Minimum === "" || a.Weightage === "";
        });

        if (index >= 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    };

    if (
      payload.bandId > 0 && payload.competencyId > 0 &&
      payload.fromDate && payload.toDate && payload.goals &&
      payload.goals.Certifications && payload.goals.Certifications.Minimum && payload.goals.Certifications.List.length >= payload.goals.Certifications.Minimum && payload.goals.Certifications.Weightage &&
      payload.goals.Non_Technical_Courses && payload.goals.Non_Technical_Courses.Minimum && payload.goals.Non_Technical_Courses.List.length >= payload.goals.Non_Technical_Courses.Minimum && payload.goals.Non_Technical_Courses.Weightage &&
      payload.goals.Technical_Courses && payload.goals.Technical_Courses.Minimum && payload.goals.Technical_Courses.List.length >= payload.goals.Technical_Courses.Minimum && payload.goals.Technical_Courses.Weightage &&
      validateAdditionalGoals(payload)
    ) {
      return true;
    } else {
      return false;
    }
  };

  //sets the data required for the payload
  const getAdditionalGoalCourses = () => {
    let addData = [];
    try {
      let jsonStr = [...additionalGoalsDetails];
      addData = jsonStr.map((data) => {
        delete data.Course;
        return data;
      });
    } catch (error) {
      console.log(error);
    }
    return addData;
  };

  //
  const getDeleteGoalCourses = () => {

    let deletedValues = [];

    if (goalsDetails["Technical_Courses"].List && technicalCourseDetails.List) {
      let technicals = getDifference(goalsDetails["Technical_Courses"].List, technicalCourseDetails.List);
      if (technicals && technicals.length > 0) {
        deletedValues = [...deletedValues, ...technicals];
      }
    }

    if (goalsDetails["Certifications"].List && certificationDetails.List) {
      let certifications = getDifference(goalsDetails["Certifications"].List, certificationDetails.List);
      if (certifications && certifications.length > 0) {
        deletedValues = [...deletedValues, ...certifications];
      }
    }

    if (goalsDetails["Non_Technical_Courses"].List && nonTechnicalCourseDetails.List) {
      let nontechnicals = getDifference(goalsDetails["Non_Technical_Courses"].List, nonTechnicalCourseDetails.List);
      if (nontechnicals && nontechnicals.length > 0) {
        deletedValues = [...deletedValues, ...nontechnicals];
      }
    }


    if (goalsDetails["Additional_Goals"] && additionalGoalsDetails) {
      let additionls = goalsDetails["Additional_Goals"].filter(object1 => {
        return !additionalGoalsDetails.some(object2 => {
          return object1.CourseTypeId === object2.CourseTypeId;
        });
      });;
      if (additionls && additionls.length > 0) {
        deletedValues = [...deletedValues, ...additionls.map((x) => { return { id: x.CourseTypeId, name: x.Name, type: x.Name, typeId: x.CourseTypeId } })];
      }
    }

    let addData = deletedValues.map((data) => { return data });
    return addData;
  };

  //
  const getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

  // This method is helps to store the courses, certifications and trainings for selected competency and band to server.
  const handleSubmit = (e) => {
    if (validateForm()) {
      disableSave(true);
      const currentUserDetail = userDetails?.userDetails?.employee_id;
      const payload = {
        "competencyId": competency.value,
        "bandId": band.value,
        "assignedById": currentUserDetail,
        "fromDate": startDate,
        "toDate": endDate,
        "active": 1,
        "goalId": goalId,
        "goals": {
          "Technical_Courses": { ...technicalCourseDetails },
          "Non_Technical_Courses": { ...nonTechnicalCourseDetails },
          "Certifications": { ...certificationDetails },
          "Additional_Goals": getAdditionalGoalCourses()
        }
      };

      if (validatePayloads(payload)) {

        let totalWeight = getTotalWeightAge(payload);

        if (totalWeight !== 100) {
          disableSave(false);
          utilities.showToast(`Overall weightage is ${totalWeight}% should be 100%`);
          return
        }

        if (isCreate) {
          axios.post(SAVE_NEW_GOALS, payload)
            .then((res) => {
              disableSave(false);
              console.log(res.data);
              if (res.data.errorType === 'Error' || res.data.errorMessage) {
                handleError(res);
              } 
              if (!res.data.isSuccess) {
                utilities.showToast(res.data.message);
              } 
              if (res.data.affectedRows > 0) {
                console.log('Added ' + res.data.affectedRows + ' New Goal!!');
                GaEvent(gaCategory.GOAL_SETTING, "New Goal Added");
                utilities.showToast('Added ' + res.data.affectedRows + ' New Goal!!');
                getGoalList(competency, band);
              }
            })
            .catch((err) => {
              disableSave(false);
              console.log('err', err);
            });
        } else {
          checkGoalDeletion(payload).then((resp) => {

            if (resp.data && Object.keys(resp.data).length > 0) {
              let courses = [];
              for (const [key, value] of Object.entries(resp.data)) {
                if (['Technical', 'Non Technical', 'Certifications', 'Training'].includes(key)) {
                  const grouped = _.groupBy(value, x => x.title);
                  courses = [...courses, ...Object.keys(grouped)];
                } else {
                  courses = [...courses, key];
                }
              }
              if (courses && Object.keys(courses).length > 0) {
                let message = "Courses (" + courses.join(', ') + ") cannot be deleted. Its already taken by users.";
                utilities.showToast(message);
              }
              disableSave(false);
              return;
            }

            axios.put(SAVE_NEW_GOALS, payload)
              .then((res) => {
                disableSave(false);
                if (res.data.errorType === 'Error' || res.data.errorMessage) {
                  handleError(res);
                } if (res.data.affectedRows > 0) {
                  GaEvent(gaCategory.GOAL_SETTING, "Goal Updated");
                  utilities.showToast('Goal changes have been successfully updated!');
                }
              })
              .catch((err) => {
                disableSave(false);
                console.log('err', err);
              });
          }).catch((err) => {
            disableSave(false);
            console.log(err);
          });
        }
      } else {
        disableSave(false);
        utilities.showToast("Please enter all mandatory feilds");
      }
    }
    setValidated(true);
  };

  //
  const checkGoalDeletion = async (payload) => {
    try {
      const deleteGoals = getDeleteGoalCourses();
      if (deleteGoals && deleteGoals.length > 0) {
        const delPayloads = { ...payload };
        delPayloads.goals = { "Deleted_Goals": deleteGoals }
        let response = await axios.put(GOAL_LIST, delPayloads);
        return response;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  // This method is helps to handle error message if any occures in api call.
  const handleError = (res) => {
    if (res.data.errorMessage === "ER_DUP_ENTRY: Duplicate entry '2-2' for key 'reference_unique'") {
      utilities.showToast("Sorry, You are not allowed to save the same value again.");
    } else {
      utilities.showToast(res.data.errorMessage);
    }
  }

  // This method is helps to reset the form.
  const resetForm = () => {
    setStartDate("");
    setEndDate("");
    setTechnicalCourseDetails({});
    setCertificationDetails({});
    setNonTechnicalCourseDetails({});
    setAdditionalGoalDetails([]);
  }

  //
  const disableSave = (state) => {
    setBtnText(state ? 'Saving' : 'Save');
    setBtnDisabled(state);
  }

  // Get current date
  const getCurrentDate = () => {
    var CurDate = new Date();
    var dd = String(CurDate.getDate()).padStart(2, "0");
    var mm = String(CurDate.getMonth() + 1).padStart(2, "0");
    var yyyy = CurDate.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  }

  //
  const toggleSideBar = () => {
    setSideBarOpen(!isSideBarOpen);
  }

  //
  const getCategoryFromPane = (filterValue, type) => {
    if (type === "Category") setCategoryFilter(filterValue);
    if (type === "Skill") setSkillFilter(filterValue);
  }

  const sortOptions = (options) => {
    return options.sort(function (a, b) {
      return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
    });;
  }
  const CompetencyPanel = useRef(null);
  const BandPanel = useRef(null);
  const TechnicalPanel = useRef(null);
  const NonTechnicalPanel = useRef(null);
  const CertificatePanel = useRef(null);
  const AdditionalPanel = useRef(null);

  return (
    <Container className="main-container tab-container p-4">
      <Form noValidate validated={validated}>
        <Row>
          <Col></Col>
          <Col>
            <SaveButton variant="primary"
              className="float-right"
              onClick={handleSubmit}
              saveBtnTitle={btnText}
              closeBtnTitle="Close"
              goal={{competency, band}}
              disabled={checkAccessList('goal_read_access') || isBtnDisabled} />
            {/* <i className="fa fa-filter fa-2x add-logo" style={{ float: "right" }} onClick={toggleSideBar}></i>
                <SidePane handleClick={toggleSideBar} sidePaneOpen={isSideBarOpen} getCategoryFromPane={getCategoryFromPane} /> */}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <GoalsCombo label={"Competency"}
              ref={CompetencyPanel}
              isLoading={staticDataLoad}
              isDisabled={!checkAccessList('competency_selection')}
              value={competency}
              onChange={(item) => getGoalList(item, band)}
              options={competencyOptions}
              errorMessage={"Please select competency"}>
            </GoalsCombo>
          </Col>
          <Col>
            <GoalsCombo label={"Band"}
              ref={BandPanel}
              isLoading={staticDataLoad}
              value={band}
              onChange={(item) => getGoalList(competency, item)}
              options={bandOptions}
              errorMessage={"Please select band"}>
            </GoalsCombo>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <GoalsDateField
              label={"Start Date"}
              startDate={startDate}
              CurDate={getCurrentDate}
              value={startDate}
              onChange={setStartDate}
              isDisabled={checkAccessList("goal_read_access")}
              errorMessage={"Please select start date"}></GoalsDateField>
          </Col>
          <Col>
            <GoalsDateField
              label={"End Date"}
              value={endDate}
              startDate={startDate}
              endDate={endDate}
              CurDate={getCurrentDate}
              onChange={setEndDate}
              isDisabled={checkAccessList("goal_read_access")}
              errorMessage={"Please select end date"}></GoalsDateField>
          </Col>
        </Row>
        {isLoading && isLoading === true && <>
          <Row>
            <Col>
              <div className="d-flex justify-content-center align-items-center" style={{ height: '30rem' }}>
                <Spinner animation="grow" variant="primary" />
              </div>
            </Col>
          </Row>
        </>}

        {!isLoading && isLoading === false &&
          <>
            <Row className="mt-5">
              <Col className="competency-row">
                <GoalCourse label={"Technical Course"}
                  ref={TechnicalPanel}
                  comboOptions={getComboOptions("Technical")}
                  isLoading={staticCourseDataLoad}
                  onChange={setTechnicalCourseDetails}
                  selectedValues={getSelectedValues("Technical_Courses")}
                  value={technicalCourseDetails}
                  courseId={getCourseTypeId("Technical")}
                  isDisabled={checkAccessList("goal_read_access")}
                  errorMessage={"Please select technical courses"}>
                </GoalCourse>
              </Col>
            </Row>
            <Row>
              <Col className="competency-row" >
                <GoalCourse label={"Certifications"}
                  ref={CertificatePanel}
                  comboOptions={staticCertificationOption}
                  isLoading={staticCourseDataLoad}
                  onChange={setCertificationDetails}
                  selectedValues={getSelectedValues("Certifications")}
                  value={certificationDetails}
                  courseId={getCourseTypeId("Certifications")}
                  isDisabled={checkAccessList("goal_read_access")}
                  errorMessage={"Please select certifications"}>
                </GoalCourse>
              </Col>
            </Row>
            <Row>
              <Col className="competency-row" >
                <GoalCourse label={"Non Technical Courses"}
                  ref={NonTechnicalPanel}
                  comboOptions={getComboOptions("Non Technical")}
                  isLoading={staticCourseDataLoad}
                  onChange={setNonTechnicalCourseDetails}
                  selectedValues={getSelectedValues("Non_Technical_Courses")}
                  value={nonTechnicalCourseDetails}
                  courseId={getCourseTypeId("Non Technical")}
                  isDisabled={checkAccessList("goal_read_access")}
                  errorMessage={"Please select non technical courses"}>
                </GoalCourse>
              </Col>
            </Row>
            <Row>
              <Col className="competency-row">
                <AdditionalGoalCourse label={"Additional Course"}
                  ref={AdditionalPanel}
                  comboOptions={additionalGoalsOptions}
                  values={additionalGoalsDetails}
                  isLoading={staticCourseDataLoad}
                  onChange={setAdditionalGoalDetails}
                  courseId={"4"}
                ></AdditionalGoalCourse>
              </Col>
            </Row>
          </>
        }
      </Form>
    </Container>
  );
};

const SaveButton = ({ goal, saveBtnTitle, closeBtnTitle, variant, submit, className, disabled, onClick }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (submit) {
      handleClose();
    }
  }, [submit, goal])

  return (
    <>
      <Button variant={variant} className={className} onClick={handleShow} disabled={disabled}>{saveBtnTitle}</Button>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Goals will be assigned to employees in <b>Band {goal.band.label}</b> for <b>{goal.competency.label}</b> competency.<br/>New trainings/certifications/courses can be added, but cannot be removed if already used.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>{closeBtnTitle}</Button>
          <Button variant="primary" onClick={() => { handleClose(); onClick(); }}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUserDetail: state.CurrentUserReducer.currentUserDetail,
  };
};

export default connect(mapStateToProps)(CreateNewGoals);
