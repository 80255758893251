import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import img4 from '../assets/img4.png';
import img5 from '../assets/img5.png';
import img6 from '../assets/img6.png';
import img7 from '../assets/img7.png';
import img8 from '../assets/img8.png';
import img9 from '../assets/img9.png';

export const imageConstants = [
  {
    id: "1",
    image: img1,
    url:"https://youtu.be/oF8wnhCwtHM"
  },
  {
    id: "2",
    image: img2,
    url:"https://www.matillion.com/resources/blog/connecting-matillion-etl-to-snowflake-a-guide"
  },
  {
    id: "3",
    image: img3,
    url:"https://alephjs.org/"
  },
  {
    id: "4",
    image: img4,
    url:"https://fugue-tutorials.readthedocs.io/tutorials/beginner/index.html"
  },
  {
    id: "5",
    image: img5,
    url:"https://academy.talend.com/learn"
  },
  {
    id: "6",
    image: img6,
    url:"https://deepnote.com/@abid/Data-Science-with-DuckDB-f4a2af8f-5128-42b9-a3ea-78f8636a6b92"
  },
  {
    id: "7",
    image: img7,
    url:"https://youtu.be/bhBSlnQcq2k"
  },
  {
    id: "8",
    image: img8,
    url:"https://youtu.be/IUU6OR8yHCc"
  },
  {
    id: "9",
    image: img9,
    url:"https://analyticsdata24.files.wordpress.com/2020/02/spark-the-definitive-guide40www.bigdatabugs.com_.pdf"
  }
];
