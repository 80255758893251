import axios from "axios";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { checkAccessList } from "../common/utilities";
import { Loader } from "../components/customComponents";
import { utilities } from "../components/utilities";
import { GET_GOAL_STATIC_DATA } from "../constants/urlConstants";
import CreateNewGoals from "./CreateNewGoals";
import EmployeeGoals from "./EmployeeGoals";
import { GoalContext } from "./GoalContext";
import MyGoals from "./MyGoals";
import "./styles.css";

export const Goals = () => {
    const [staticData, setStaticData] = useState();
    const [isLoading, setLoading] = useState(null);

    useEffect(() => {
        loadStaticValues();
    }, []);

    const loadStaticValues = async () => {
        try {
            setLoading(true);
            let result = await axios.get(GET_GOAL_STATIC_DATA);

            if (result) {
                if (result.data.errorType === 'Error') {
                    utilities.showToast(result.data.errorMessage);

                } else {
                    setStaticData(result.data)
                }
            }
        } catch (err) {
            console.log('err', err);
        }
        setLoading(false);
    }

    return (
        <div className="page-wrap">
            {isLoading && isLoading === true && <>
                <Loader />
            </>}

            {!isLoading && isLoading === false &&
                <GoalContext.Provider value={staticData}>
                    <Tabs id="uncontrolled-tab-example">
                        {checkAccessList("create_goals") &&
                            <Tab eventKey="create_goals" title="Create Goals">
                                <div className="row">
                                    <CreateNewGoals initValues={staticData} />
                                </div>
                            </Tab>
                        }
                        {checkAccessList("my_employee_goals") &&
                            <Tab eventKey="my_employees" title="My Team">
                                <EmployeeGoals initValues={staticData} />
                            </Tab>
                        }
                        {checkAccessList("my_goal_access") &&
                            <Tab eventKey="my_goals" title="My Goals">
                                <div className="row">
                                    <MyGoals initValues={staticData} />
                                </div>
                            </Tab>
                        }
                    </Tabs>
                </GoalContext.Provider>
            }
        </div>
    )
}