import React from "react";
import Accordion from "react-bootstrap/Accordion";

const CustomAccordion = ({ items, alwaysOpen }) => {
  return (
    <div className="Accordindiv">
      <Accordion
        defaultActiveKey={!alwaysOpen ? null : items.map((item, i) => `${i}`)}
        alwaysOpen
      >
        {items?.map((item, index) => {
          return (
            <Accordion.Item eventKey={`${index}`}>
              <Accordion.Header>
                <div className="question-style">
                  <div>{index + 1}.&nbsp;</div>
                  <div>{item?.header}</div>
                </div>
              </Accordion.Header>
              <Accordion.Body>{item?.body}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
