import Axios from "axios";
import _ from 'lodash';
import React, { Component } from "react";
import { Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "../App";
import wavicleLogo from "../assets/wavicle.png";
import { utilities } from "../components/utilities";
import { FETCH_USER } from "../constants/urlConstants";
import {
  setAccessListDetails, setCurrentUserDetails, setCurrentUserID,
  setCurrentUserName
} from "../NavBar/action";
import LoginUsingMicrosoft from "../Office365/Office365Welcome";
import "./styles.css";
class login extends Component {
  responseGoogle = (response) => {
    const { email, name } = response.profileObj;

    if (!email.endsWith("@wavicledata.com")) {
      utilities.showToast("Please login with wavicledata email!");
      return;
    }
    // Call redux action to fetch user details
    // Hello <User>
    this.props.setCurrentUserID(email);
    this.props.setCurrentUserName(name);
    

    localStorage.setItem("email_id", email);
    localStorage.setItem("emp_name", name);

    Axios.post(
      FETCH_USER,
      {
        emailId: email,
      }
    ).then((res) => {
      if(!res?.data || _.isEmpty(res?.data)){
        history.push({pathname:'/#/errorPage'})
      }
      this.props.setCurrentUserDetails({
        ...res.data,
        isAdmin:!utilities.isEmptyOrNull(res.data.admin)
      });
      // window.location.reload();
    });
  };

  googleErrorResp = (error) => {
    console.log("Google login error", error);
    utilities.showToast(error.error);
    return;
  }

  render() {
    return (
      <div className="boxed-page">
        <Card>
          <div className="login_page">
            <div className="seperate_image">
              <img
                src={wavicleLogo}
                className="wavicleLogo"
                alt="wavicle_logo"
              ></img>
            </div>
            <hr />
            <div className="seperate_space">
              <h2 className="wav_login">
                Log in using your Wavicle Email account.
              </h2>
              <Form.Text className="text">
                (ends with @wavicledata.com)
              </Form.Text>
            </div>
            <hr />
            <div className="seperate_space">
              {/* <GoogleLogin
                variant="primary"
                clientId={getClientId()}
                buttonText="Continue with Google"
                onSuccess={this.responseGoogle}
                onFailure={this.googleErrorResp}
                isSignedIn={true}
                cookiePolicy={"single_host_origin"}
              /> */}
              <span>
                  <LoginUsingMicrosoft {...this.props} />
              </span>
            </div>
          </div>
          <Card.Footer className="text-muted">
            <div className="container">
              <div className="copyright-section">
                <p>2020 © WAVICLE DATA SOLUTIONS - ALL RIGHTS RESERVED</p>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { setCurrentUserID, setCurrentUserName, setCurrentUserDetails, setAccessListDetails },
      dispatch
    )
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
    isAuthenticated: state.CurrentUserReducer.isAuthenticated,
    currentUser: state.CurrentUserReducer.currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(login);
