import axios from "axios";
import _ from 'lodash';
import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import XLSX from 'xlsx';
import { utilities } from "../components/utilities";
import {
  UPLOAD_CERTIFICATIONS
} from "../constants/urlConstants";
import "./styles.css";

const UploadCertifications = ({ existingCertifications, fetchCertMasterList }) => {
  const ref = useRef();

  const [newCertifications, setNewCertifications] = useState([]);
  const [updateCertificationUsers, setUpdateCertificationUsers] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [fileUploaded, SetFileUploaded] = useState(false);
  const validCertFileHeaders = ['employee_id', 'certification_title', 'status', 'issued_by', 'certified_on', 'expires_on'];
  const [uploadedFileHeaders, setUploadedFileHeaders] = useState([]);
  const employeeList = useSelector(
    (state) => state.EmployeeListReducer.employeeList
  );
  const handleSubmit = async e => {
    if (!fileUploaded) {
      utilities.showToast("Please Upload a File!");
    } else {
      e.preventDefault();
      if (_.difference(validCertFileHeaders, uploadedFileHeaders).length !== 0) {
        utilities.showToast("Please upload file with valid headers");
      } else {
        let flag = false;
        updateCertificationUsers.forEach((userData,userIndex) => {
          let index = employeeList.findIndex(data => {
           return userData.employee_id === data.employee_id
          });
          if (index < 0){
            updateCertificationUsers.splice(userIndex,1);
            flag = true;
          }
        })
        if(flag){
          utilities.showToast("Invalid employee id's has been removed");  
        }
        if (updateCertificationUsers.length > 0) {
          setSubmitting(true);
          axios.post(
            UPLOAD_CERTIFICATIONS, {
            newCertifications: newCertifications,
            updateCertificationUsers: updateCertificationUsers
          }
          )
            .then((response) => {
              setSubmitting(false);
              setNewCertifications([])
              setUpdateCertificationUsers([])
              setSubmitting(false)
              SetFileUploaded(false)
              setUploadedFileHeaders([])
              ref.current.value = "";
              if (response?.data?.errorMessage) {
                utilities.showToast(response.data.errorMessage);
              } else {
                fetchCertMasterList();
                utilities.showToast("File upload successful");
              }
            })
            .catch((err) => {
              setSubmitting(false);
              utilities.showToast("Failed to upload the file!");
            });
        }
        else {
          utilities.showToast("Please upload file with data");
        }
      }
    }
  };

  const processData = (fileData) => {

    const filteredCertiNames = _.uniq(_.map(existingCertifications, 'certification_title')); //getting unique certlist

    const tempNewCertificationsUsers = _.filter(fileData, (fileItem) => fileItem.certification_title && !_.includes(filteredCertiNames, fileItem.certification_title));//group new certis
    const tempNewCertifications = tempNewCertificationsUsers.map(cert => {
      return {
        certification_title: cert.certification_title,
        issuing_authority: cert.issued_by
      }
    })
    setNewCertifications(tempNewCertifications);
    setUpdateCertificationUsers(fileData);
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      SetFileUploaded(true);
    }

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = ({ target: { result } }) => {
      /* Parse data */
      //      const bstr = evt.target.result;
      const wb = XLSX.read(result, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(convertToJson(data));
    };
    reader.readAsBinaryString(file);
  };

  const convertToJson = (csv) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");
    setUploadedFileHeaders(headers);

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    return result;
  }

  return (
    <form onSubmit={handleSubmit}>
      <h4>Upload the certificate spreadsheet</h4>
      <p>Please upload Excel in following format : {validCertFileHeaders.join(" ,")}</p>
      <input type="file" onChange={handleFileUpload} id="cert_file_upload" ref={ref} />
      {isSubmitting ? (
        <Spinner animation="border" role="status" />
      ) : (
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Upload
        </Button>
      )}
    </form>
  );
};

export default UploadCertifications;
